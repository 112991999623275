<template>
  <div class="profile-form-wrapper">
    <div :class="{ blurred: !profileData.email_verified_at && 0 }">
      <!-- Name field -->
      <ModalInput
        v-if="currentEditField === 'name' && isModalOpen"
        :title="$t('profile.name')"
        :value="profileData.name"
        @changed="handleChange('name', $event)"
        @cancel="handleCancel()"
      />
      <!-- <Input
        v-if="currentEditField === 'name'"
        :value="profileData.name"
        @changed="handleChange('name', $event)"
      /> -->

      <TheProfileFormField
        v-else
        :property="profileData.name"
        :readonly="true"
        @clicked="() => $router.push('/tokens')"
      >
        {{ $t("profile.name") }}
      </TheProfileFormField>

      <!-- Phone field -->
      <ModalInput
        v-if="currentEditField === 'phone_verified' && isModalOpen"
        type="tel"
        :title="$t('profile.phone')"
        :value="profileData.phone_verified"
        @changed="handleChange('phone_verified', $event)"
        @cancel="handleCancel()"
      />
      <!-- <Input
        type="tel"
        :value="profileData.phone_verified"
        v-if="currentEditField === 'phone_verified'"
        @changed="handleChange('phone_verified', $event)"
      /> -->

      <TheProfileFormField
        v-else
        :property="profileData.phone_verified || ''"
        @clicked="() => openFieldEditor('phone_verified')"
      >
        {{ $t("profile.phone") }}
      </TheProfileFormField>

      <!-- B-day field -->
      <ModalDatePicker
        v-if="currentEditField === 'birthday' && isModalOpen"
        :title="$t('profile.birth')"
        @changed="handleChange('birthday', $event)"
        @cancel="handleCancel()"
      />

      <TheProfileFormField
        :property="profileData.birthday"
        @clicked="() => openFieldEditor('birthday')"
      >
        {{ $t("profile.birth") }}
      </TheProfileFormField>

      <!-- Gender field -->
      <ModalSelect
        v-if="currentEditField === 'gender' && isModalOpen"
        :title="$t('profile.gender')"
        :options="[{label: $t('registration.genderPage.male'), value: 'male'}, {label: $t('registration.genderPage.female'), value: 'female'}]"
        :current-property="getGender(profileData.gender)"
        @changed="handleChange('gender', $event)"
        @cancel="handleCancel()"
      />

      <TheProfileFormField
        :property="getGender(profileData.gender)"
        @clicked="() => openFieldEditor('gender')"
      >
        {{ $t("profile.gender") }}
      </TheProfileFormField>

      <!-- Partner field -->
      <ModalSelect
        v-if="currentEditField === 'looking_gender' && isModalOpen"
        :title="$t('profile.partner')"
        :options="[{label: $t('registration.genderPage.lookformale'), value: 'male'}, {label: $t('registration.genderPage.lookforfemale'), value: 'female'}]"
        :current-property="getLookForGender(profileData.looking_gender)"
        @changed="handleChange('looking_gender', $event)"
        @cancel="handleCancel()"
      />

      <TheProfileFormField
        :property="getLookForGender(profileData.looking_gender)"
        @clicked="() => openFieldEditor('looking_gender')"
      >
        {{ $t("profile.partner") }}
      </TheProfileFormField>

      <!-- Country field -->
      <ModalSelect
        v-if="currentEditField === 'country' && isModalOpen"
        :title="$t('profile.country')"
        :options="['Switzerland', 'Ukraine', 'Austria', 'Germany', 'United States', 'United Kingdom']"
        :current-property="profileData.country"
        @changed="handleChange('country', $event)"
        @cancel="handleCancel()"
      />

      <TheProfileFormField
        :is-country="true"
        :property="profileData.country"
        @clicked="() => openFieldEditor('country')"
      >
        {{ $t("profile.country") }}
      </TheProfileFormField>

      <!-- City field -->
      <ModalInput
        v-if="currentEditField === 'city' && isModalOpen"
        :title="$t('profile.city')"
        :value="profileData.city"
        @changed="handleChange('city', $event)"
        @cancel="handleCancel()"
      />
      <!-- <ModalCitySelect
        v-if="currentEditField === 'city' && isModalOpen"
        @changed="handleChange('city', $event)"
        @cancel="handleCancel()"
        :title="$t('profile.city')"
        :currentProperty="profileData.city"
      /> -->

      <TheProfileFormField
        :property="profileData.city"
        @clicked="() => openFieldEditor('city')"
      >
        {{ $t("profile.city") }}
      </TheProfileFormField>

      <!-- Tokens field -->
      <TheProfileFormField
        :property="tokensLeft.toString()"
        :is-token-field="true"
        @clicked="openTokensPage"
      >
        {{ $t("profile.tokens") }}
      </TheProfileFormField>

      <!-- Cancel membership field -->
      <TheProfileFormField
        v-if="profileData.paidPromo"
        property="Cancel"
        @clicked="handleMembership"
      >
        {{ $t("profile.membership") }}
      </TheProfileFormField>
    </div>

    <div v-if="!profileData.email_verified_at && 0" class="email-verification">
      <p>{{ $t("profile.email") }}</p>
      <form @submit.prevent="handleVerification">
        <input type="email" :value="profileData.email" readonly>
        <button :disabled="isEmailSent">
          {{ $t("profile.verify") }}
        </button>
      </form>

      <p v-if="isEmailSent" class="sent-info">
        {{ $t("profile.verification") }}
      </p>
    </div>
    <div style="padding: 20px;">
      <span>{{ $t('contact.info') }} <a href="mailto:support@mailercare.com">support@mailercare.com</a></span>
    </div>
    <div :class="{ blurred: !profileData.email_verified_at && 0 }">
      <ModalCrop
        v-if="currentEditField === 'avatar'"
        :title="$t('profile.upload')"
        @changed="handleChange('avatar', $event)"
      />
      <PhotoUpload
        :src="profileData.avatar"
        @clicked="() => openFieldEditor('avatar')"
      />
    </div>
    <ModalCancelMembership v-if="cancelMembership" @close-modal="cancelMembership = false" />
    <!-- <ContentModal :text="$t('profile.verification')" v-if="isEmailSent" @submit="closeModal" @close="closeModal"/> -->
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import ModalCrop from '../ModalCrop/ModalCrop.vue';
// import ModalCitySelect from '../ModelSelectCity/ModalCitySelect.vue';
import ModalInput from '@/components/ModalInput/ModalInput';
import ModalDatePicker from '@/components/ModalDatePicker/ModalDatePicker';
import ModalSelect from '@/components/ModalSelect/ModalSelect';
import TheProfileFormField from '@/components/TheProfileFormField/TheProfileFormField';
// import Input from '@/components/ProfileInput/ProfileInput';
import { Namespaces } from '@/utils/constants/store.js';
import PhotoUpload from '@/components/PhotoUpload/PhotoUpload';
import ModalCancelMembership from '@/components/ModalCancelMembership/ModalCancelMembership';

const {
  mapActions: profileActions,
  mapGetters: profileGetters
} = createNamespacedHelpers(Namespaces.Profile);
const {
  // mapActions: configActions,
  mapGetters: configGetters
} = createNamespacedHelpers(Namespaces.Config);

const {
  mapGetters: registrationGetters,
  mapActions: registrationActions
} = createNamespacedHelpers(Namespaces.Registration);

export default {
  components: {
    TheProfileFormField,
    // Input,
    ModalDatePicker,
    ModalSelect,
    PhotoUpload,
    ModalCrop,
    // ModalCitySelect,
    ModalInput,
    ModalCancelMembership
    // ContentModal,
  },
  data () {
    return {
      currentEditField: '',
      isEmailSent: false,
      isModalOpen: false,
      cancelMembership: false,
      gender: [{ value: 'male', text: this.$i18n.t('registration.genderPage.male') }, { value: 'female', text: this.$i18n.t('registration.genderPage.female') }],
      lookfor: [{ value: 'male', text: this.$i18n.t('registration.genderPage.lookformale') }, { value: 'female', text: this.$i18n.t('registration.genderPage.lookforfemale') }]
    };
  },
  computed: {
    ...configGetters(['configData']),
    ...profileGetters(['profileData', 'tokensLeft']),
    ...registrationGetters(['cities'])
  },
  watch: {
    $route (newVal, oldVal) {
      this.isModalOpen = newVal.query?.edit || false;
    }
  },
  async mounted () {
    await this.getUser();
  },
  methods: {
    ...profileActions(['editUser', 'getUser']),
    ...registrationActions(['getCities']),
    openFieldEditor (name, modal = true) {
      this.currentEditField = name;
      this.isModalOpen = modal;
    },
    handleCancel () {
      this.currentEditField = '';
      this.isModalOpen = false;
    },
    handleChange (field, value) {
      if (!value) {
        value = this.currentEditField;
      }
      // console.log('handleChange', value)
      this.currentEditField = '';
      if (field === 'gender' || field === 'looking_gender') {
        this.editUser({ [field]: value.value });
      } else {
        this.editUser({ [field]: value });
      }
    },
    // upperCaseFirst(value) {
    //   if (value) {
    //     return  value.charAt(0).toUpperCase()+ value.slice(1)
    //   }
    // },
    getGender (value) {
      const g = this.gender.find(i => i.value === value);
      if (g) {
        // console.log('getGnder', g.text)
        return g.text;
      }
    },
    getLookForGender (value) {
      const g = this.lookfor.find(i => i.value === value);
      if (g) {
        // console.log('getLookForGnder', g.text)
        return g.text;
      }
    },
    async handleVerification () {
      await this.$UserAPI.verifyEmail(this.$route.fullPath);
      this.isEmailSent = true;
    },
    openTokensPage () {
      this.$router.push('/tokens');
    },
    closeModal () {
      this.cancelMembership = false;
      // this.isEmailSent = false
      // this.showModal = false
    },
    handleMembership () {
      this.cancelMembership = true;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "./theProfileForm.scss"
</style>
