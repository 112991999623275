<template>
  <div class="number-wrapper">
    <div class="content">
      <p class="info">
        {{ $t("notifications.tokenGiven") }}
      </p>

      <div class="input-block">
      </div>

      <Button
        :title="$t('buttons.confirm')"
        bgColor="#428D83"
        textColor="#fff"
        @click.native="handleSend"
      />
    </div>
  </div>
</template>

<script>
import Button from "@/components/Button/Button";
import TelephoneInput from "@/components/TelephoneInput/TelephoneInput";

export default {
  components: {
    Button,
    TelephoneInput
  },

  data() {
    return {
      number: "",
      isValidNumber: null
    };
  },

  methods: {
    handleSend() {
      this.$emit("close");
    },

    handleNumber(info) {
      this.number = info.number;
      this.isValidNumber = info.valid;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "./tokenGiven.scss";
</style>
