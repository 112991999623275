<template>
  <!-- <div class="number-wrapper"> -->
    <div class="content">
      <!-- <img src="@/assets/images/whatsapp-logo-big.png" alt="123" class="icon" /> -->

      <p class="info">
        {{ $t("whatsapp.number") }}
      </p>

      <div class="input-block">
        <TelephoneInput @getNumber="handleNumber"/>
      </div>

      <Button
        :title="$t('buttons.send')"
        bgColor="#428D83"
        textColor="#fff"
        :disabled="!isValidNumber"
        @click.native="handleSend"
      />
    </div>
  <!-- </div> -->
</template>

<script>
import Button from "@/components/Button/Button"
import TelephoneInput from "@/components/TelephoneInput/TelephoneInput"

export default {
  components: {
    Button,
    TelephoneInput
  },
  data () {
    return {
      number: "",
      isValidNumber: null
    }
  },
  mounted () {
  },
  methods: {
    handleSend () {
      if (!this.isValidNumber) return

      this.$emit("step", 1)
      this.$emit("enterNumber", this.number)
    },
    handleNumber (info) {
      this.number = info.number
      this.isValidNumber = info.valid
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./whatsAppNumber.scss";
</style>
