<template>
  <div class="modal">
    <div class="back" @click="handleClose"><span class="material-icons">arrow_back_ios</span></div>
    <div class="modal-content">
      <h2>{{ title }}</h2>

      <div class="select">
        <input v-model="input" :type="type" class="profile_select"/>
      </div>
      <Button :title="$t('profile.btn')" @click.native="handleChange" />
    </div>
  </div>
</template>

<script>
import Button from "../Button/Button";
export default {
  components: {
    Button
  },
  props: {
    type:{
      type:String,
      default:"text"
    },
    closeHandler: {
      type: Function,
      default: () => {}
    },
    title: {
      type: String,
      default: ""
    },
    value:{
      type: String,
      default: ""
    }
  },
  data() {
    return {
      input: ""
    };
  },
  mounted () {
    // console.log('Component mounted')
    this.input = this.value
    this.$router.push({
      query : {
        edit : true
      }
    })    
  },
  beforeDestroy () {
    // console.log('Component beforeDestroy')
    this.$router.push({
      query : {}
    })    
  },
  methods: {
    handleChange () {
      this.$emit("changed", this.input);
    },
    handleClose () {
      this.$emit("cancel");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "./ModalInput.scss";
.back {
  position: absolute;
  z-index: 2;
  top: 1.5rem;
  left: 10px;
  color: black;
}
</style>
