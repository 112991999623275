import types from "./types"

export default {
  [types.GET_PROFILE_SUCCESS](state, data) {
    state.profileData = data
    state.loading = false
  },
  [types.GET_PROFILE_FAIL](state, error) {
    state.loading = false
    state.error = error
  },
  [types.EDIT_PROFILE_SUCCESS](state, data) {
    const stateData = { ...state.profileData, ...data }
    state.profileData = { ...stateData }
    state.loading = false
  },
  [types.EDIT_PROFILE_FAIL](state, error) {
    state.loading = false
    state.error = error
  },
  [types.SET_WHATSAPP_NUMBER_SUCCESS](state, number) {
    state.profileData.whatsappNumber = number
    state.loading = false
  },
  [types.SET_WHATSAPP_NUMBER_FAIL](state, error) {
    state.loading = false
    state.error = error
  },
  [types.CHECK_WHATSAPP_CODE_SUCCESS](state) {
    state.profileData.whatsappVerified = true
    state.loading = false
  },
  [types.CHECK_WHATSAPP_CODE_FAIL](state, error) {
    state.profileData.whatsappVerified = false
    state.loading = false
    state.error = error
  },
  [types.CHECK_EMAIL_TOKEN_SUCCESS](state, data) {
    const stateData = { ...state.profileData, ...data }
    state.profileData = { ...stateData }
    state.loading = false
  },
  [types.CHECK_EMAIL_TOKEN_FAIL](state, error) {
    state.loading = false
    state.error = error
  },
  [types.DECREMENT_SUCCESS](state) {
    state.loading = false
  },
  [types.DECREMENT_FAIL](state, error) {
    state.loading = false
    state.error = error
  },
  [types.CHECK_PAYMENT_SUCCESS](state, data) {
    state.profileData.count_tokens = data.count_tokens * 20
    state.loading = false
  },
  [types.CHECK_PAYMENT_FAIL](state, error) {
    state.loading = false
    state.error = error
  },
  [types.GETPIN_SUCCESS](state, pincode) {
    state.profileData.phone_pay_pin = pincode
    state.loading = false
  },
  [types.GETPIN_FAIL](state, error) {
    state.loading = false
    state.error = error
  },
  [types.SET_PHONE_NUMBER_SUCCESS](state, number) {
    state.profileData.phoneNumber = number
    state.loading = false
  },
  [types.SET_PHONE_NUMBER_FAIL](state, error) {
    state.loading = false
    state.error = error
  },
  [types.CHECK_PHONE_CODE_SUCCESS](state) {
    state.profileData.phoneVerified = true
    state.loading = false
  },
  [types.CHECK_PHONE_CODE_FAIL](state, error) {
    state.loading = false
    state.error = error
  },
  [types.GET_LIKES_SUCCESS](state, data) {
    state.profileData.likes = data
    state.loading = false
  },
  [types.GET_LIKES_FAIL](state, error) {
    state.loading = false
    state.error = error
  },
}
