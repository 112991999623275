<template>
  <div>
    <div ref="googlePay" class="gpay" />
  </div>
</template>
<script>
const cachedScripts = {};
/* fetch sugar methods */
const handleResponse = (response) => {
  return response.json()
    .then(function (json) {
      // console.log('handleResponse', response);
      if (response.status >= 200 && response.status < 300) {
        // Return success JSON response
        return json;
      }

      // Throw error with response status
      throw new Error(json ? json.status : null);
    });
};

const postJson = (url, data) => {
  let json = data;
  if (typeof data === 'object') {
    json = JSON.stringify(data);
  } else if (typeof data !== 'string') {
    throw new TypeError('Data must be an object or a JSON string.');
  }
  // console.log('postJson', json);return;
  return fetch(url, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    },
    body: json
  }).then(handleResponse);
};

// eslint-disable-next-line no-unused-vars
const getJson = (url) => {
  return fetch(url, {
    credentials: 'include',
    headers: {
      Accept: 'application/json'
    }
  }).then(handleResponse);
};

export default {
  name: 'GooglePay',
  props: {
    totalPrice: {
      type: Number,
      default: 0.00,
      required: true
    },
    plan: {
      type: Object,
      default: () => {}
    },
    isTrial: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    ipinfo: {
      ipaddr: '',
      currency: 'EUR',
      country: 'PT',
      city: 'Lisboa',
      postal: '1100'
    },
    cid: '',
    config: {
      /**
       * Define the version of the Google Pay API referenced when creating your
       * configuration
       *
       * @see {@link https://developers.google.com/pay/api/web/reference/request-objects#PaymentDataRequest|apiVersion in PaymentDataRequest}
       */
      baseRequest: {
        apiVersion: 2,
        apiVersionMinor: 0
      },
      /**
       * Identify your gateway and your site's gateway merchant identifier
       *
       * The Google Pay API response will return an encrypted payment method capable
       * of being charged by a supported gateway after payer authorization
       *
       * @todo check with your gateway on the parameters to pass
       * @see {@link https://developers.google.com/pay/api/web/reference/request-objects#gateway|PaymentMethodTokenizationSpecification}
       */
      tokenizationSpecification: {
        type: 'PAYMENT_GATEWAY',
        parameters: {
          gateway: 'emerchantpay',
          gatewayMerchantId: 'BCR2DN4TZKW7NBIY'
        }
      },
      /**
       * Card networks supported by your site and your gateway
       *
       * @see {@link https://developers.google.com/pay/api/web/reference/request-objects#CardParameters|CardParameters}
       * @todo confirm card networks supported by your site and gateway
       */
      allowedCardNetworks: [
        'MASTERCARD',
        'VISA'
      ],
      /**
       * Card authentication methods supported by your site and your gateway
       *
       * @see {@link https://developers.google.com/pay/api/web/reference/request-objects#CardParameters|CardParameters}
       * @todo confirm your processor supports Android device tokens for your
       * supported card networks
       */
      allowedCardAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS']
    }
  }),
  computed: {},
  async mounted () {
    // console.error('g mounted', this.isGooglePayLoaded());
    if (!this.isGooglePayLoaded()) {
      try {
        await this.loadScript('https://pay.google.com/gp/p/js/pay.js');
      } catch (err) {
        // console.error(err);
        return;
      }
    }
    this.loadGooglePay();
    // This loads the google pay button on the screen.
    // I used a timeout because I need to wait for the
    // script to load, since I'm loading it when the page mounts
    this.cid = this.$storage.getCookie('_cid') || '';
    // const waitUntil = (condition, checkInterval = 100) => {
    //   return new Promise((resolve) => {
    //     const interval = setInterval(() => {
    //       if (!condition()) { return; };
    //       clearInterval(interval);
    //       resolve();
    //     }, checkInterval);
    //   });
    // };
    // await waitUntil(() => window.google !== undefined).then(() => this.loadGooglePay());
    // setTimeout(() => this.loadGooglePay(), 3000);
  },
  methods: {
    isGooglePayLoaded () {
      return 'google' in (window || global) && !!window.google?.payments?.api?.PaymentsClient;
    },
    loadScript (src) {
      const existing = cachedScripts[src];
      if (existing) {
        return existing;
      }

      const promise = new Promise((resolve, reject) => {
        // Create script
        const script = document.createElement('script');
        script.src = src;
        script.async = true;

        // Script event listener callbacks for load and error
        const onScriptLoad = () => {
          resolve();
        };

        const onScriptError = () => {
          cleanup();

          // Remove from cachedScripts so that we can try loading again
          delete cachedScripts[src];
          script.remove();

          reject(new Error(`Unable to load script ${src}`));
        };

        script.addEventListener('load', onScriptLoad);
        script.addEventListener('error', onScriptError);

        // Add script to document body
        document.body.appendChild(script);

        // Remove event listeners on cleanup
        function cleanup () {
          script.removeEventListener('load', onScriptLoad);
          script.removeEventListener('error', onScriptError);
        }
      });

      cachedScripts[src] = promise;

      return promise;
    },
    async getIpInfo () {
      // console.log('getIpInfo');
      await fetch('/ipinfo', {
        credentials: 'include',
        headers: {
          Accept: 'application/json'
        }
      })
        .then(async (response) => {
          return await response.json();
        })
        .then((json) => {
          // console.log('json', json);
          this.ipinfo = {
            country: json.countryCode,
            ipaddr: json.ip,
            city: json.city,
            currency: (json.countryCode === 'GB' ? 'GBP' : ['AU', 'US', 'CA', 'NZ', 'ZA'].includes(json.countryCode) ? 'USD' : 'EUR'),
            postal: json.postal
          };
        });
    },
    loadGooglePay () {
      /*
      A helper function that requests an Apple Pay merchant session using a promise.
      */

      /**
       * Describe your site's support for the CARD payment method and its required
       * fields
       *
       * @see {@link https://developers.google.com/pay/api/web/reference/request-objects#CardParameters|CardParameters}
       */
      const baseCardPaymentMethod = {
        type: 'CARD',
        parameters: {
          allowedAuthMethods: this.config.allowedCardAuthMethods,
          allowedCardNetworks: this.config.allowedCardNetworks
        }
      };

      /**
       * Describe your site's support for the CARD payment method including optional
       * fields
       *
       * @see {@link https://developers.google.com/pay/api/web/reference/request-objects#CardParameters|CardParameters}
       */
      const cardPaymentMethod = Object.assign({}, baseCardPaymentMethod, {
        tokenizationSpecification: this.config.tokenizationSpecification
      });

      /**
       * An initialized google.payments.api.PaymentsClient object or null if not yet set
       *
       * @see {@link getGooglePaymentsClient}
       */
      let paymentsClient = null;

      /**
       * Configure your site's support for payment methods supported by the Google Pay
       * API.
       *
       * Each member of allowedPaymentMethods should contain only the required fields,
       * allowing reuse of this base request when determining a viewer's ability
       * to pay and later requesting a supported payment method
       *
       * @returns {object} Google Pay API version, payment methods supported by the site
       */
      const getGoogleIsReadyToPayRequest = () => {
        return Object.assign({}, this.config.baseRequest, {
          allowedPaymentMethods: [baseCardPaymentMethod]
        });
      };

      /**
       * Configure support for the Google Pay API
       *
       * @see {@link https://developers.google.com/pay/api/web/reference/request-objects#PaymentDataRequest|PaymentDataRequest}
       * @returns {object} PaymentDataRequest fields
       */
      const getGooglePaymentDataRequest = () => {
        const paymentDataRequest = Object.assign({}, this.config.baseRequest);
        paymentDataRequest.allowedPaymentMethods = [cardPaymentMethod];
        paymentDataRequest.transactionInfo = getGoogleTransactionInfo();
        paymentDataRequest.merchantInfo = {
          // @todo a merchant ID is available for a production environment after approval by Google
          // See {@link https://developers.google.com/pay/api/web/guides/test-and-deploy/integration-checklist|Integration checklist}
          merchantId: 'BCR2DN4TZKW7NBIY',
          merchantName: 'Liberty Mobile Limited'
        };
        return paymentDataRequest;
      };

      /**
       * Return an active PaymentsClient or initialize
       *
       * @see {@link https://developers.google.com/pay/api/web/reference/client#PaymentsClient|PaymentsClient constructor}
       * @returns {google.payments.api.PaymentsClient} Google Pay API client
       */
      const getGooglePaymentsClient = () => {
        if (paymentsClient === null) {
          // eslint-disable-next-line no-undef
          paymentsClient = new google.payments.api.PaymentsClient({
            // Alterar o environment para 'PRODUCTION' em prod
            // environment: 'TEST'
            // environment: 'PRODUCTION'
            environment: process.env.NODE_ENV === 'production' ? 'PRODUCTION' : 'TEST'
          });
        }
        return paymentsClient;
      };

      /**
       * Initialize Google PaymentsClient after Google-hosted JavaScript has loaded
       *
       * Display a Google Pay payment button after confirmation of the viewer's
       * ability to pay.
       */
      const onGooglePayLoaded = () => {
        const paymentsClient = getGooglePaymentsClient();
        paymentsClient
          .isReadyToPay(getGoogleIsReadyToPayRequest())
          .then((response) => {
            if (response.result) {
              addGooglePayButton();
              // @todo prefetch payment data to improve performance after confirming site functionality
              // prefetchGooglePaymentData();
              this.$emit('loaded', response.result);
            }
          })
          .catch((err) => {
            // show error in developer console for debugging
            // console.error(err);
            this.$emit('loadedError', err);
          });
      };

      /**
       * Add a Google Pay purchase button alongside an existing checkout button
       *
       * @see {@link https://developers.google.com/pay/api/web/reference/request-objects#ButtonOptions|Button options}
       * @see {@link https://developers.google.com/pay/api/web/guides/brand-guidelines|Google Pay brand guidelines}
       */
      const addGooglePayButton = () => {
        const paymentsClient = getGooglePaymentsClient();
        const button = paymentsClient.createButton({
          buttonColor: 'default',
          buttonType: 'buy',
          buttonRadius: 33,
          buttonSizeMode: 'fill',
          onClick: onGooglePaymentButtonClicked
        });
        this.$refs.googlePay.appendChild(button);
      };

      /**
       * Provide Google Pay API with a payment amount, currency, and amount status
       *
       * @see {@link https://developers.google.com/pay/api/web/reference/request-objects#TransactionInfo|TransactionInfo}
       * @returns {object} transaction info, suitable for use as transactionInfo property of PaymentDataRequest
       */
      const getGoogleTransactionInfo = () => ({
        countryCode: this.ipinfo.country,
        currencyCode: this.ipinfo.currency,
        totalPriceStatus: 'FINAL',
        // set to cart total
        totalPrice: this.totalPrice.toString()
      });

      /**
       * Prefetch payment data to improve performance
       *
       * @see {@link https://developers.google.com/pay/api/web/reference/client#prefetchPaymentData|prefetchPaymentData()}
       */
      // function prefetchGooglePaymentData() {
      //   const paymentDataRequest = getGooglePaymentDataRequest();
      //   // transactionInfo must be set but does not affect cache
      //   paymentDataRequest.transactionInfo = {
      //     totalPriceStatus: "NOT_CURRENTLY_KNOWN",
      //     currencyCode: "USD",
      //   };
      //   const paymentsClient = getGooglePaymentsClient();
      //   paymentsClient.prefetchPaymentData(paymentDataRequest);
      // }

      /**
       * Show Google Pay payment sheet when Google Pay payment button is clicked
       */
      const onGooglePaymentButtonClicked = async () => {
        await this.getIpInfo();
        const paymentDataRequest = getGooglePaymentDataRequest();
        paymentDataRequest.transactionInfo = getGoogleTransactionInfo();

        const paymentsClient = getGooglePaymentsClient();
        paymentsClient
          .loadPaymentData(paymentDataRequest)
          .then((paymentData) => {
            // handle the response
            this.$emit('paymentAuthorized');
            processPayment(paymentData);
          })
          .catch((err) => {
            // show error in developer console for debugging
            // console.error(err);
            this.$emit('paymentError', err);
          });
      };
      /**
       * Process payment data returned by the Google Pay API
       *
       * @param {object} paymentData response from Google Pay API after user approves payment
       * @see {@link https://developers.google.com/pay/api/web/reference/response-objects#PaymentData|PaymentData object reference}
       */
      const processPayment = async (paymentData) => {
        // show returned data in developer console for debugging
        // console.log(paymentData);return;
        // @todo pass payment token to your gateway to process payment
        const paymentToken = JSON.parse(paymentData.paymentMethodData.tokenizationData.token);
        await postJson('/googlepay/payment-authorize', {
          token: paymentToken,
          params: {
            price: this.totalPrice,
            currency: this.ipinfo.currency,
            country: this.ipinfo.country,
            city: this.ipinfo.city,
            zip: this.ipinfo.postal,
            clickId: this.cid,
            ipaddr: this.ipinfo.ipaddr,
            ua: window.navigator.userAgent,
            plan: this.plan
          }
        })
          .then((response) => {
            // console.log('processPayment response', response);
            this.$emit('paymentSuccess', response);
            window.location.href = '/?txId=' + response.txid + '&ta=' + response.amount + '&currency=' + response.currency;
          }, (status) => {
            // console.log('processPayment status', JSON.stringify(status));
            this.$emit('paymentError', JSON.stringify(status));
          });
      };

      onGooglePayLoaded();
    }
  }
};
</script>
<style scoped>
.gpay {
  display: inline-flex;
  justify-content: center;
  font-size: 12px;
  border-radius: 5px;
  padding: 0px;
  box-sizing: border-box;
  min-width: 200px;
  min-height: 32px;
  max-height: 60px;
  width: 100%;
  height: 60px;
}
</style>
