import axios from 'axios';

const flowKeywords = {
  'hi, nice profile, how are you?': 'icebreaker_replies1',
  'hi, can you talk now?': 'icebreaker_replies2',
  'i like what i see': 'icebreaker_replies3',
  "let's get naughty": 'icebreaker_replies4',
  hookup: 'hookup_replies',
  romantic: 'romantic_replies',
  // facetime: 'facetime_replies',
  // availability_response: 'availability_response_replies',
  // private: 'private_replies',
  // intimate: 'intimate_replies',
  // playful: 'playful_replies',
  // fun: 'fun_replies',
  // seductive: 'seductive_replies',
  // tease: 'tease_replies',
  // chat: 'chat_replies',
  // video_call: 'video_call_replies',
  // adventure: 'adventure_replies',
  // exploration: 'exploration_replies',
  // date: 'date_replies',
  // connection: 'connection_replies',
  // exciting: 'exciting_replies',
  // spicy: 'spicy_replies',
  // flirt: 'flirty_replies',
  // sassy: 'sassy_replies',
  deep: 'deep_talk_replies'
};

const flowMessages = {
  letsgetnaughty_replies: [
    "let's get naughty bb, I'm in the mood - we open cam here or facetime?",
    'what for naughty stuff r u looking for bb?',
    'what for naughty stuff you enjoy {username} one?'
  ],
  icebreaker_replies: [
    'Hi bb! Tell me something special about yourself',
    'heyy, how are you?',
    'Hii {username}, all good?',
    "heyy {username}, how's it going?",
    "hii {username}, how are you? what's ur name please?",
    'Hii {username}, how are you doing?',
    'mmm {username}, how are you?',
    'hii bb',
    'heyy {username}, all good bb?'
  ],
  icebreaker_replies1: [
    'Heyy {username}, all good thx and u? Also look’n for funn? Online funn or meetup bb?',
    'Heyy {username}, all good here thx, U? 😉 Also lookn for funn? U fancy to continue online or a casual meetup bb? 😏'
  ],
  icebreaker_replies2: [
    'Eyy {username}, sure I can talk bb, what are you lookn for? Also Funn? Wanne meetup or first online funn?',
    "Sure {username}, I'm up for a chat! What are you after bb? Fancy some online funn or lookn for a casual meetup? 😏"
  ],
  icebreaker_replies3: [
    'mmm thx {username}, would love to see you also bb Are you also looking for funn? Meetup or online funn for now?',
    'mmm thx {username}, would love to see ya too! 😘 U up for some funn? Casual meetup or to continue online for now bb?'
  ],
  icebreaker_replies4: [
    'mmm why not {username}, I’m here and also lookn for funn. U’r looking to meetup or online funn now bb?',
    'Heyy {username}, mmm why not! I’m up for casual fun too. U fancy a hot meet-up or to continue with online funn for starters, bb? 😉'
  ],
  first_chat_replies: [
    "Heyy {username} thx for hitting me up bb, I'm also in the mood for funn😏😍😛 so what u'r saying? u'r lookn for quick online now, not for casual meetup👅?"
  ],
  free_chat_replies: [
    "Hi {username}, I'm here and ready to chat! What are you looking for today?",
    'Hey {username}, glad you messaged me. Are you looking for something fun or romantic?',
    "Hello {username}, it's great to hear from you! What’s on your mind today?"
  ],
  meet_replies: [
    "ahh great bb😘 u got a place or wanne come over😜🍑💦? I'm alone🍓 got no car but can take Uber ofc🫦",
    'U alone now? can u accept my cam invite 🍒👅or quick call😜??? Wanna show U I\'m 4 real bb😘'
  ],
  cam_replies: [
    "mmm niccee bb I'm still in bed🍓 we cam now or need a bit more time😜🍑💦? ",
    'U alone now? can u accept my cam invite 🍒👅or quick call😜??? Wanna show U I\'m 4 real bb😘'
  ],
  // post_chat_triggers: [
  //   "Hey {username}, you've used up your free chats. Want to continue? Purchase a trial now!"
  // ],
  followup_replies: [
    'Hi bb, are you still here?'
  ],
  trigger: [
    'U still about, bb? 😏 I’m keen to connect now… x',
    "I guess u're busy bb, but hit me up when U're back online! Can't wait to get this going 😘"
  ],
  follow_up_replies: [
    'mmm kinda excited bb👐😉 u got time now💋🍓???'
    // "what are you looking for bb? Funn??",
    // 'are you looking for online fun or meetup?',
    // "what are you looking for? Funn??)",
    // 'want a video or we open cam and chat there {username}?',
    // "i got time, home alone looking for fun",
    // 'you look for onliine fun or meetup?',
    // 'live on cam or meetup bb?'
    // "what u got in mind?"
  ],
  hookup_replies: [
    'A hookup sounds exciting, {username}! When are you available for that?',
    'That’s great, {username}! Let’s plan for the hookup. What time works for you?',
    'I’m ready for a hookup, {username}! When would be the best time for you?'
  ],
  romantic_replies: [
    'I’m in the mood for something romantic, {username}. How about you?',
    'Let’s talk about something sweet, {username}. What’s your idea of romance?',
    'I love a good romantic chat, {username}. What’s on your mind?'
  ],
  deep_talk_replies: [
    'Let’s get deep, {username}. What’s on your heart?',
    'I’m all for deep talks, {username}. Let’s open up.',
    'Let’s talk about something deep, {username}. What’s your biggest dream?'
  ],
  alternatives: [
    'Can you explain that a bit more?',
    'How does that make you feel?',
    "What's your favorite part about it?",
    'Have you ever experienced something similar?',
    'What are your thoughts on that?',
    'How did you come to that conclusion?',
    'What do you enjoy most about it?'
  ]
};

const payload = {
  models: [
    'Anthracite-Org/Magnum-v4-72b',
    'aphrodite/knifeayumu/Cydonia-v1.3-Magnum-v4-22B',
    'aphrodite/knifeayumu/Cydonia-v1.3-Magnum-v4-22B',
    'Behemoth-123B-v1.2-4.0bpw-h6-exl2',
    'koboldcpp/Behemoth-123B-v1.2',
    'koboldcpp/Cydonia-v1.3-Magnum-v4-22B',
    'koboldcpp/Cydonia-v1.3-Magnum-v4-22B',
    'koboldcpp/Gemma-2-9B-Blackout-R1',
    'koboldcpp/L3-8B-Stheno-v3.2',
    'koboldcpp/Llama-3.1-8B-Stheno-v3.4-Q8_0-imat',
    'koboldcpp/Llama-3.1-8B-Stheno-v3.4-Q8_0-imat',
    'koboldcpp/Llama-3.2-1B-Instruct-Uncensored.Q4_K_M',
    'koboldcpp/Llama-3.2-3B-Instruct',
    'koboldcpp/Llama-3.2-3B-Instruct-Q4_K_M',
    'koboldcpp/Llama-3.2-3B-Instruct-Q4_K_S',
    'koboldcpp/Lyra-Gutenberg-mistral-nemo-12B',
    'koboldcpp/Lyra-Gutenberg-mistral-nemo-12B',
    'koboldcpp/magnum-v4-22b-Q5_K_L',
    'koboldcpp/Meta-Llama-3.1-8B-Instruct',
    'koboldcpp/mini-magnum-12b-v1.1',
    'koboldcpp/mini-magnum-12b-v1.1-iMat-Q4_K_M',
    'koboldcpp/Mistral-Nemo-12B-ArliAI-RPMax-v1.1.i1-Q6_K',
    'koboldcpp/Mistral-Nemo-12B-ArliAI-RPMax-v1.1.i1-Q6_K',
    'koboldcpp/NemoMix-Unleashed-12B',
    'NousResearch/Hermes-2-Pro-Mistral-7B',
    'tabbyAPI/EVA-UNIT-01_EVA-Qwen2.5-32B-v0.2-exl2-4.1bpw'
  ],
  // models: ['koboldcpp/Mistral-Nemo-12B-ArliAI-RPMax-v1.1.i1-Q6_K'],
  params: {
    dynatemp_exponent: 1,
    dynatemp_range: 0,
    max_context_length: 4096,
    max_length: 200,
    min_p: 0,
    n: 1,
    rep_pen: 1.07,
    rep_pen_range: 360,
    rep_pen_slope: 0.7,
    sampler_order: [
      6,
      0,
      1,
      3,
      4,
      2,
      5
    ],
    smoothing_factor: 0,
    stop_sequence: [
      '##User_name##:',
      '\n##User_name## ',
      '\n##Char_name##: '
    ],
    temperature: 0.75,
    tfs: 1,
    top_a: 0,
    top_k: 100,
    top_p: 0.92,
    typical: 1,
    use_default_badwordsids: false
  },
  prompt: '[Character: ##Char_name##; species: Human; age: ##Char_age##; gender: female; physical appearance: cute, attractive; personality: cheerful, upbeat, friendly; likes: chatting; description: ##Char_name## is outgoing, adventurous, and enjoys many interesting hobbies. She has had a secret crush on you for a long time.]\n',
  workers: []
};

// eslint-disable-next-line no-unused-vars
const poll = async (id) => {
  // const startTime = Date.now();
  while (true) {
    try {
      const response = await axios.get(`https://stablehorde.net/api/v2/generate/text/status/${id}`);
      console.log(response);
      if (response.status === 200) {
        const result = response.data;
        if (result.status === 'completed') {
          return result.generated_text;
        }
      }
    } catch (error) {
      console.error('Error waiting for response:', error);
    }
    await new Promise(resolve => setTimeout(resolve, 5000));// Wait for 5 seconds before checking again
  }

  // try {
  //   const { data } = await axios.get(`https://stablehorde.net/api/v2/generate/text/status/${id}`);
  //   console.log(data);

  //   if (data.faulted) {
  //     console.log('Fault encountered during text generation from AI Horde.');
  //     return;
  //   }

  //   if (data.done) {
  //     console.log(`The request was successfully processed from AI Horde.`);
  //     return data.generations[0].text;
  //   }
  // } catch {
  //   console.log('The request was failed.');
  // }
  // setTimeout(poll, 5000, id);//Math.max(0, 4000 - (Date.now() - startTime)));
};

const getModels = async (apiKey) => {
  try {
    const response = await axios.get('https://stablehorde.net/api/v2/status/models?type=text', {}, {
      headers: {
        'Content-Type': 'application/json',
        apykey: apiKey
      }
    });
    console.log(response);
    if (response.status === 200) {
      return response.data.map(model => model.name);
    }
  } catch (error) {
    console.error('Error fetching model names:', error);
  }
  return [];
};

const botReply = async (messages, username, modelName, modelAge, ai = false) => {
  const reply = {};
  const apiKey = '0000000000';
  if (ai) {
    try {
      const models = await getModels(apiKey);
      payload.models = models.filter(name => !name.includes('CohereForAI'));
      // payload.prompt = payload.prompt.replaceAll('##Char_name##', modelName);
      // payload.prompt = payload.prompt.replaceAll('##Char_age##', modelAge);
      // payload.prompt = payload.prompt.replaceAll('##User_name##', username);
      payload.prompt = messages.map((message) => {
        if (message.from === 'model') {
          return `${modelName}: ${message.text}`
        } else {
          return `${username}: ${message.text}`
        }
      }).join('\n');
      // payload.prompt += `${username}: ${message}`;
      payload.stop_sequence = [
            `${username}:`,
            `\n${username} `,
            `\n${modelName}: `
      ];
      // console.log('prompt', payload.prompt); return
      await generateText(payload, apiKey).then((generatedText) => {
        console.log(generatedText);
        reply.id = Date.now();
        reply.message = generatedText;
      });

      // const { data } = await axios.post('https://stablehorde.net/api/v2/generate/text/async', payload, {
      //   headers: {
      //     'Content-Type': 'application/json',
      //     apikey: '0000000000'
      //   }
      // });
      // if (data.id) {
      //   console.log('A new request has been added for processing to AI Horde.', data.id);
      //   return await poll(data.id);
      // }
    } catch (error) {
      console.log('The new request was not added for processing to AI Horde due to an error.', error);
    }
  } else {
    const emojiRegex = /[\p{Emoji_Presentation}\p{Extended_Pictographic}]/gu;
    const flowType = (message, firstone = false) => {
      const cam = ['yes', 'yeah', 'yea', 'yep', 'online', 'fun', 'both', 'cam', 'phone', 'call', 'voice', 'face', 'live', 'video', 'number', 'snap', 'whatsapp', 'skype', 'chat', 'catfish', 'fake', 'nope', 'pussy', 'pic', 'vid', 'hello', 'see', 'tits', 'hard', 'ass', 'boobs', 'open', 'trade'];
      const meet = ['sex', 'fucking', 'casual', 'riding', 'sit', 'where', 'hook', 'meet', 'home', 'place', 'car', 'nearby', 'today', 'evening', 'afternoon', 'morning', 'shag', 'real', 'fuck', 'here', 'pick', 'head', 'oral'];
      // Find the full key that matches the partial key
      const fullKey = Object.keys(flowKeywords).find(key => message.includes(key));
      // Get the value based on the full key
      // console.log('flowType', message, fullKey, firstone);
      if (!(fullKey || firstone)) {
        return cam.find(e => message.includes(e)) ? 'cam_replies' : meet.find(e => message.includes(e)) ? 'meet_replies' : null;
      }

      return flowKeywords[fullKey];
    };
    const userMessages = messages.filter(m => m.from === 'client');
    const message = userMessages[userMessages.length - 1]?.text.replace(emojiRegex, '').toLowerCase().trim();
    let flow = '';
    switch (userMessages.length) {
      case 1:
        flow = flowType(message, messages.length === 1) ?? 'first_chat_replies';
        break;
      case 2:
        flow = flowType(message) ?? 'follow_up_replies';
        break;
      case 3:
        // flow = window.$nuxt.$store.state.messages.previousFlow ?? flowType(message) ?? 'follow_up_replies';
        flow = 'meet_replies';
        break;
      default:
        flow = 'alternatives';
    }
    // console.log('flow', message, flow);
    window.$nuxt.$store.commit('messages/setPreviousFlow', flow);
    reply.id = Date.now();
    const capitalize = s => (s && String(s[0]).toUpperCase() + String(s).slice(1));
    const calculateTypingDelay = (message) => {
      const baseDelay = process.env.NODE_ENV !== 'production' ? 5000 : Math.random() * 1000 + 500;// Base delay in milliseconds
      const delayPerCharacter = process.env.NODE_ENV !== 'production' ? 0 : 150;// Additional delay per character in milliseconds
      const messageLength = message.length;
      return baseDelay + (delayPerCharacter * messageLength);
    };

    let flowIndex = 0;
    if (!['meet_replies', 'cam_replies'].includes(flow)) {
      flowIndex = Math.floor(Math.random() * flowMessages[flow].length);
      let attempts = 0;
      const maxAttempts = messages.length;
      while (messages.includes(reply.message) && attempts < maxAttempts) {
        reply.message = flowMessages[flow][flowIndex].replace('{username}', capitalize(username).replace(/gues.../i, 'bb'));
        attempts++;
      }
    } else if (userMessages.length === 3) {
      flowIndex = 1;
    }
    reply.message = flowMessages[flow][flowIndex].replace('{username}', capitalize(username.replace(/gues.../i, 'bb')));
    await new Promise(resolve => setTimeout(resolve, calculateTypingDelay(reply.message)));// Wait for 5 seconds before checking again
    // console.log(message, flow, window.$nuxt.$store.state.messages.previousFlow);
  }
  return reply;
};

async function generateText (payload, apiKey) {
  const url = 'https://stablehorde.net/api/v2/generate/text/async';
  const headers = {
    // apykey: `${apiKey}`,
    apikey: '0000000000',
    'Content-Type': 'application/json'
  };

  try {
    const { data } = await axios.post(url, payload, { headers });
    if (data.id) {
      return await waitForResponse(data.id, apiKey);
    }
  } catch (error) {
    console.error('Error generating text:', error);
  }
  return null;
}

async function waitForResponse (jobId, apiKey) {
  const url = `https://stablehorde.net/api/v2/generate/text/status/${jobId}`;
  const headers = {
    // apykey: `${apiKey}`,
    apikey: '0000000000'
  };

  while (true) {
    try {
      const { data } = await axios.get(url, { headers });
      console.log(data);
      if (data.faulted) {
        console.log('Fault encountered during text generation from AI Horde.');
        return;
      }

      if (data.done) {
        console.log('The request was successfully processed from AI Horde.');
        return data.generations[0].text;
      }
      // if (response.status === 200) {
      //     const result = response.data;
      //     if (result.status === "completed") {
      //         return result.generated_text;
      //     }
      // }
    } catch (error) {
      console.error('Error waiting for response:', error);
    }
    await new Promise(resolve => setTimeout(resolve, 5000));// Wait for 5 seconds before checking again
  }
}

export default botReply;
