<template>
  <div class="block-wrapper">
    <nuxt-link :to="`/girls/${modelId}`">
      <img :src="getAvatar(photo)" alt="avatar">
      <div v-if="online" class="status" />
    </nuxt-link>

    <div class="block-info" @click="handleClick">
      <div class="block-info-left">
        <span><b>{{ name }}</b></span>
        <span class="message">{{ lastMessage.replace(/(<([^>]+)>)/ig, '') }}</span>
      </div>

      <div class="block-info-right">
        <span>{{ calculateTime(timestamp) }}</span>

        <div v-if="unread" class="alert alert-msg">
          {{ unread }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const moment = require('moment');

export default {
  props: {
    avatar: {
      type: String,
      default: ''
    },
    modelId: {
      type: Number,
      default: 1
    },
    clientId: {
      type: Number,
      default: null
    },
    name: {
      type: String,
      default: 'Melanie'
    },
    timestamp: {
      type: String,
      default: new Date(Date.now()).toISOString()
    },
    unread: {
      type: Number,
      default: 0
    },
    online: {
      type: Number,
      default: 0
    },
    lastMessage: {
      type: String,
      default: 'Hey, honey!...'
    },
    photo: {
      type: String,
      default: ''
    }
  },
  methods: {
    getAvatar (src) {
      if (src != null) {
        return `${this.$config.apiHost}/storage/twilio/${this.modelId}/small_${src}`;
      } else {
        return '';
      }
    },
    handleClick () {
      this.$emit('clicked', this.name, this.modelId);
    },
    calculateTime (value) {
      return moment(value).fromNow();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "./chatBlock.scss";
</style>
