var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "toast__wrap" }, [
    _c("div", { staticClass: "toast__item" }, [
      _vm.img
        ? _c("div", { staticClass: "toast__img" }, [
            _c("img", {
              attrs: { src: _vm.img, alt: "photo" },
              on: { click: _vm.handleClick },
            }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "toast__info", on: { click: _vm.handleClick } },
        [
          _c("span", { staticClass: "toast__info-name" }, [
            _vm._v(_vm._s(_vm.name)),
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "toast__info-text" }, [
            _vm._v(_vm._s(_vm.text)),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "svg",
        {
          staticClass: "icon-close",
          attrs: { viewBox: "0 0 50 45", xmlns: "http://www.w3.org/2000/svg" },
        },
        [
          _c("path", {
            attrs: {
              d: "M9.15625 6.3125L6.3125 9.15625L22.15625 25L6.21875 40.96875L9.03125 43.78125L25 27.84375L40.9375 43.78125L43.78125 40.9375L27.84375 25L43.6875 9.15625L40.84375 6.3125L25 22.15625Z",
              fill: "#fff",
            },
          }),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }