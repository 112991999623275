var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ChatUI", {
    attrs: {
      initialFeed: _vm.initialFeed,
      "new-message": _vm.newMessage,
      isLoading: _vm.isLoading,
    },
    on: { newOwnMessage: _vm.newOwnMessage, closed: _vm.handleClose },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }