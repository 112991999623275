<template>
  <div class="modal__content">
    <div class="modal__content-img mb-s"><img :src="img" alt="photo"></div>
    <div class="modal__content-text mb-m">
      {{ text }}
    </div>
    <Button
      title="See offers"
      @click.native="handleSubmit"
    />
    <Link/>
  </div>
</template>

<script>
import Button from "../Button/Button"
import Link from "../Link/Link"

export default {
  name: "ModalMessage",
  components:{
    Button,
    Link
  },
  props:{
    text: {
      type: String,
      default: ""
    },
    img: {
      type: String,
      default: ""
    },
  },
  methods:{
    handleSubmit () {
      this.$emit('submit')
    }
  }
}
</script>

<style lang="scss">
@import "./modalmessage";
</style>
