<template>
  <div class="modal__content">
    <div class="modal__content-img mb-s"><img :src="img" alt="photo" v-if="img"></div>
    <div class="modal__content-text mb-m">
      {{ text }}
    </div>
    <Button
      title="Chat Now"
      @click.native="handleSubmit"
    />
  </div>
</template>

<script>
import Button from "../Button/Button"

export default {
  components: {
    Button
  },
  props:{
    text: {
      type: String,
      default: 'You got new message!'
    },
    img: {
      type: String,
      default: ""
    }
  },
  data () {
    return {
    }
  },
  methods: {
    handleSubmit() {
      this.$emit("startChat")
    }
  }
};
</script>

<style lang="scss" scoped>
@import "./NewMessageEvent";
</style>
