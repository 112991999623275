<template>
  <vue-tel-input
    v-model="phone"
    ref="phoneInput"
    @input="handleInput"
    :autoDefaultCountry="false"
    :dropdownOptions="{ showDialCodeInSelection: true, showFlags: true, showSearchBox: true }"
    :inputOptions="{
      required: true,
      type: 'tel',
      styleClasses: [isValid === false ? 'invalidColor' : null, 'tel-input']
    }"
    styleClasses="tel-input"
    :validCharactersOnly="true"
    :defaultCountry="defaultCountry"
    :preferredCountries="preferredCountries"
  ></vue-tel-input>
</template>

<script>
import { VueTelInput } from "vue-tel-input"
import "vue-tel-input/dist/vue-tel-input.css"

export default {
  components: {
    VueTelInput
  },
  props: {
    defaultCountry: {
      // Default country code, ie: 'AU'
      // Will override the current country of user
      type: [String, Number],
      default: 'DE'
    }
  },
  data () {
    return {
      phone: null,
      isValid: true,
      preferredCountries: ['DE', 'AT', 'CH', 'US', 'GB', 'PT', 'NL', 'FR']
    }
  },
  mounted () {
    this.$nextTick(this.$refs.phoneInput.focus())
  },
  methods: {
    handleInput(number, phone) {
      const numberInfo = {
        number: phone.number,
        valid: phone.valid
      };

      this.isValid = phone.valid

      this.$emit("getNumber", numberInfo)
    }
  }
}
</script>

<style lang="scss">
@import "./telephoneInput.scss";
.tel-input {
  font-size: 1.4rem;
}
</style>
