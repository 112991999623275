var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "block-wrapper" },
    [
      _c("nuxt-link", { attrs: { to: `/girls/${_vm.modelId}` } }, [
        _c("img", { attrs: { src: _vm.getAvatar(_vm.photo), alt: "avatar" } }),
        _vm._v(" "),
        _vm.online ? _c("div", { staticClass: "status" }) : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "block-info", on: { click: _vm.handleClick } }, [
        _c("div", { staticClass: "block-info-left" }, [
          _c("span", [_c("b", [_vm._v(_vm._s(_vm.name))])]),
          _vm._v(" "),
          _c("span", { staticClass: "message" }, [
            _vm._v(_vm._s(_vm.lastMessage.replace(/(<([^>]+)>)/gi, ""))),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "block-info-right" }, [
          _c("span", [_vm._v(_vm._s(_vm.calculateTime(_vm.timestamp)))]),
          _vm._v(" "),
          _vm.unread
            ? _c("div", { staticClass: "alert alert-msg" }, [
                _vm._v("\n        " + _vm._s(_vm.unread) + "\n      "),
              ])
            : _vm._e(),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }