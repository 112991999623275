var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "phonenumber-wrapper" }, [
    _c(
      "div",
      { staticClass: "content" },
      [
        _c("p", { staticClass: "info" }, [
          _vm._v("\n      " + _vm._s(_vm.$t("whatsapp.code")) + "\n    "),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "input-block" },
          [
            _c("otp-input", {
              ref: "otpInput",
              attrs: {
                "input-classes": "otp-input",
                size: "24",
                separator: "",
                "should-auto-focus": true,
                "input-type": "number",
              },
              on: {
                "on-complete": _vm.isTokenComplete,
                "on-change": _vm.isTokenComplete,
              },
              model: {
                value: _vm.code,
                callback: function ($$v) {
                  _vm.code = $$v
                },
                expression: "code",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _vm.phoneVerification === false
          ? _c("p", { staticClass: "error-msg" }, [
              _vm._v("\n      Incorrect code\n    "),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("Button", {
          attrs: {
            title: _vm.$t("buttons.confirm"),
            bgColor: "#428D83",
            textColor: "#fff",
            disabled: !_vm.isCodeFull,
          },
          nativeOn: {
            click: function ($event) {
              return _vm.handleVerification.apply(null, arguments)
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }