<template>
  <div class='modal__content'>
    <div class='modal__content-text mb-m'>
      {{ $t('tokens.makeCall') }}
    </div>
    <Button
      title='Call'
      @click.native='handleSubmit'
    />
  </div>
</template>

<script>
import Button from '../Button/Button'
import {createNamespacedHelpers} from 'vuex'
import {Namespaces} from '@/utils/constants/store.js'

const {
  mapActions: profileActions,
  mapGetters: profileGetters
} = createNamespacedHelpers(Namespaces.Profile)

export default {
  name: 'ModalCall',
  components:{
    Button
  },
  computed: {
    ...profileGetters(['phonePin']),
  }, 
  methods:{
    ...profileActions(['getPIN']),
    handleSubmit() {
      this.$emit('submit')
    }
  }
}
</script>

<style lang='scss'>
@import './modalcall'
</style>
