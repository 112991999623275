<template>
  <div class="modal__content" @click="handleClose">
    <div class="modal__content-img mb-s"><HeartCircleOutline :size=96 fillColor="#5DD478"/></div>
    <div class="modal__content-text mb-m">
      {{ text }}
    </div>
    <button class="next-button" type="button" @click="handleSend"><chat class="icon-button" :size=24 />{{$t('like.openchat')}}</button>
    <button class="next-button" type="button" @click="handleClose"><cards class="icon-button" :size=24 />{{$t('like.close')}}</button>
  </div>
</template>

<script>
import Button from "@/components/Button/Button"
import Chat from "vue-material-design-icons/ForumOutline.vue";
import Cards from "vue-material-design-icons/Cards.vue";
import HeartCircleOutline from "vue-material-design-icons/HeartCircleOutline.vue";

export default {
  components: {
    Button,
    Chat,
    Cards,
    HeartCircleOutline
  },
  props:{
    text: {
      type: String,
      default: 'Your like has been sent successfully!'
    }
  },
  data () {
    return {
    }
  },
  methods: {
    handleSend() {
      this.$emit("startChat")
    },
    handleClose(info) {
      this.$emit("close")
    }
  }
};
</script>

<style lang="scss" scoped>
@import "./Like";
</style>
