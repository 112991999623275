var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "slider-container" }, [
    _c(
      "div",
      { staticClass: "slider" },
      [
        _c(
          "client-only",
          [
            _c(
              "swiper",
              {
                attrs: { options: _vm.swiperOptions },
                on: {
                  slideChange: _vm.onSlideChange,
                  ready: _vm.onSwiperInit,
                  sliderMove: _vm.onSliderMove,
                },
              },
              _vm._l(_vm.modelsListFiltered, function (model) {
                return _c(
                  "swiper-slide",
                  { key: model.id, attrs: { "data-id": model.id } },
                  [
                    _c("Slide", {
                      key: model.id,
                      attrs: {
                        id: model.id,
                        image:
                          model.files.length > 0 ? model.files[0].path : "",
                        name: model.name,
                        age: model.age,
                        distance: model.distance,
                        status: model.online,
                        description: model.description,
                      },
                    }),
                  ],
                  1
                )
              }),
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }