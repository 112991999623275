var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "input-wrapper" }, [
    _vm.type === "checkbox"
      ? _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.input,
              expression: "input",
            },
          ],
          attrs: { type: "checkbox" },
          domProps: {
            checked: Array.isArray(_vm.input)
              ? _vm._i(_vm.input, null) > -1
              : _vm.input,
          },
          on: {
            change: function ($event) {
              var $$a = _vm.input,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.input = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.input = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.input = $$c
              }
            },
          },
        })
      : _vm.type === "radio"
      ? _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.input,
              expression: "input",
            },
          ],
          attrs: { type: "radio" },
          domProps: { checked: _vm._q(_vm.input, null) },
          on: {
            change: function ($event) {
              _vm.input = null
            },
          },
        })
      : _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.input,
              expression: "input",
            },
          ],
          attrs: { type: _vm.type },
          domProps: { value: _vm.input },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.input = $event.target.value
            },
          },
        }),
    _vm._v(" "),
    _c("span", { on: { click: _vm.handleSave } }, [
      _vm._v(_vm._s(_vm.$t("profile.btn"))),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }