<template>
    <div class="content">
      <p class="info confirm">{{ $t('whatsapp.verified') }}</p>

      <Button
        :title="$t('buttons.finish')"
        bgColor="#428D83"
        textColor="#fff"
        @click.native="closeWindow"
      />
    </div>
</template>

<script>
import Button from "@/components/Button/Button";

export default {
  components: {
    Button
  },

  methods: {
    closeWindow() {
      this.$emit("close", false);
    }
  }
};
</script>

<style lang="scss">
@import "./whatsAppConfirm.scss";
.content .confirm {
  padding-bottom: 40px;
}
</style>
