<template>
  <div class="modal">
    <div class="back" @click="handleClose"><span class="material-icons">arrow_back_ios</span></div>
    <div class="modal-content">
      <h2>{{ title }}</h2>
      <div class="select">
        <SelectCity
          class="space"
          @select="getValue"
          @searchInput="search"
          :options="cities"
          :initialCity="currentProperty"
        />
      </div>
      <Button :title="$t('profile.btn')" @click.native="handleChange" />
    </div>
  </div>
</template>

<script>
import Button from "../Button/Button";
import SelectCity from "../SelectCity/SelectCity.vue";
import throttle from "lodash.throttle";
import {createNamespacedHelpers} from "vuex";
import {Namespaces} from "@/utils/constants/store.js";

const {
  mapGetters: registrationGetters,
  mapActions: registrationActions
} = createNamespacedHelpers(Namespaces.Registration);

export default {
  components: {
    Button,SelectCity
  },

  props: {
    closeHandler: {
      type: Function,
      default: () => {}
    },
    title: {
      type: String,
      default: ""
    },
    currentProperty:{
      type: String,
      default: ""
    }
  },
  data() {
    return {
      value: null
    };
  },
  computed: {
    ...registrationGetters(['cities']),
  },
  mounted () {
    // console.log('Component mounted')
    this.$router.push({
      query : {
        edit : true
      }
    })    
  },
  beforeDestroy () {
    // console.log('Component beforeDestroy')
    this.$router.push({
      query : {}
    })    
  },
  methods: {
    ...registrationActions(['getCities']),

    search: throttle(function(value) {
      const city = {
        input: value ? value : "a",
        lang: 'en'
      }
      this.getCities(city)
    }, 500),

    getValue(value) {
      this.value = value
    },

    handleChange() {
      this.$emit("changed", this.value);
    },
    handleClose () {
      this.$emit("cancel");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "./modalSelect.scss";
.back {
  position: absolute;
  z-index: 2;
  top: 1.5rem;
  left: 10px;
  color: black;
}
</style>
