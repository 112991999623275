<template>
  <div class="modal__content">
    <div class="modal__content-text mb-m">
      {{ text }}
    </div>
    <Button
      title="ok"
      @click.native="handleSubmit"
    />
  </div>
</template>

<script>
import Button from "../Button/Button";
export default {
  name: "ModalConfirm",
  components:{
    Button
  },
  props:{
    text: {
      type: String,
      default: ""
    }
  },
  methods:{
    handleSubmit() {
     this.$emit('submit')
    }
  }
}
</script>

<style scoped lang="scss">
@import "./modalconfirm";
</style>
