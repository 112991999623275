<template>
  <div class="safe-top safe-left safe-right safe-bottom" @touchstart="onTouchStart">
    <TopBar :tokens="configData.free_tokens" />
    <Nuxt :class="{ blurred: showTokenWindow }" />

    <ChatModalWindow
      v-if="isChatOpened"
      :new-username="chatName"
      :unique-name="uniqueName"
      :on-close="closeChat"
      :model-id="model_id"
    />

    <TokensFlow
      v-if="showTokenWindow"
      :has-token="tokensLeft"
      @close="closeTokenWindow"
    />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import Toast from '../components/Toast/Toast';
import ToastMessage from '../components/Toast/ToastMessage';
// import getImageToast from '../utils/imageToast.js';
import TopBar from '@/components/Header/Header';
import { Namespaces } from '@/utils/constants/store.js';
// import ToastButton from "../components/Toast/ToastButton"
import ChatModalWindow from '@/components/ChatModalWindow/ChatModalWindow';
import TokensFlow from '@/components/TokensFlow/TokensFlow';
import { TOAST_SETTINGS } from '@/utils/constants/index.js';

// const {
//   mapActions: chatAction,
//   mapGetters: chatGetter
// } = createNamespacedHelpers(Namespaces.Chat);
const {
  mapActions: configActions,
  mapGetters: configGetters
} = createNamespacedHelpers(Namespaces.Config);
const {
  mapActions: modelsActions,
  mapGetters: modelsGetters
} = createNamespacedHelpers(Namespaces.Models);
const {
  mapActions: profileActions,
  mapGetters: profileGetters
} = createNamespacedHelpers(Namespaces.Profile);

const moment = require('moment');

export default {
  components: {
    TopBar,
    // Toast,
    // ToastButton,
    ChatModalWindow,
    TokensFlow
    // ToastMessage,
  },
  data: () => ({
    uniqueName: '',
    isChatOpened: false,
    chatName: '',
    model_id: null,
    showTokenWindow: 0,
    NewMessageEvent: false,
    channel: null,
    msgText: '',
    // windowHeight: window.innerHeight,
    model_icon: '',
    timers: [Math.floor(Math.random() * 5 + 20), Math.floor(Math.random() * 35 + 65), Math.floor(Math.random() * 40 + 70)],
    visitedGirls: [],
    msgNums: []
    // msgInterval: Math.floor(Math.random()*(30-15))+15, // from 15 to 30
  }),
  async fetch () {
    // await this.getUser()
    // console.log('asyncData ', this.userId)
  },
  computed: {
    ...configGetters(['configData']),
    ...modelsGetters(['modelsList', 'currentModel']),
    ...profileGetters(['userId', 'tokensLeft', 'error', 'profileData'])
  },
  watch: {
    $route (to, from) {
      if (from.name === 'girls-id') {
        // this.visitedGirls = this.$storage.state.visitedGirls || [];
        const visitTimes = [+this.configData.time_message || 15, +this.configData.time_message * 2 || 30, +this.configData.time_message * 4 || 60];
        this.$store.commit('setVisited', from.params.id);
        if (this.$store.state.visited.length === 1) {
          // console.log('route change to 1', this.$store.state.visited.length)
          setTimeout(() => {
            this.startToastVisited(from.params.id);
          }, visitTimes[0] * 1000);
        // } else if (this.$store.state.visited.length === 3) {
        //   // console.log('route change to 3', this.$store.state.visited.length)
        //   setTimeout(() => {
        //     this.startToastVisited(to.params.id);
        //   }, visitTimes[2] * 1000);
        }

        // if (this.modelsList.filter(m => m.id === Number(to.params.id) && m.online === 1).length > 0 &&
        //     this.visitedGirls.length < 3 &&
        //     !this.visitedGirls.includes(Number(to.params.id))
        // ) {
        //   this.visitedGirls.push(Number(to.params.id));
        //   this.$storage.setState('visitedGirls', this.visitedGirls);
        //   console.log('timer start', this.visitedGirls.length)
        //   if (this.visitedGirls.length === 1) {
        //     setTimeout(() => {
        //       this.showToast(this.visitedGirls[0], this.msgNums[0]);
        //     }, this.timers[0] * 1000);
        //   } else if (this.visitedGirls.length === 2) {
        //     console.log('timer start', this.visitedGirls.length, (this.timers[0]+this.timers[1]))
        //     setTimeout(() => {
        //       this.showToast(this.visitedGirls[1], this.msgNums[1]);
        //     }, (this.timers[0] + this.timers[1]) * 1000);
        //   } else if (this.visitedGirls.length === 3) {
        //     console.log('timer start', this.visitedGirls.length, (this.timers[1]+this.timers[2]))
        //     setTimeout(() => {
        //       this.showToast(this.visitedGirls[2], this.msgNums[2]);
        //     }, (this.timers[1] + this.timers[2]) * 1000);
        //   }
        // }
      }
      // if ( this.timer === null &&
      //      this.$storage.state.chattedWith.indexOf(to.paramss.id) !== -1 &&
      //      this.$storage.state.visitedGirls && ['index', 'girls-id', 'chats'].includes(to.name) ||
      //      (to.name === 'index' && !to.query.chatid)
      //     ) {
      //   console.log('timer start', this.$storage.state.visitedGirls)
      //   this.timer = setInterval(() => {
      //     this.timerCheck()
      //   }, 1000)
      // }
      // console.log('route change to', to)
      // console.log('route change from', from)
    }
  },
  async created () {
    await this.getUser();
    if (this.error.response && this.error.response.status === 401) {
      this.$router.push('/registration');
      // console.log('401')
    }
    // this.unsubscribe = this.$store.subscribe((mutation, state) => {
    //   if (mutation.type.indexOf('messages/') !== -1) {
    //     console.log(`Updating to ${mutation.type}`);
    //     if (typeof window !== "undefined") state.messages && sessionStorage.setItem("messages", JSON.stringify(state.messages))
    //   }
    // })
    // this.showTokenWindow = this.tokensLeft === 0
    // console.log('tokensLeft', this.tokensLeft)
  },
  async mounted () {
    if (!this.configData.time_profile_view) {
      await this.getConfig();
    }
    // this.timers = [
    //   +this.configData.time_profile_view || Math.floor(Math.random() * 10 + 10),
    //   +this.configData.time_profile_view + 15 || Math.floor(Math.random() * 5 + 25),
    //   +this.configData.time_profile_view + 20 || Math.floor(Math.random() * 10 + 30)
    // ];

    // this.$nextTick(() => {
    //   window.addEventListener('resize', this.onResize)
    // })
    // if (!this.userId) {
    //   this.$router.push('/registration')
    //   return
    // }
    // Get category and request models
    // if (!this.userId) return
    const category = this.$auth.$storage.getCookie('category') || 'ukrainian_girls';
    await this.getModelsList({ category });
    // this.startToastVisited()
    // const msg = {
    //   id: 32630,
    //   client_id: 20752,
    //   model_id: 30,
    //   session_id: '1000GEM9195261857774',
    //   text: 'again',
    //   from: 'model',
    //   moderator_id: 83,
    //   created_at: '2024-01-08T13:32:19.000000Z',
    //   updated_at: '2024-01-08T13:32:19.000000Z'
    // };
    // setTimeout(() => {
    //   this.$store.commit('messages/addUnread', { model_id: msg.model_id });
    //   this.$store.commit('messages/add', msg);
    //   this.startToastMessage(msg);
    // }, 5 * 1000);
    // return
    // this.startToastLike()
    // this.isChatOpened =
    // console.log(`chat opened: ${document.getElementById('chatpage')}`)
    this.$store.dispatch('messages/initializeStore');
    this.channel = `user.${this.userId}`;
    await this.$echo.private(this.channel)
      .subscribed(() => {
        // eslint-disable-next-line no-console
        console.log(`Subscribed to channel: ${this.channel}`);
        // console.log(this.$refs.account)
        // this.$storage.setState('subscribed', this.$echo.socketId())
      })
      .listen('UserEvent', (msg) => {
        // console.log('UserEvent ', msg)
        this.$store.commit('setSessionId', msg.chat.session_id);
        if (document.getElementById('chatpage')) {
          if (+this.$store.state.ChatModel === +msg.chat.model_id) {
            this.$store.commit('chat/addMessage', msg.chat);
          } else {
            this.$store.commit('messages/add', msg.chat);
          }
        } else {
          this.$store.commit('messages/addUnread', { model_id: msg.chat.model_id });
          this.$store.commit('messages/add', msg.chat);
          this.startToastMessage(msg.chat);
        }
      });
    // const visitTimes = [+this.configData.time_message || 15, +this.configData.time_message * 2 || 30, +this.configData.time_message * 4 || 60];
    // for (let i = this.$store.state.visited.length; i < 3; i++) {
    //   // console.log('visited ', i, visitTimes[i])
    //   setTimeout(() => {
    //     this.startToastVisited();
    //   }, visitTimes[i] * 1000);
    // }
    // console.log('UserEvent ', this.$echo.socketId())
    // window.onbeforeunload = async (e) => {
    //     if(!e) e = window.event;
    //     //e.cancelBubble is supported by IE - this will kill the bubbling process.
    //     e.cancelBubble = true;
    //     e.returnValue = 'You sure you want to leave?'; //This is displayed on the dialog

    //     //e.stopPropagation works in Firefox.
    //     if (e.stopPropagation) {
    //         e.stopPropagation();
    //         e.preventDefault();
    //     }
    // }

    // document.onvisibilitychange = async () => {
    //   if (document.visibilityState === 'hidden' && window.location.hostname !== 'localhost') {
    //     // console.log('hidden', `${this.$config.apiHost}/api/sanctum/bye-bye/${this.userId }`)
    //     if (process.client) {
    //       const token = localStorage.getItem('USER_ACCESS_TOKEN') || ''
    //       if (token) {
    //         fetch(`${this.$config.apiHost}/api/sanctum/bye-bye/${this.userId }`, {
    //           keepalive: true,
    //           method: 'POST',
    //           headers: new Headers({
    //             'Authorization': 'Bearer ' + token
    //           })
    //         })
    //         // .then(response => response.json())
    //         // .then(json => console.log('Response', json))
    //       }
    //     }
    //   }
    // }
  },
  beforeDestroy () {
    if (this.channel) {
      this.$echo.leave(this.channel);
      // console.log(`Leave channel: ${this.channel}`)
    }
    // this.unsubscribe()
    // this.$echo.disconnect()
    // window.removeEventListener('resize', this.onResize)
  },
  destroyed () {
  },
  methods: {
    ...configActions(['getConfig']),
    ...modelsActions(['getModelsList']),
    ...profileActions(['getUser']),
    onTouchStart (e) {
      if (this.visitedGirls.length > 0) {
        return;
      }
      // console.log("touchstart.");
      const onlineModels = this.modelsList.filter(m => m.online === 1);
      if (!onlineModels.length) {
        return;
      }
      while (this.visitedGirls.length < 3) {
        const rnd = Math.floor(Math.random() * onlineModels.length);
        if (!this.visitedGirls.includes(rnd) && onlineModels[rnd]?.id) {
          this.visitedGirls.push(onlineModels[rnd].id);
        }
      }
      while (this.msgNums.length < 3) {
        const r = Math.floor(Math.random() * 3);
        if (!this.msgNums.includes(r)) {
          this.msgNums.push(r);
        }
      }
      for (let i = 0; i < 1; i++) {
        const prev = (i === 0 ? 0 : this.timers[i - 1] * 1000);
        // console.log('hey', i, prev, this.timers[i] * 1000, prev + this.timers[i] * 1000);
        setTimeout(() => {
          this.showToast(this.visitedGirls[i], this.msgNums[i]);
        }, prev + this.timers[i] * 1000);
      }
    },
    timerCheck () {
      this.currSeconds++;
      // console.log('this.currSeconds: ', this.msgInterval, this.currSeconds)
      if (this.currSeconds === this.msgInterval) {
        // console.log('timer stop', this.$route.name, ['index', 'girls-id', 'chats'].indexOf(this.$route.name) !== -1)
        clearInterval(this.timer);
        this.currSeconds = 0;
      }
    },
    onResize () {
      // console.log('resize ', this.windowHeight, window.innerHeight)
      // this.windowHeight = window.innerHeight
    },
    startToastVisited (modelId) {
      // const nameToast = this.modelsList.map(item => item.name)
      const onlineModels = this.modelsList.filter(m => m.online === 1);
      let rnd;
      do {
        rnd = Math.floor(Math.random() * onlineModels.length - 1 + 1);
      }
      while (this.$store.state.visited.includes(rnd));
      // const rnd = Math.floor(Math.random() * onlineModels.length - 1 + 1);
      const model = modelId ? this.modelsList.filter(m => m.id === Number(modelId))[0] : onlineModels[rnd];
      if (!model) {
        // console.log('model', model, modelId, onlineModels, rnd);
        return;
      }
      // if (!model.length) return
      // const nameToast = this.modelsList.filter(m => m.id == model.model_id)
      if (['tokens'].includes(this.$route.name)) {
        return;
      }
      // const imageToast = getImageToast(this.modelsList)
      const toastContent = {
        component: Toast,
        props: {
          name: model.name,
          text: this.$t('toast.text_visited'),
          img: model.chat_icon,
          handleClick: () => {
            this.$router.push(`/girls/${model.id}`);
            // this.$router.push('tokens');
          }
        }
      };
      this.$toast(toastContent, TOAST_SETTINGS);
      this.$store.commit('setVisited', model.id);
      // console.log('model >', modelId, this.$store.state.visited[0]);
      // if (modelId && this.$store.state.visited[0] === modelId) {
      //   setTimeout(() => {
      //     this.startToastCamInvite(model.id);
      //   }, Math.floor(Math.random() * 20 + 10) * 1000);
      // }
      // let interval = this.configData.time_profile_view * 1000
      // setInterval(() => this.$toast(toastContent, TOAST_SETTINGS), interval)
    },
    startToastLike () {
      // const nameToast = this.modelsList.map(item => item.name);
      // const imageToast = getImageToast(this.modelsList);
      // const toastContent = {
      //   component: Toast,
      //   props: {
      //     name: nameToast[1],
      //     text: this.$t('toast.text_like'),
      //     img: imageToast[1]
      //   }
      // };
      // let interval = this.configData.time_like * 1000
      // setInterval(() => this.$toast(toastContent, TOAST_SETTINGS), interval)
    },
    startToastCamInvite (modelId) {
      const model = this.modelsList.filter(m => m.id === +modelId)[0];
      if (!model) { return; };
      const toastContent = {
        component: Toast,
        props: {
          name: model.name,
          text: this.$t('toast.text_caminvite'),
          img: model.chat_icon,
          handleClick: () => {
            this.$router.push('tokens');
          }
        }
      };
      const obj = {
        id: moment().valueOf(),
        client_id: this.userId,
        // text: this.$t(`toast.messages[${msgNum}]`, { name: this.profileData.email.substring(0, this.profileData.email.lastIndexOf("@")) }),
        text: this.$t('toast.text_caminvite') + ' Click <a href="/tokens">to Accept</a>',
        model_id: model.id,
        session_id: (Math.random().toString(16) + '000000000').substr(2, 8),
        from: 'model',
        bot: 1,
        moderator_id: null,
        online: model.online,
        model_name: model.name,
        chat_icon: model.chat_icon?.substring(model.chat_icon.lastIndexOf('_') + 1),
        created_at: moment().utc().format(),
        updated_at: moment().utc().format()
      };
      this.$store.commit('messages/addUnread', { model_id: model.id });
      this.$store.commit('messages/add', obj);
      this.$store.commit('setChattedWith', model.id);
      this.$toast(toastContent, TOAST_SETTINGS);
    },
    showToast (modelId, msgNum) {
      const model = this.modelsList.filter(m => m.id === +modelId)[0];
      // console.log('showToast', modelId, model)
      if (!model) { return; };
      if (this.$route.query.chatid) {
        // console.log('showToast', modelId)
        return;
      }
      const obj = {
        id: moment().valueOf(),
        client_id: this.userId,
        // text: this.$t(`toast.messages[${msgNum}]`, { name: this.profileData.email.substring(0, this.profileData.email.lastIndexOf("@")) }),
        text: this.$t(`toast.messages[${msgNum}]`, { name: '' }),
        model_id: model.id,
        session_id: (Math.random().toString(16) + '000000000').substr(2, 8),
        from: 'model',
        bot: 1,
        moderator_id: null,
        online: model.online,
        model_name: model.name,
        chat_icon: model.chat_icon?.substring(model.chat_icon.lastIndexOf('_') + 1),
        created_at: moment().utc().format(),
        updated_at: moment().utc().format()
      };
      this.$store.commit('messages/addUnread', { model_id: model.id });
      this.$store.commit('messages/add', obj);
      this.$store.commit('setChattedWith', model.id);
      if (!['tokens', 'chats'].includes(this.$route.name)) {
        this.startToastMessage(obj);
      }
    },
    startToastMessage (message) {
      // const nameToast = this.modelsList.map(item => item.name)
      // const imageToast = getImageToast(this.modelsList)
      // console.log('filter:', message, this.modelsList.filter(model => model.id === message.model_id))
      const model = this.modelsList.filter(model => model.id === +message.model_id);
      // console.log('model:', model);
      if (!model.length || ['tokens'].includes(this.$route.name)) { return; }
      const openChat = () => {
        // if (this.tokensLeft === 0) {
        //   this.$router.push('tokens');
        //   return;
        // }
        if (this.$store.state.isChatOpened) {
          return;
        }
        this.chatName = model[0]?.name;
        this.uniqueName = `${model[0]?.name}+${this.userId}`;
        this.isChatOpened = true;
        this.model_id = model[0]?.id;
      };
      // const openOffer = () => {
      //   this.$router.push('tokens');
      // };
      const toastContent = {
        component: ToastMessage,
        props: {
          name: model[0]?.name,
          text: message.text.substring(0, 40) + '...',
          img: model[0]?.chat_icon,
          buttonTitle: this.$t('toast.btn_reply'),
          handleOpen: openChat
        }
      };
      // this.$toast(toastContent, TOAST_SETTINGS)
      this.$toast(toastContent, {
        position: 'bottom-center',
        timeout: false,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: false,
        icon: false,
        rtl: false
      });
      // let interval = this.configData.time_message * 1000;
      // setInterval(() => {
      //   this.$toast(toastContent, {
      //     position: "bottom-center",
      //     timeout: 10000,
      //     closeOnClick: true,
      //     pauseOnFocusLoss: true,
      //     pauseOnHover: true,
      //     draggable: true,
      //     draggablePercent: 0.6,
      //     showCloseButtonOnHover: false,
      //     hideProgressBar: true,
      //     closeButton: false,
      //     icon: false,
      //     rtl: false
      //   });
      // }, 2000);
    },
    closeChat () {
      this.isChatOpened = false;
    },
    closeTokenWindow () {
      this.showTokenWindow = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.blurred {
  filter: blur(5px);
  background-color: whitesmoke;
  pointer-events: none;
}
.safe-top {
  padding-top: env(safe-area-inset-top);
}
.safe-left {
  padding-left: env(safe-area-inset-left);
}
.safe-right {
  padding-right: env(safe-area-inset-right);
}
.safe-bottom {
  padding-bottom: env(safe-area-inset-bottom);
}
.mx-4 {
  margin-left: 16px;
  margin-right: 16px;
}
</style>

<style lang="scss">
// @import "~/style/index.scss";
</style>
