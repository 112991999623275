var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("vue-tel-input", {
    ref: "phoneInput",
    attrs: {
      autoDefaultCountry: false,
      dropdownOptions: {
        showDialCodeInSelection: true,
        showFlags: true,
        showSearchBox: true,
      },
      inputOptions: {
        required: true,
        type: "tel",
        styleClasses: [
          _vm.isValid === false ? "invalidColor" : null,
          "tel-input",
        ],
      },
      styleClasses: "tel-input",
      validCharactersOnly: true,
      defaultCountry: _vm.defaultCountry,
      preferredCountries: _vm.preferredCountries,
    },
    on: { input: _vm.handleInput },
    model: {
      value: _vm.phone,
      callback: function ($$v) {
        _vm.phone = $$v
      },
      expression: "phone",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }