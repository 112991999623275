var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "offer-wrapper" },
    [
      _c(
        "div",
        { staticClass: "offer-wrapper-top" },
        [
          _c("Icon", {
            attrs: { color: _vm.item.color, iconType: _vm.item.iconType },
          }),
          _vm._v(" "),
          _c("p", [
            _vm._v("\n      " + _vm._s(_vm.$t("tokens.buy")) + "\n      "),
            _c("span", { staticClass: "highlight" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.item.count) +
                  " " +
                  _vm._s(_vm.$t("tokens.tokens")) +
                  "\n      "
              ),
            ]),
            _vm._v(
              "\n      " +
                _vm._s(_vm.$t("tokens.only")) +
                "\n      " +
                _vm._s(_vm.$t("tokens.for")) +
                "\n      "
            ),
            _c("span", { staticClass: "highlight" }, [
              _vm._v("$" + _vm._s(_vm.item.price)),
            ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("Button", {
        attrs: { title: _vm.$t("tokens.btn") },
        nativeOn: {
          click: function ($event) {
            return _vm.handleClick.apply(null, arguments)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }