<template>
  <nuxt-link :to="to" class="modal__link">{{text}}</nuxt-link>
</template>

<script>
export default {
  name: "Link",
  props:{
    text:{
      type:String,
      default:"Go back home"
    },
    to:{
      type:String,
      default: '/'
    }
  }
}
</script>

<style scoped lang="scss">
@import "./link";
</style>
