<template>
  <div>
    <PhoneVerifyNumber
      v-if="STEPS_ORDER[stepNumber] === STEP_NAME.PHONE_NUMBER"
      @step="handleStep"
      @enterNumber="setNumber"
    />

    <PhoneVerifyCode
      v-if="STEPS_ORDER[stepNumber] === STEP_NAME.VERIFICATION_CODE"
      @step="handleStep"
    />

    <PhoneVerifyConfirm
      v-if="STEPS_ORDER[stepNumber] === STEP_NAME.CONFIRMATION"
      @close="closeComponent"
    />
  </div>
</template>

<script>
import {createNamespacedHelpers} from "vuex"
import {Namespaces} from "@/utils/constants/store.js"
import PhoneVerifyNumber from "@/components/PhoneVerifyNumber/PhoneVerifyNumber"
import PhoneVerifyCode from "@/components/PhoneVerifyCode/PhoneVerifyCode"
import PhoneVerifyConfirm from "@/components/PhoneVerifyConfirm/PhoneVerifyConfirm"

import {
  WHATSAPP_STEPS,
  WHATSAPP_STEPS_ORDER
} from "@/utils/constants/index.js"

const {
  mapActions: profileActions,
  mapGetters: profileGetters
} = createNamespacedHelpers(Namespaces.Profile)

export default {
  components: {
    PhoneVerifyNumber,
    PhoneVerifyCode,
    PhoneVerifyConfirm
  },
  data() {
    return {
      stepNumber: 0 
    }
  },
  created () {
    this.STEPS_ORDER = WHATSAPP_STEPS_ORDER
    this.STEP_NAME = WHATSAPP_STEPS
  },
  methods: {
    ...profileActions(["setPhoneNumber"]),

    handleStep (step) {
      this.stepNumber = step
    },
    closeComponent (value) {
      this.$emit("close", value)
    },
    setNumber (number) {
      this.setPhoneNumber(number)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./PhoneVerifyFlow";
</style>
