<template>
  <Modal :buttonTitle="$t('modal.email.button')" @close="handleClose">
    <template v-if="!emailSent">
        <div class="info" v-html='$t(text, {"email": email})'></div>
        <Button
          :title="$t(btnTitle)"
          @click.native="handleVerification"
        />
    </template>
    <template v-else>
        <div class="info">{{ $t('profile.verification') }}</div>
        <Button
          title="Ok"
          @click.native="handleClick"
        />
    </template>
  </Modal>
</template>

<script>
import Button from "@/components/Button/Button"
import Modal from "@/components/Modal/Modal"

export default {
  name: "ModalEmailVerify",
  components: {
    Modal,
    Button,
  },
  data() {
    return {
      emailSent: 0,
    }
  },
  props: {
    text: {
      type: String,
      default: "modal.email.text"
    },
    btnTitle: {
      type: String,
      default: "modal.email.button"
    },
    email: {
      type: String,
      default: ""
    },
  },
  methods: {
    async handleVerification() {
      await this.$UserAPI.verifyEmail(this.$route.fullPath)
      this.emailSent = true
      this.$store.commit('setEmailSent', true)
    },
    handleClose () {
      this.$emit('close-modal')
      // this.$router.push({ name: 'tokens' })
    },
    handleClick () {
      this.$emit('click-modal')
    }
  }
};
</script>

<style scoped lang="scss">
</style>
