export default httpClient => ({
  getChatsList () {
    return httpClient.get(`sanctum/get-channel-list`)
  },
  getTelenesChatMessages () {
    return httpClient.post(`sanctum/telenes/get-chats`)
  },
  getTelenesLastMessages (category) {
    return httpClient.get(`sanctum/telenes/last-messages/${category}`)
  },
  getTelenesChatModelMessages (model_id) {
    return httpClient.get(`sanctum/telenes/chat-model/${model_id}`)
  },
  sendChatInfo (params) {
    return httpClient.post(`sanctum/set-chat`, params)
  }
})