var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "slider-buttons" }, [
    _c("div", { staticClass: "option-wrap" }, [
      _c(
        "button",
        {
          staticClass: "option-button blueColor",
          on: { click: _vm.messageHandler },
        },
        [_c("ChatIcon", { attrs: { size: 48, "fill-color": "white" } })],
        1
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "option-button2",
          attrs: { disabled: !_vm.isOnline },
          on: { click: _vm.handleClick },
        },
        [
          _c("img", {
            attrs: {
              src: require("@/assets/img/live_stream.png"),
              "aria-valuetext": "cam",
            },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "button",
        { staticClass: "option-button", on: { click: _vm.handleClick } },
        [_c("Phone", { attrs: { size: 48, "fill-color": "#3CCF65" } })],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }