<template>
  <Message :fillColor="color" :size="size" :title="title"/>
</template>

<script>
import Message from "vue-material-design-icons/Message.vue";

export default {
  components: {
    Message
  },
  props: {
    title: {
      type: String,
      default: "Chat"
    },
    color: {
      type: String,
      default: "#A2B2D1"
    },
    size: {
      type: Number,
      default: 28
    }
  }
};
</script>
