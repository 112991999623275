<template>
  <nuxt-link to="/" class="back-button">
    <ArrowLeft :size="16" />
    <span>{{ $t("profile.back") }}</span>
  </nuxt-link>
</template>

<script>
import ArrowLeft from "@/assets/Icons/ArrowLeft/ArrowLeft";

export default {
  components: {
    ArrowLeft
  }
};
</script>

<style lang="scss" scoped>
@import "./backButton.scss";
</style>
