<template>
  <div class="modal">
    <div class="modal-content">
      <h2>{{ title }}</h2>
      <div class="cropper__holder">
        <cropper
          class="custom-restrictions-cropper"
          check-orientation
          :src="image"
          ref="cropper"
          :stencil-component="$options.components.CircleStencil"
        />
      </div>
      <div class="button" @click="$refs.file.click()">
        <input ref="file" type="file" accept="image/*" @change="uploadImage($event)" />
        Upload image
      </div>
      <Button :title="$t('profile.btn')" @click.native="handleChange"/>
    </div>
  </div>
</template>

<script>
import {Cropper,CircleStencil} from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css'

export default {
  name: "ModalCrop",
  components: {
    Cropper,CircleStencil
  },
  data() {
    return {
      image:require("@/assets/images/upload.png"),
      result:null
    };
  },
  props: {
    title: {
      type: String,
      default: ""
    }
  },
  methods: {
    uploadImage(event) {
      let input = event.target;
      if (input.files && input.files[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.image = e.target.result;
        };
        reader.readAsDataURL(input.files[0]);

      }
    },
    handleChange() {
       const { canvas } = this.$refs.cropper.getResult();
       this.image = canvas.toDataURL();
       this.$emit("changed",this.image);
    }
  }
}
</script>

<style lang="scss">
@import "./modalcrop";
</style>
