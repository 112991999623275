<template>
    <section class="CongratuationsPopup">
        <div class="PoPupContent">
          <div class="PoPupCnt">
            <h1>CONGRATULATIONS!</h1>
            <h3>You've successfully created your profile on Flirtypoint</h3>
            <p class="instruction">Open the email from us and click on the
              <strong>Activate my account</strong>.</p>
            <div class="ConfirmEmail">
              <p class="email_info">Your e-mail: {{userEmail}}</p>
              <p><a href="https://mail.google.com/mail/" class="free-cta" target="_blank">Go to your e-mail</a></p>
              <!-- <a href="javascript:void(0)" class="Resend resend_links" id="resendActivationMail" style="display:inline-block" target="_blank">Resend activation e-mail</a> -->
            </div>
            <div class="ImportantInfo">
              <h4>Important:</h4>
              <ul>
                <li>It can take up to 5 minutes for the activation e-mail to arrive</li>
                <li>Check your e-mail accounts 'Spam/Junk' folder to make sure our e-mail hasn't landed there
                </li>
                <li>Mark us as 'Not junk/Spam' to ensure that you receive all important e-mails from RabbitsMeet
                </li>
              </ul>
            </div>
            <div class="changeEmail">
              <label class="black-text" style="color: #0b0b0b;font-weight: bold;line-height: 20px">Want to change your email address?</label>
              <a href="#" class="changeAccountEmail black-text" style="color: #0b0b0b;font-weight: bold">Click here</a>
            </div>
            <div class="changeForm" style="display: none">
              <form name="change_email">
                <div class="form-group">
                  <input type="text" class="form-control" id="email" name="email" placeholder="Enter new email address..">
                  <label class="error" style="color: #db0b0b;"></label>
                </div>
                <div>
                  <button class="btn btn-dark update_email">Send</button>
                </div>
              </form>
            </div>
          </div>
        </div>
    </section>
</template>

<script>
import Button from "@/components/Button/Button"
import Link from "@/components/Link/Link"

export default {
  components: {
    Button,
  },
  props:{
    userEmail: {      
      type: String,
      default: ""
    }
  },
  data () {
    return {
    }
  },
  methods: {
    handleSend () {
      this.$emit('submit')
    },
    handleClose () {
      this.$emit("close")
    }
  }
}
</script>

<style lang="scss" scoped>
h1 {
  font-family: "Open Sans";
  font-style: normal;
}
.CongratuationsPopup{height:100%;position:fixed;top:0;width:100%;z-index:100;padding:0;
  font-family: "Open Sans";
  font-style: normal;
}
.CongratuationsPopup:before{background:rgba(0,0,0,.7);content:"";height:100%;position:fixed;top:0;left:0;width:100%;z-index:1}
.PoPupContent{background:#fff;left:2%;margin:0 auto;max-width:1140px;position:fixed;right:2%;top:50%;transform:translateX(0) translateY(-50%);z-index:2}
.PoPupContent{max-width:100%;padding:0}
.PoPupContent{max-width:500px}
.PoPupCnt{font-size:16px;min-height:510px;padding:30px 22px}
.PoPupCnt ul{padding-left:20px}
.PoPupCnt ul li{font-size:13px;line-height:16px;padding-bottom:8px}
.PoPupCnt h4{font-size:15px;font-weight:600}
.PoPupCnt p{font-size:20px}
.ConfirmEmail{padding:0 0 20px}
.ConfirmEmail p{color:green;font-weight:600}
.ConfirmEmail a.Resend{color:#000;display:block;font-size:17px;font-weight:600;margin:5px 0 3px;padding:0;text-decoration:underline;transition-duration:.35s}
.ConfirmEmail a.Resend:hover{color:#f75656}
.PoPupCnt .free-cta{background-color:#f75656;border-radius:5px;font-size:22px;padding:10px 0;text-transform:none}
.ImportantInfo h4{font-size:20px;text-transform:uppercase;padding-top:10px}
.ImportantInfo p{font-size:14px;line-height:24px;margin:0}
.ImportantInfo p span{font-size:18px;font-weight:600;padding-top:10px}
.CongratuationsImg{text-align:right}
.CongratuationsImg img{height:658px;max-width:100%;-o-object-fit:cover;object-fit:cover}
.changeEmail {
  margin-top: 15px;
  margin-bottom: 15px;
  display: none;
}

      .PoPupCnt h3 {
        font-weight: 600;
        font-size: 23px;
      }

      .PoPupCnt .instruction {
        font-size: 16px;
        margin: 19px 0 20px 0;
      }

      .ConfirmEmail a.Resend {
        margin: 5px 0px 3px 0px;
      }


      @media  only screen and (max-width: 667px) {
        /*.PoPupCnt {*/
        /*    padding: 5px 20px !important;*/
        /*}*/
        .PoPupCnt h1 {
          font-size: 22px;
          line-height: 24px;
          margin-bottom: 10px;
          font-weight: 600;
        }

        .PoPupCnt h3 {
          font-size: 18px;
          line-height: 23px;
          font-weight: 600;
          margin-bottom: 20px;
        }

        .PoPupCnt .instruction {
          font-size: 13px;
          line-height: 13px;

        }

        .PoPupCnt p {
          margin-bottom: 5px;
          font-size: 18px;
        }

        .changeEmail label {
          margin-bottom: 0;
        }

        .ConfirmEmail .email_info {
          font-weight: 600;
          line-height: 22px;
          font-size: 19px;
          margin: 20px 0 20px 0;
        }


        .ConfirmEmail a.Resend {
          margin: 18px 0px;
        }

      }

      @media  only screen and (max-width: 450px) {

        /*.PoPupCnt p{*/
        /*    margin-bottom: 5px;*/
        /*    font-size: 15px;*/
        /*}*/
        /*.PoPupCnt .free-cta {*/
        /*    background-color:

      ;*/
        /*    padding: 3px 0;*/
        /*    text-transform: none;*/
        /*    font-size: 15px;*/
        /*}*/
        /*.ConfirmEmail a.Resend {*/
        /*    display: block;*/
        /*    color: #000;*/
        /*    font-size: 14px;*/
        /*    padding: 5px 0 0;*/
        /*    font-weight: 600;*/
        /*    transition-duration: .35s;*/
        /*    text-decoration: underline;*/
        /*}*/
        .ConfirmEmail {
          padding: 0px 0 8px;
        }

        .ImportantInfo h4 {
          font-size: 17px;
        }

        .changeEmail {
          line-height: 10px;
          font-size: 13px;
        }

        .changeEmail .form-control {
          margin-bottom: 5px;
        }

        .changeForm {
          padding-bottom: 10px;
        }

      }

      @media  only screen and (max-width: 400px) {

        .changeEmail a {
          display: block;
        }

      }

      @media  only screen and (max-width: 360px) {

        .ConfirmEmail .email_info {
          line-height: 18px;
          font-size: 16px;
        }

      }
.modal-open{overflow:hidden}.modal-open .modal{overflow-x:hidden;overflow-y:auto}.modal{display:none;height:100%;left:0;outline:0;overflow:hidden;position:fixed;top:0;width:100%;z-index:1050}
.free-cta{background-color:#7cc72c;border:0;display:inline-block;font-weight:600;max-width:400px;padding:10px 40px;text-align:center;text-transform:uppercase;width:100%}.free-cta,.free-cta:hover{color:#fff;transition-duration:.35s}.free-cta:hover{background-color:#649e25;text-decoration:none;transform:scale(1.1)}
.free-cta{padding:10px 20px}
</style>
