var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "backdrop",
      on: {
        click: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.handleClose.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "Modal",
        { on: { close: _vm.handleClose } },
        [
          !_vm.confirmed
            ? [
                _c(
                  "div",
                  {},
                  [
                    _c("Alert", {
                      attrs: { size: 96, "fill-color": "#5DD478" },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", {
                  staticClass: "info",
                  domProps: {
                    innerHTML: _vm._s(_vm.$t("modal.cancelMembership.text")),
                  },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "button-group" },
                  [
                    _c("Btn", {
                      attrs: { title: _vm.$t("buttons.cancel") },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.handleClose.apply(null, arguments)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("Btn", {
                      staticClass: "btnCancel",
                      attrs: { title: _vm.$t("buttons.confirm") },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.handleConfirm.apply(null, arguments)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]
            : [
                _c("div", { staticClass: "info" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("modal.cancelMembership.canceled")) +
                      "\n      "
                  ),
                ]),
                _vm._v(" "),
                _c("Btn", {
                  attrs: { title: "Ok" },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.handleClose.apply(null, arguments)
                    },
                  },
                }),
              ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }