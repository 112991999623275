import types from "./types";

export default {
  [types.GET_CONFIG_SUCCESS](state, data) {
    state.configData = data;
    state.loading = false;
  },

  [types.GET_CONFIG_FAIL](state, error) {
    state.loading = false;
    state.error = error;
  },

  [types.GET_GEODATA_SUCCESS](state, geo) {
    //console.log('GEO', geo)
    state.geoData = { ...state.geoData, ...geo }
    if (geo.city === undefined) {
      //console.log('GEO no city')
      state.geoData.city = { geoname_id: 2911328, names: {en: 'Hamberg'} }
    }
    // console.log('GEO', state.geoData)
    state.loading = false;
  },

  [types.GET_GEODATA_FAIL](state, error) {
    state.loading = false;
    state.error = error;
  }
};
