var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "wa" },
    [
      _c("img", {
        staticClass: "icon",
        attrs: {
          src: require("@/assets/images/whatsapp-logo-big.png"),
          alt: "123",
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "close", on: { click: _vm.handleClose } }),
      _vm._v(" "),
      _vm.STEPS_ORDER[_vm.stepNumber] === _vm.STEP_NAME.PHONE_NUMBER
        ? _c("WhatsAppNumber", {
            on: { step: _vm.handleStep, enterNumber: _vm.checkNumber },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.STEPS_ORDER[_vm.stepNumber] === _vm.STEP_NAME.VERIFICATION_CODE
        ? _c("WhatsAppCode", { on: { step: _vm.handleStep } })
        : _vm._e(),
      _vm._v(" "),
      _vm.STEPS_ORDER[_vm.stepNumber] === _vm.STEP_NAME.CONFIRMATION
        ? _c("WhatsAppConfirm", { on: { close: _vm.closeComponent } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }