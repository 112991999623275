var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-welcome-page" }, [
    _c("h1", { staticClass: "title space" }, [
      _vm._v(_vm._s(_vm.$t(_vm.welcomeTitle))),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "about-me" },
      [
        _c("span", [_vm._v(_vm._s(_vm.$t("registration.genderPage.me")))]),
        _vm._v(" "),
        _c("GenderToggle", {
          staticClass: "space",
          attrs: { defaultGender: _vm.userInfo.gender },
          on: { clicked: _vm.handleUserToggle },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "looking-for" },
      [
        _c("span", [_vm._v(_vm._s(_vm.$t("registration.genderPage.partner")))]),
        _vm._v(" "),
        _c("GenderToggle", {
          staticClass: "space",
          attrs: { defaultGender: _vm.userInfo.looking_gender, lookfor: true },
          on: { clicked: _vm.handlePartnerToggle },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }