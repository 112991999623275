const axios = require('axios')

export default async function verifyEmail ({email, $config: {abstractApiKey} }) {
  console.log('key', abstractApiKey)
  if (!abstractApiKey) return false
  const res = await axios.get('https://emailvalidation.abstractapi.com/v1/', 
  { 
    params: { 'email': email, 'api_key': abstractApiKey },
  })
  console.log(JSON.stringify(res.data))
  return res.data.deliverability === 'DELIVERABLE'
}
