<template>
  <MessageText :fillColor="color" :size="size" />
</template>

<script>
import MessageText from "vue-material-design-icons/MessageText.vue";

export default {
  components: {
    MessageText
  },
  props: {
    color: {
      type: String,
      default: "#A2B2D1"
    },
    size: {
      type: Number,
      default: 28
    }
  }
};
</script>
