export { default as Account } from "./Account/Account";
export { default as ArrowLeft } from "./ArrowLeft/ArrowLeft";
export { default as Alert } from "./Alert/Alert";
export { default as Bell } from "./Bell/Bell";
export { default as Check } from "./Check/Check";
export { default as Close } from "./Close/Close";
export { default as Female } from "./Female/Female";
export { default as Heart } from "./Heart/Heart";
export { default as Male } from "./Male/Male";
export { default as Message } from "./Message/Message";
export { default as MessageText } from "./Message/MessageText";
export { default as WhatsApp } from "./WhatsApp/WhatsApp";
export { default as SnapChat } from "./SnapChat/SnapChat";