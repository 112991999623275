<template>
  <ChatUI
    :initialFeed="initialFeed"
    :new-message="newMessage"
    @newOwnMessage="newOwnMessage"
    @closed="handleClose"
    :isLoading="isLoading"
  />
</template>

<script>
import ChatUI from "@/components/ChatUI/ChatUI"
import Button from "../Button/Button";
import {createNamespacedHelpers} from "vuex";
import {Namespaces} from "@/utils/constants/store.js";

const moment = require("moment");
const axios = require("axios");
const chat = require("twilio-chat");
const _ = require("lodash");
const twilio = require("twilio-common");

const {
  mapActions: chatAction,
} = createNamespacedHelpers(Namespaces.Chat);

const {
  mapGetters: SelectedModelGetters,
  mapActions: SelectedModelActions,
} = createNamespacedHelpers(Namespaces.SelectedModel);

export default {
  name: "TwilioChat",

  props: {
    newUsername: {
      type: String,
      default: 'Melo'
    },

    uniqueChatName: {
      type: String
    }
  },

  components: {
    Button,
    ChatUI,
  },

  data: function() {
    return {
      tc: {
        accessManager: null,
        messagingClient: null,
        channel: [],
        generalChannel: null,
        username: "",
        channelArray: [],
        currentChannel: null,
        activeChannelIndex: null,
        messagesArray: []
      },
      username: "",
      connected: false,
      selected: false,
      showMessages: false,
      moment: moment,
      message: null,
      userNotJoined: true,
      newChannel: "",
      notification: false,
      notificationMsg: "",

      newMessage: {},
      isLoading: false,
    };
  },

  mounted() {
    console.log("Component mounted.");
    this.connectClientWithUsername(this.newUsername)
  },

  computed: {
    ...SelectedModelGetters(['selectedModel']),

    initialFeed() {
      const newFeed = [];

      for (const msg of this.tc.messagesArray) {
        let obj = {
          id: msg.state.author === 'system' ? 1 : 0,
          contents: msg.state.body,
          date: moment(msg.state.timestamp).fromNow()
        };

        newFeed.push(obj)
      }

      return newFeed;
    }
  },

  methods: {
    ...chatAction(['setChat', 'getChats']),
    ...SelectedModelActions(["getModelDetails"]),

    handleClose() {
      this.$emit('closed')
    },

    newOwnMessage(text) {
      console.log('TEXT', text)

      let newText = {
        id: 0,
        author: 'Me',
        contents: text,
        date: '16:30'
      }
      this.tc.currentChannel.sendMessage(text)
    },

    connectClientWithUsername(username) {
      this.tc.username = username;
      this.fetchAccessToken(this.tc.username, this.connectMessagingClient);
    },

    async fetchAccessToken(username, handler) {
      const params = {
        identity: this.tc.username,
        device: "browser"
      }

      try {
        const token = await this.$api.post('sanctum/token', params)
        console.log('---- T O K E N -----', token)
        handler(token)
        this.username = ''
      } catch (error) {
        console.error(error)
      }
    },

    async connectMessagingClient(token) {
      // Initialize the ChatUI messaging client
      this.tc.accessManager = new twilio.AccessManager(token);

      const client = await new chat.Client.create(token)

      this.tc.messagingClient = client;
      this.updateConnectedUI();
      this.tc.messagingClient.on("tokenExpired", this.refreshToken);

      this.createAndSetupChannel()

    },

    updateConnectedUI() {
      this.connected = true;
    },

    refreshToken() {
      this.fetchAccessToken(this.tc.username, this.setNewToken);
    },

    setNewToken(tokenResponse) {
      this.tc.accessManager.updateToken(tokenResponse.token);
    },

    loadChannelList(handler) {
      if (this.tc.messagingClient === undefined) {
        console.log("Client is not initialized");
        return;
      }
      this.getVisibleChannels(this.tc.messagingClient, handler);
    },

    async getVisibleChannels(messagingClient, handler) {
      const channels = await messagingClient.getUserChannelDescriptors()

      this.tc.channelArray = this.sortChannelsByName(channels.items)
      this.tc.channelArray.forEach(this.addChannel)

      if (typeof handler === "function") {
        handler();
      }
    },

    sortChannelsByName(channels) {
      return channels.sort(function(a, b) {
        if (a.friendlyName === "general") {
          return -1;
        }
        if (b.friendlyName === "General Channel") {
          return 1;
        }
        return a.friendlyName.localeCompare(b.friendlyName);
      });
    },

    addChannel(channel) {
      if (channel.uniqueName === "general") {
        this.tc.generalChannel = channel;
      }
    },

    async setupChannel(channel) {
      console.log("setup");

      this.isLoading = true

      const params = {
        channel_id: channel.sid,
        language: "en"
      }

      try {
        await this.leaveCurrentChannel()

        await this.initChannel(channel)

        await this.joinChannel(channel)

        await this.$api.post('sanctum/add-chat-webhook', params)

        this.initChannelEvents()

      } catch (error) {
        console.error(error)
      }
    },

    async leaveCurrentChannel() {
      if (this.tc.currentChannel) {
        const leftChannel = await this.tc.currentChannel.leave()
        console.log("left " + leftChannel.friendlyName);

        leftChannel.removeListener("messageAdded", this.addMessageToList);
        leftChannel.removeListener("memberLeft", this.notifyMemberLeft);
      } else {
        console.log("resolving");
        return Promise.resolve();
      }
    },

    initChannel(channel) {
      console.log("Initialized channel " + channel.friendlyName);
      return this.tc.messagingClient.getChannelBySid(channel.sid);
    },

    initChannelEvents() {
      console.log(this.tc.currentChannel);
      if (this.tc.currentChannel) {
        console.log(this.tc.currentChannel.friendlyName + " ready.");
        this.tc.currentChannel.on("messageAdded", this.addMessageToList);
        this.tc.currentChannel.on("memberLeft", this.notifyMemberLeft);
        this.isLoading = false
      }
    },

    notifyMemberLeft(member) {
      console.log("leaving");
      console.log(member);
      console.log(member.identity + " left the channel");
      // notify(member.identity + ' left the channel');
    },

    async joinChannel(_channel) {
      console.log(_channel);
      console.log('MY LOG -----------', _channel.sid, this.selectedModel);

      const chatParams = {
        model_id: this.selectedModel.id,
        sid: _channel.sid
      }

      this.setChat(chatParams)

      try {
        const leftChannel = await _channel.leave()
        const joinedChannel = await leftChannel.join()

        console.log("Joined channel " + joinedChannel.friendlyName);

        this.tc.currentChannel = joinedChannel;
        this.loadMessages();

        return joinedChannel;

      } catch (error) {
        alert(
          "Couldn't join channel " + _channel.friendlyName + " because " + error
        );
      }
    },

    async loadMessages() {
      const messages = await this.tc.currentChannel.getMessages(50)

      this.showMessages = true;
      this.tc.messagesArray = messages.items;
      this.userNotJoined = false;
    },

    addMessageToList(message) {
      let obj = {
        id: message.state.author === 'system' ? 1 : 0,
        contents: message.state.body,
        date: moment(message.state.timestamp).fromNow()
      };

      this.newMessage = obj;
    },

    async createAndSetupChannel() {
      let vm = this;
      let existingChannel = false;

      //TODO refactor this method

      // try {
      //   const channel = await this.tc.messagingClient
      //     .createChannel({
      //       friendlyName: this.tc.username,
      //       uniqueName: this.tc.username,
      //       isPrivate: true
      //     })
      //
      //   const channelResult = await this.setupChannel(channel)
      //
      //   if (!existingChannel) {
      //     channelResult.add("ChatBot");
      //   }
      //
      //   this.loadChannelList(channelResult);
      //
      //
      // } catch (error) {
      //   console.error(error)
      //   existingChannel = true;
      //   return this.tc.messagingClient.getChannelByUniqueName(this.tc.username);
      // }

      return this.tc.messagingClient
        .createChannel({
          friendlyName: this.tc.username,
          // uniqueName: this.tc.username,
          uniqueName: this.uniqueChatName,
          isPrivate: true
        })
        .catch(function(error) {
          existingChannel = true;
          return vm.tc.messagingClient.getChannelByUniqueName(vm.uniqueChatName);
        })
        .then(function(channel) {
          vm.setupChannel(channel);

          return channel;
        })
        .then(function(channel) {
          if (!existingChannel) {
            channel.add("ChatBot");
          }
          vm.loadChannelList(channel);
        })
    },
  }
};
</script>

<style scoped>
html,
body {
  background-color: #fff;
  color: #636b6f;
  font-family: "Nunito", sans-serif;
  font-weight: 200;
  height: 100vh;
  margin: 0;
}

.full-height {
  height: 100vh;
}

.flex-center {
  align-items: center;
  display: flex;
  justify-content: center;
}

.position-ref {
  position: relative;
}

.top-right {
  position: absolute;
  right: 10px;
  top: 18px;
}

.content {
  text-align: center;
}

.title {
  font-size: 84px;
}

.links > a {
  color: #636b6f;
  padding: 0 25px;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.1rem;
  text-decoration: none;
  text-transform: uppercase;
}

.m-b-md {
  margin-bottom: 30px;
}
</style>
