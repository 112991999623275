<template>
  <div class="form-details-page">
    <h1 class="title space">{{ $t(cityTitle) }}</h1>
    <!-- <SelectCity
      class="space"
      @select="handleCitySelect"
      @searchInput="search"
      :options="cities"
      :initialCity="userInfo.city"
    /> -->
      <TextField
        type="text"
        class="space"
        ref="city"
        @change="handleInputChange"
        :value="userInfo.city"
      />

    <h1 class="title space">{{ $t(ageTitle) }}</h1>
    <RangeSelector class="space" @chosenRange="handleAgeRange" />
  </div>
</template>

<script>
import RangeSelector from "../RangeSelector/RangeSelector"
import SelectCity from "../SelectCity/SelectCity.vue"
import {AGE_SLIDER_DEFAULTS} from "@/utils/constants/index.js"
import {createNamespacedHelpers} from "vuex"
import {Namespaces} from "@/utils/constants/store.js"
import TextField from "../TextField/TextField"
import throttle from 'lodash.throttle'

const {
  mapGetters: registrationGetters,
  mapActions: registrationActions
} = createNamespacedHelpers(Namespaces.Registration)

const {
  mapGetters: configGetters
} = createNamespacedHelpers(Namespaces.Config)

export default {
  components: {
    RangeSelector,
    TextField,
    SelectCity
  },
  data () {
    return {
      userInfo: {
        city: this.geoDataCity,
        min_age: AGE_SLIDER_DEFAULTS.RANGE[0],
        max_age: AGE_SLIDER_DEFAULTS.RANGE[1]
      }
    }
  },
  created () {
    this.watchGeodataChange()
  },
  props: {
    cityTitle: {
      type: String,
      default: "registration.cityPage.city"
    },
    ageTitle: {
      type: String,
      default: "registration.cityPage.ageGroup"
    }
  },
  watch: {
    userInfo: {
      handler () {
        this.$emit("formValue", this.userInfo)
      },
      deep: true
    },
    geoDataCity () {
      this.watchGeodataChange()
    }
  },
  computed: {
    ...registrationGetters(['cities']),
    ...configGetters(["geoDataCity"]),
  },
  methods: {
    ...registrationActions(['getCities']),
    handleCitySelect (value) {
      this.userInfo.city = value
    },
    handleInputChange (value) {
      // console.log('city', value)
      this.userInfo.city = (value || "").trim()
    },
    search: throttle(function(value) {
      const city = {
        input: value ? value : "a",
        lang: 'en'
      }
      this.getCities(city)
    }, 500),
    handleAgeRange (value) {
      this.userInfo.min_age = value[0]
      this.userInfo.max_age = value[1]
    },
    watchGeodataChange () {
      this.userInfo.city = this.geoDataCity
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./theFormCity.scss";
</style>
