import types from "./types"

export default {
  async getConfig({ commit }) {
    try {
      const category = this.$auth.$storage.getCookie('category')

      const { data } = await this.$ConfigAPI.getConfigData(category)
      commit(types.GET_CONFIG_SUCCESS, data)
    } catch (error) {
      commit(types.GET_CONFIG_FAIL, error)
    }
  },
  async getGeodata({commit}) {
    try {
      const geo = await this.$UserAPI.getGeodata()

      commit(types.GET_GEODATA_SUCCESS, geo)
    } catch (error) {
      commit(types.GET_GEODATA_FAIL, error)
    }
  },
}
