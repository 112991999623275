<template>
  <GenderFemale :fillColor="color" :size="size" />
</template>

<script>
import GenderFemale from "vue-material-design-icons/GenderFemale.vue";

export default {
  components: {
    GenderFemale
  },
  props: {
    color: {
      type: String,
      default: "#FF37A3"
    },
    size: {
      type: Number,
      default: 24
    }
  }
};
</script>
