var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "transition-group",
    {
      attrs: { name: "messages-list", tag: "div" },
      on: { enter: _vm.onEnter },
    },
    _vm._l(_vm.feed, function (message, index) {
      return _c(
        "div",
        { key: `${index}-msg`, staticClass: "messages-list-item" },
        [
          _vm.showDateHeader(message.created_at, index)
            ? _c("div", { staticClass: "date-header" }, [
                _vm._v(_vm._s(_vm.getDate(message.created_at))),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("messages", {
            attrs: {
              fromModel: message.from === "model",
              date: message.created_at,
              "image-url": message.imageUrl,
              contents: message.text,
              sent: message.sent,
            },
          }),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }