<template>
  <div class="form-username-page">
    <h1 class="title space">{{ $t('registration.emailPage.username') }}</h1>

    <div class="email-field">
      <TextField
        :errorMsg="errorMsg"
        type="text"
        class="space"
        ref="TextField"
        @change="handleInputChange"
        @input="handleInputChange"
        :value="userInfo.username"
        :isLoading="isLoading"
        :isValid="$v.userInfo.username.isValid"
        :dirty="$v.userInfo.username.$dirty"
        :placeholder="$t('registration.emailPage.usernametext')"
      />
    </div>

    <div class="highlight">
      <label class="orange">
        <input type="checkbox" @change="handleAccept" checked/>
        <span class="checkmark"></span>
        <span class="checklabel">{{ $t('registration.emailPage.checkbox') }}</span>
      </label>
    </div>
    <!-- <Button
      :title="$t('buttons.profiles')"
      @click.native="handleSubmit"
      :disabled="!userInfo.termsAccepted || !this.$v.userInfo.username.isValid || clicked"
    /> -->
    <button class="button-wrapper" 
            type="button" 
            :style="{ background: bgColor, color: '#000000' }"            
            @click="handleSubmit" 
            :disabled="!userInfo.termsAccepted || !this.$v.userInfo.username.isValid || clicked"
    >
      <Loader class="icon-button" v-if="isClicked"/>{{$t('buttons.profiles')}}
    </button>

    <span class="conditions">
      <i18n path="terms.term" tag="label">
        <button class="tos" @click="IsShowTerms=true">{{ $t('terms.tos') }}</button>
      </i18n>
    </span>
    <ContentModalTerms @submit="IsShowTerms=false" v-if="IsShowTerms" :text="$t('terms.htmlText')"/>
    </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex"
import { Namespaces } from "@/utils/constants/store.js"
import TextField from "../TextField/TextField"
import ContentModalTerms from "../ContentModalTerms/ContentModalTerms"
import { validationMixin } from "vuelidate"
import { COLORS } from "@/utils/constants/index.js"

const { 
  mapActions: registrationActions
} = createNamespacedHelpers( Namespaces.Registration )

export default {
  mixins: [validationMixin],
  components: {
    TextField,
    ContentModalTerms
  },
  data () {
    return {
      isLoading: false,
      isClicked: false,
      bgColor: COLORS.ORANGE_GRADIENT,
      IsShowTerms: false,
      errorMsg: null,
      clicked: false,
      userInfo: {
        username: '',
        termsAccepted: true
      }
    }
  },
  validations: {
    userInfo: {
      username: {
        async isValid (value) {
          const isUsernameValid = value.match(/^([a-zA-Z0-9_-]){4,20}$/)
          if (!isUsernameValid) {
            this.errorMsg = this.$i18n.t('profile.username')
            return false
          }
          this.errorMsg = this.$i18n.t('registration.emailPage.errormsg')
          this.isLoading = true
          const UsernameVerified = await this.verifyUsername(this.userInfo.username)
          this.isLoading = false
          return UsernameVerified

          return true
        }
      }
    }
  },
  mounted () {
    // console.log('ref', this.$refs.TextField.$el.children[0])
    this.userInfo.username = 'guest-' + Math.random().toString(16).substr(2, 6)
    // this.$nextTick(this.$refs.TextField.$el.children[0].focus())
  },
  methods: {
    ...registrationActions(["checkUsername"]),
    async verifyUsername (name) {
      const res = await this.checkUsername( name )
      return res==='OK'
    },
    handleAccept (value) {
      this.userInfo.termsAccepted = value
      this.$emit("termsAccepted", this.userInfo.termsAccepted)
    },
    handleInputChange (value) {
      this.userInfo.username = (value || "").trim()
      // if (this.userInfo.username.length > 3)
        this.$v.userInfo.username.$touch()
    },
    async handleSubmit () {
      this.isClicked = true
      this.$v.userInfo.$touch()
      this.errors = this.$v.userInfo.$anyError
      this.isValid = this.$v.userInfo.username.isValid
      if (this.errors && this.isValid) return
      this.$emit("formValue", this.userInfo)
      this.$emit("login")
      this.clicked = true
      setTimeout(()=> {
        this.clicked = false
        this.isClicked = false
      }, 5000)
    },
    showTerms() {
      this.IsShowTerms = true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./TheFormUsername.scss";
.highlight {
  margin: 20px 0;
}
.orange {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  // background-color: rgba($color: orange, $alpha: 0.1);
}

/* Hide the browser's default checkbox */
.orange input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  border-radius: 5px;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background: transparent;
  border: 1px solid orange;
}
/* When the checkbox is checked, add a blue background */
.orange input:checked ~ .checkmark {
  background: linear-gradient(90deg, #ff6565 -2.93%, #ffdc23 105.33%);
}
/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
/* Show the checkmark when checked */
.orange input:checked ~ .checkmark:after {
  display: block;
}
/* Style the checkmark/indicator */
.orange .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid black;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.orange .checklabel {
  font-size: 18px;
  // margin-top: 20px;
  // padding: 20px 2px 20px 2px;
  color: white;
}
.tos {
  color: #FF8C00;
  background: transparent;
}
.button-wrapper {
  width: 100%;
  padding: 13px 0;
  border-radius: 20px;
  border: none;
  font-size: 17px;
  outline: none;
  cursor: pointer;
  font-family: "DMSans-Bold";
  &:disabled{
    background: gray!important;
  }
  position: relative;
  // display: inline-flex;
  display: block;
  align-items: center;
  // justify-content: center;
  // height: 2.5rem;
}
.icon-button {
  position:absolute;
  // display: block;
  // margin-left: auto;
  width: 40px;
  // height: 40px;
  // float: right;
  // padding: 0.35em;
  top: 50%;
  left: 85%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
</style>
