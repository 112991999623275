<template>
  <Star :fillColor="color" :size="size" />
</template>

<script>
import Star from "vue-material-design-icons/StarFourPoints.vue";

export default {
  components: {
    Star
  },
  props: {
    color: {
      type: String,
      default: "#A2B2D1"
    },
    size: {
      type: Number,
      default: 28
    }
  }
};
</script>
