var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "like-wrapper" }, [
    _c("div", { staticClass: "content" }, [
      _c(
        "button",
        {
          staticClass: "next-button",
          attrs: { type: "button" },
          on: { click: _vm.handleSend },
        },
        [
          _c("chat", { staticClass: "icon-button", attrs: { size: 24 } }),
          _vm._v("Send a Message"),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "next-button",
          attrs: { type: "button" },
          on: { click: _vm.handleKeep },
        },
        [
          _c("cards", { staticClass: "icon-button", attrs: { size: 24 } }),
          _vm._v("Keep Playing"),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }