<template>
    <div class="upload-wrapper">
      <img v-if="!src.length" :src="uploadIcon" alt=""/>
      <img :src="src" alt=""/>
      <div class="info">
        <a href="#"  @click="clickHandler">{{ $t("profile.upload") }}</a>
        <span>{{ $t("profile.image") }}</span>
      </div>
    </div>
</template>

<script>


export default {
  name: "photo-upload",
  data() {
    return {
      uploadIcon: require("@/assets/images/upload.png")
    };
  },
  props:{
    src: {
      type: String,
      default: ""
    },
  },
  methods: {
    clickHandler() {
      this.$emit("clicked");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "./photoUpload.scss";
</style>
