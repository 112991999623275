<template>
  <div class="modal">
    <div class="back" @click="handleClose"><span class="material-icons">arrow_back_ios</span></div>
    <div class="modal-content">
      <h2>{{ title }}</h2>

      <div class="select">
        <Select
          :options="options"
          class="profile_select"
          borderColor="1px solid grey"
          :currentProperty="currentProperty"
          @select="getValue"
        />
      </div>
      <Button :title="$t('profile.btn')" @click.native="handleChange" />
    </div>
  </div>
</template>

<script>
import Select from "@/components/Select/Select";
import Button from "../Button/Button";
export default {
  components: {
    Select,Button
  },

  props: {
    closeHandler: {
      type: Function,
      default: () => {}
    },

    title: {
      type: String,
      default: ""
    },

    options: {
      type: Array,
      default: () => []
    },
    currentProperty:{
      type: String,
      default: ""
    }
  },

  data() {
    return {
      value: null
    };
  },
  mounted () {
    // console.log('Component mounted')
    this.$router.push({
      query : {
        edit : true
      }
    })    
  },
  beforeDestroy () {
    // console.log('Component beforeDestroy')
    this.$router.push({
      query : {}
    })    
  },
  methods: {
    getValue(value) {
      if (value instanceof Object) {
        this.value = value
      } else {
        this.value = value.toLowerCase()
      }
    },
    handleChange() {
      this.$emit("changed", this.value)
    },
    handleClose () {
      this.$emit("cancel");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "./modalSelect.scss";
.back {
  position: absolute;
  z-index: 2;
  top: 1.5rem;
  left: 10px;
  color: black;
}
</style>
