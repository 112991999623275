<template>
  <div class="block-wrapper" :class="{ highlight: unread }">
    <nuxt-link to="/girls/1" class="block-image">
      <img :src="getAvatar(photo)" alt="avatar" />
      <div v-if="status" class="status"></div>
    </nuxt-link>

    <div class="block-info">
      <div class="block-info-left">
        <div>
          <span>{{ name }}</span>
          <span class="time">{{ $t("notifications.at") }} {{ timestamp }}</span>
        </div>
        <span class="message">{{ action }}</span>
      </div>

      <div class="block-info-right">
        <div class="unread" v-if="unread">
          <span>{{ $t("notifications.open") }}</span>
          <ChevronRight :size="16" color="#FF2525" class="icon" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChevronRight from "@/assets/Icons/ChevronRight/ChevronRight";

export default {
  components: {
    ChevronRight
  },

  props: {
    avatar: {
      type: String,
      default: ""
    },

    name: {
      type: String,
      default: "Melanie"
    },

    status: {
      type: Boolean,
      default: true
    },

    timestamp: {
      type: String,
      default: "17:25"
    },

    unread: {
      type: Number,
      default: 0
    },

    action: {
      type: String,
      default: "has sent you a message"
    },

    photo: {
      type: String,
      default: "toast_girl.png"
    }
  },

  methods: {
    getAvatar(src) {
      let avatar = require("@/assets/images/" + src);

      return avatar;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "./notificationBlock.scss";
</style>
