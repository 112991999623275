var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "modal__content" },
    [
      _c("div", { staticClass: "modal__content-text mb-m" }, [
        _vm._v(
          "\n    " +
            _vm._s(_vm.$t("chat.hello", { model: _vm.modelName })) +
            " "
        ),
        _c(
          "span",
          {
            staticStyle: {
              "font-size": "48px",
              position: "relative",
              top: "10px",
            },
          },
          [_vm._v("👋")]
        ),
      ]),
      _vm._v(" "),
      _vm._l(4, function (index) {
        return _c(
          "div",
          { key: index, staticClass: "gradient__wrap" },
          [
            _c("Button", {
              staticClass: "hey",
              attrs: {
                title: _vm.$t("chat.text" + index),
                "bg-color": _vm.button_color,
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.handleSubmit.apply(null, arguments)
                },
              },
            }),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }