var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ChatUI", {
    attrs: {
      "initial-feed": _vm.feed,
      "new-message": _vm.newMessage,
      "is-loading": _vm.isLoading,
      title: _vm.newUsername,
      "model-icon": _vm.img,
      "model-id": _vm.modelId,
      "is-online": _vm.selectedModel.online,
      wa: _vm.showWa,
    },
    on: { newOwnMessage: _vm.newOwnMessage, closed: _vm.handleClose },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }