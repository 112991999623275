<template>
  <div class="icon" :style="{ 'border-color': color }">
    <Star v-if="iconType === 'Star'" :color="color" />
    <Diamond v-if="iconType === 'Diamond'" :color="color" />
  </div>
</template>

<script>
import Star from "@/assets/Icons/Star/Star";
import Diamond from "@/assets/Icons/Diamond/Diamond";

export default {
  components: {
    Star,
    Diamond
  },

  props: {
    color: {
      type: String,
      default: ""
    },

    iconType: {
      type: String,
      default: "Star"
    }
  }
};
</script>

<style lang="scss" scoped>
@import "./tokenOfferIcon.scss";
</style>
