<template>
  <div class="safe-top safe-left safe-right safe-bottom">
    <!-- <noscript v-html="iFrameCode" /> -->
    <Nuxt />
  </div>
</template>

<script>
export default {
  data () {
    return {
      // iFrameCode: '<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TB9DC7G3" height="0" width="0" style="display: none; visibility: hidden" />',
    };
  }
};
</script>

<style lang="scss">
.safe-top {
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
}
.safe-left {
  padding-left: constant(safe-area-inset-left);
  padding-left: env(safe-area-inset-left);
}
.safe-right {
  padding-right: constant(safe-area-inset-right);
  padding-right: env(safe-area-inset-right);
}
.safe-bottom {
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}
.mx-4 {
  margin-left: 16px;
  margin-right: 16px;
}
</style>
