<template>
  <div class="input__container">
    <img
      v-show="file"
      :src="imagePreview"
      class="image--uploaded">
    <slot name="input-field">
      <input-field
        ref="msgInput"
        v-model="message"
        @input="onInput"
        @newOwnMessage="onNewOwnMessage"/>
    </slot>
    <slot name="input-button">
      <input-button @newOwnMessage="onNewOwnMessage" :title="title"/>
    </slot>
  </div>
</template>

<script>
import InputField from './InputField.vue'
import InputButton from './InputButton.vue'

export default {
  name: 'InputContainer',
  components: {
    InputField,
    InputButton,
  },
  props: {
    title: {
      type: String,
      default: 'Send',
      required: false
    }
  },
  data () {
    return {
      message: '',
      file: undefined,
      imagePreview: ''
    }
  },
  mounted () {
    // this.$nextTick(this.$refs.msgInput.$el.children[0].focus())
  },
  methods: {
    onNewOwnMessage () {
      if ((!this.message || this.message === '') && !this.file) {
        return
      }

      this.$emit('newOwnMessage', this.message, this.file, this.imagePreview)

      this.message = ''

      this.file = undefined

      this.imagePreview = ''
    },
    onInput (value) {
      this.$emit('input', value)
    }, 
    handleFileUpload () {
      this.file = this.$refs.image.files[0]
      let reader = new FileReader()

      reader.addEventListener(
        'load',
        function () {
          this.imagePreview = reader.result
        }.bind(this),
        false
      )

      if (this.file) {
        if (/\.(jpe?g|png|gif)$/i.test(this.file.name)) {
          reader.readAsDataURL(this.file)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.visible {
  display: none;
}
</style>
