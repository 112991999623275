<template>
  <div
    class="message--own">
    <div class="message__contents">
      <img
        v-if="imageUrl"
        :src="imageUrl"
        alt=""
        style="width: 100%">
      <div class="chat-message">{{ contents }}</div>
      <div class="metadata"><time class="time">{{ date }}</time><Tick class="tick" :size="16" v-if="sent"/></div>
    </div>
  </div>
</template>

<script>
import Tick from 'vue-material-design-icons/CheckAll.vue'

export default {
  name: 'MessageOwn',
  components: {
    Tick
  },
  props: {
    date: {
      type: String,
      default: '16:30:00',
      required: false
    },
    imageUrl: {
      type: String,
      default: '',
      required: false
    },
    contents: {
      type: String,
      default: '',
      required: false
    },
    sent: {
      type: Boolean,
      default: true,
      required: false 
    }
  }
}
</script>

<style>
</style>
