var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("Transition", { attrs: { name: "fade" } }, [
    _c("div", [
      _c("div", { staticClass: "modal__overlay" }),
      _vm._v(" "),
      _vm.showing
        ? _c("div", { staticClass: "modal__wrap" }, [_vm._t("default")], 2)
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }