var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "menu-item",
      style: { background: _vm.backgroundColor },
      on: { click: _vm.clickHandler },
    },
    [
      _c("div", { staticClass: "menu-item-left" }, [
        _c("div", { staticClass: "field" }, [_vm._t("default")], 2),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "icon-block" },
          [
            _vm.isTokenField
              ? _c("Diamond", {
                  staticClass: "diamond",
                  attrs: { color: "#FF2525", size: 20 },
                })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "span",
              { staticClass: "add value", class: { property: _vm.property } },
              [
                _vm._v(
                  "\n         " +
                    _vm._s(_vm.property || _vm.$t("profile.add")) +
                    "\n      "
                ),
              ]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      !_vm.readonly
        ? _c("div", { staticClass: "menu-item-icon" }, [_c("ChevronRight")], 1)
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }