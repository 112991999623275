<template>
  <div class="phonenumber-wrapper">
    <div class="content">
      <p class="info">
        {{ $t("whatsapp.code") }}
      </p>

      <div class="input-block">
        <otp-input
          v-model="code"
          ref="otpInput"
          @on-complete="isTokenComplete"
          @on-change="isTokenComplete"
          input-classes="otp-input"
          size="24"
          separator=""
          :should-auto-focus="true"
          input-type="number"
        ></otp-input>
      </div>

      <p v-if="phoneVerification === false" class="error-msg">
        Incorrect code
      </p>

      <Button
        :title="$t('buttons.confirm')"
        bgColor="#428D83"
        textColor="#fff"
        @click.native="handleVerification"
        :disabled="!isCodeFull"
      />
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex"
import { Namespaces } from "@/utils/constants/store.js"
import Button from "@/components/Button/Button"
import OTPInput from "@bachdgvn/vue-otp-input"

const {
  mapActions: profileActions,
  mapGetters: profileGetters
} = createNamespacedHelpers(Namespaces.Profile)

export default {
  components: {
    Button,
    "otp-input": OTPInput
  },
  data() {
    return {
      code: "",
      isCodeFull: false
    }
  },
  computed: {
    ...profileGetters(["phoneVerification"])
  },
  methods: {
    ...profileActions(["checkPhoneCode"]),

    isTokenComplete (isValid) {
      this.isCodeFull = isValid
    },
    async handleVerification () {
      if (!this.isCodeFull) return

      await this.checkPhoneCode(this.code)

      if (!this.phoneVerification) return

      this.$emit("step", 2)
    }
  }
}
</script>

<style lang="scss">
@import "./PhoneVerifyCode";
.input-block {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;

  .otp-input {
    width: 40px;
    height: 40px;
    padding: 5px;
    margin: 0 10px;
    font-size: 20px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    text-align: center;
    &.error {
      border: 1px solid red !important;
    }
  }
  .otp-input::-webkit-inner-spin-button,
  .otp-input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
</style>
