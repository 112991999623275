<template>
  <div class="modal">
    <div class="modal-content-block">
      <!-- <ChatTwilioLogic
        class="custom-window"
        @closed="onClose"
        :newUsername="newUsername"
        :uniqueChatName="uniqueName"
      /> -->
      <ChatTelenesLogic
        class="custom-window"
        @closed="onClose"
        :newUsername="newUsername"
        :uniqueChatName="uniqueName"
        :modelId="modelId"
      />
    </div>
  </div>
</template>

<script>
// import ChatTwilioLogic from '@/components/ChatTwilioLogic/ChatTwilioLogic'
import ChatTelenesLogic from '@/components/ChatTelenesLogic/ChatTelenesLogic'

export default {
  name: "ChatWindow",
  components: {
    // ChatTwilioLogic
    ChatTelenesLogic
  },
  props: {
    onClose: {
      type: Function,
      default: () => {
      }
    },
    modelId: {
      type: Number
    },
    newUsername: {
      type: String,
      default: 'Melo'
    },
    uniqueName: {
      type: String
    }
  },
  watch: {
    newUsername() {
      console.log('CHANGE', this.newUsername)
    }
  },
  data () {
    return {}
  }
}
</script>

<style lang="scss">
@import "./chatWindow.scss";
</style>
