var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c("p", { staticClass: "info" }, [
        _vm._v("\n      " + _vm._s(_vm.$t("whatsapp.number")) + "\n    "),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "input-block" },
        [_c("TelephoneInput", { on: { getNumber: _vm.handleNumber } })],
        1
      ),
      _vm._v(" "),
      _c("Button", {
        attrs: {
          title: _vm.$t("buttons.send"),
          bgColor: "#428D83",
          textColor: "#fff",
          disabled: !_vm.isValidNumber,
        },
        nativeOn: {
          click: function ($event) {
            return _vm.handleSend.apply(null, arguments)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }