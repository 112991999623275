<template>
  <ChevronRight :fillColor="color" :size="size" />
</template>

<script>
import ChevronRight from "vue-material-design-icons/ChevronRight.vue";

export default {
  components: {
    ChevronRight
  },
  props: {
    color: {
      type: String,
      default: "#868686"
    },
    size: {
      type: Number,
      default: 20
    }
  }
};
</script>
