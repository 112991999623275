<template>
  <div class="like-wrapper">
    <div class="content">
      <button class="next-button" type="button" @click="handleSend"><chat class="icon-button" :size=24 />Send a Message</button>
      <button class="next-button" type="button" @click="handleKeep"><cards class="icon-button" :size=24 />Keep Playing</button>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Button/Button"
import Chat from "vue-material-design-icons/ForumOutline.vue";
import Cards from "vue-material-design-icons/Cards.vue";

export default {
  components: {
    Button,
    Chat,
    Cards
  },
  data () {
    return {
    }
  },
  methods: {
    handleSend() {
      this.$emit("startChat")
    },
    handleKeep(info) {
      this.$emit("close")
    }
  }
};
</script>

<style lang="scss" scoped>
@import "./SendMessage.scss";
</style>
