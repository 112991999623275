<template>
  <div>
    <TokenGiven v-if="hasToken" @close="handleClose"/>

    <TokenAbsent v-else @close="handleClose"/>
  </div>
</template>

<script>
import Button from "@/components/Button/Button";
import TokenGiven from "../TokenGiven/TokenGiven";
import TokenAbsent from "../TokenAbsent/TokenAbsent";

export default {
  components: {
    Button,
    TokenGiven,
    TokenAbsent
  },

  props: {
    hasToken: {
      type: Number
    }
  },

  data() {
    return {
      number: "",
      isValidNumber: null
    };
  },


  methods: {
    handleClose() {
      this.$emit("close");
    },

    handleNumber(info) {
      this.number = info.number;
      this.isValidNumber = info.valid;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "./tokensFlow.scss";
</style>
