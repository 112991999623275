<template>
  <div class="nophone-wrapper">
    <div class="close" @click="handleClose"></div>
    <div class="content">
      <!--p class="info">
        {{ text }}
      </p-->
      <div class="info" v-html="text"></div>

      <Button
        :title="buttonTitle"
        @click.native="handleSend"
      />
    </div>
  </div>
</template>

<script>
import Button from "@/components/Button/Button"
import Link from "@/components/Link/Link"

export default {
  components: {
    Button,
  },
  props:{
    title: {
      type: String,
      default: ""
    },
    buttonTitle: {
      type: String,
      default: "Ok"
    },
    text: {
      type: String,
      default: ""
    }
  },
  data () {
    return {
    }
  },
  methods: {
    handleSend () {
      this.$emit('submit')
    },
    handleClose () {
      this.$emit("close")
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./ContentModal";
.nophone-wrapper {
  .close {
    display: block;
    // position: relative;
    top: 0.2rem;
    right: 0.2rem;
    position: absolute;
    // right: 32px;
    // top: 32px;
    width: 32px;
    height: 32px;
    opacity: 0.3;
  }
  .close:hover {
    opacity: 1;
  }
  .close:before, .close:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 33px;
    width: 2px;
    background-color: #333;
  }
  .close:before {
    transform: rotate(45deg);
  }
  .close:after {
    transform: rotate(-45deg);
  }
}
</style>
