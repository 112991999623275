var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "form-details-page" },
    [
      _c("h1", { staticClass: "title space" }, [
        _vm._v(_vm._s(_vm.$t(_vm.cityTitle))),
      ]),
      _vm._v(" "),
      _c("TextField", {
        ref: "city",
        staticClass: "space",
        attrs: { type: "text", value: _vm.userInfo.city },
        on: { change: _vm.handleInputChange },
      }),
      _vm._v(" "),
      _c("h1", { staticClass: "title space" }, [
        _vm._v(_vm._s(_vm.$t(_vm.ageTitle))),
      ]),
      _vm._v(" "),
      _c("RangeSelector", {
        staticClass: "space",
        on: { chosenRange: _vm.handleAgeRange },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }