var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Modal",
    {
      attrs: { buttonTitle: _vm.$t("modal.email.button") },
      on: { close: _vm.handleClose },
    },
    [
      !_vm.emailSent
        ? [
            _c("div", {
              staticClass: "info",
              domProps: {
                innerHTML: _vm._s(_vm.$t(_vm.text, { email: _vm.email })),
              },
            }),
            _vm._v(" "),
            _c("Button", {
              attrs: { title: _vm.$t(_vm.btnTitle) },
              nativeOn: {
                click: function ($event) {
                  return _vm.handleVerification.apply(null, arguments)
                },
              },
            }),
          ]
        : [
            _c("div", { staticClass: "info" }, [
              _vm._v(_vm._s(_vm.$t("profile.verification"))),
            ]),
            _vm._v(" "),
            _c("Button", {
              attrs: { title: "Ok" },
              nativeOn: {
                click: function ($event) {
                  return _vm.handleClick.apply(null, arguments)
                },
              },
            }),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }