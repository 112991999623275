<template>
  <Close :fillColor="color" :size="size" :title="title"/>
</template>

<script>
import Close from "vue-material-design-icons/Close.vue";

export default {
  components: {
    Close
  },
  props: {
    color: {
      type: String,
      default: "#FF2525"
    },
    size: {
      type: Number,
      default: 32
    },
    title: {
      type: String,
      default: "Hide model"
    }
  }
};
</script>
