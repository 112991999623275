var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "CongratuationsPopup" }, [
    _c("div", { staticClass: "PoPupContent" }, [
      _c("div", { staticClass: "PoPupCnt" }, [
        _c("h1", [_vm._v("CONGRATULATIONS!")]),
        _vm._v(" "),
        _c("h3", [
          _vm._v("You've successfully created your profile on Flirtypoint"),
        ]),
        _vm._v(" "),
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "ConfirmEmail" }, [
          _c("p", { staticClass: "email_info" }, [
            _vm._v("Your e-mail: " + _vm._s(_vm.userEmail)),
          ]),
          _vm._v(" "),
          _vm._m(1),
        ]),
        _vm._v(" "),
        _vm._m(2),
        _vm._v(" "),
        _vm._m(3),
        _vm._v(" "),
        _vm._m(4),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "instruction" }, [
      _vm._v("Open the email from us and click on the\n          "),
      _c("strong", [_vm._v("Activate my account")]),
      _vm._v("."),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c(
        "a",
        {
          staticClass: "free-cta",
          attrs: { href: "https://mail.google.com/mail/", target: "_blank" },
        },
        [_vm._v("Go to your e-mail")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "ImportantInfo" }, [
      _c("h4", [_vm._v("Important:")]),
      _vm._v(" "),
      _c("ul", [
        _c("li", [
          _vm._v(
            "It can take up to 5 minutes for the activation e-mail to arrive"
          ),
        ]),
        _vm._v(" "),
        _c("li", [
          _vm._v(
            "Check your e-mail accounts 'Spam/Junk' folder to make sure our e-mail hasn't landed there\n            "
          ),
        ]),
        _vm._v(" "),
        _c("li", [
          _vm._v(
            "Mark us as 'Not junk/Spam' to ensure that you receive all important e-mails from RabbitsMeet\n            "
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "changeEmail" }, [
      _c(
        "label",
        {
          staticClass: "black-text",
          staticStyle: {
            color: "#0b0b0b",
            "font-weight": "bold",
            "line-height": "20px",
          },
        },
        [_vm._v("Want to change your email address?")]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "changeAccountEmail black-text",
          staticStyle: { color: "#0b0b0b", "font-weight": "bold" },
          attrs: { href: "#" },
        },
        [_vm._v("Click here")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "changeForm", staticStyle: { display: "none" } },
      [
        _c("form", { attrs: { name: "change_email" } }, [
          _c("div", { staticClass: "form-group" }, [
            _c("input", {
              staticClass: "form-control",
              attrs: {
                type: "text",
                id: "email",
                name: "email",
                placeholder: "Enter new email address..",
              },
            }),
            _vm._v(" "),
            _c("label", {
              staticClass: "error",
              staticStyle: { color: "#db0b0b" },
            }),
          ]),
          _vm._v(" "),
          _c("div", [
            _c("button", { staticClass: "btn btn-dark update_email" }, [
              _vm._v("Send"),
            ]),
          ]),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }