var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal__content" }, [
    _c("div", { staticClass: "modal__content-text mb-s" }, [
      _vm._v("\n    " + _vm._s(_vm.text) + "\n  "),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "modal__content-text mb-m" }, [
      _vm._v("\n    " + _vm._s(_vm.text_one) + "\n  "),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "border__block mb-m" },
      [
        _c("div", {
          staticClass: "offer__text mb-s",
          domProps: {
            innerHTML: _vm._s(
              _vm.$t("modal.offer", { count: _vm.count, price: _vm.price })
            ),
          },
        }),
        _vm._v(" "),
        _c("Button", {
          attrs: { title: "Purchase" },
          nativeOn: {
            click: function ($event) {
              return _vm.handleSubmit.apply(null, arguments)
            },
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "gradient__wrap" },
      [
        _c("Button", {
          attrs: {
            title: "See more offers",
            clickHandler: _vm.handleSubmit,
            bgColor: _vm.button_color,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "line__text mb-s" }, [
      _c("span", { staticClass: "line" }),
      _c("span", { staticClass: "text" }, [_vm._v("or")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }