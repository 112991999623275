var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "safe-top safe-left safe-right safe-bottom",
      on: { touchstart: _vm.onTouchStart },
    },
    [
      _c("TopBar", { attrs: { tokens: _vm.configData.free_tokens } }),
      _vm._v(" "),
      _c("Nuxt", { class: { blurred: _vm.showTokenWindow } }),
      _vm._v(" "),
      _vm.isChatOpened
        ? _c("ChatModalWindow", {
            attrs: {
              "new-username": _vm.chatName,
              "unique-name": _vm.uniqueName,
              "on-close": _vm.closeChat,
              "model-id": _vm.model_id,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showTokenWindow
        ? _c("TokensFlow", {
            attrs: { "has-token": _vm.tokensLeft },
            on: { close: _vm.closeTokenWindow },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showModal
        ? _c(
            "Modal",
            {
              staticClass: "camclick",
              on: {
                close: function ($event) {
                  _vm.showModal = false
                  _vm.$router.push({ name: "tokens" })
                },
              },
            },
            [
              [
                _c(
                  "div",
                  { staticClass: "modal-wrapper-img" },
                  [_c("Ok", { attrs: { size: 96, "fill-color": "#57CE70" } })],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "info" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.$t("modal.camInvite.text", { model: _vm.modelName })
                      ) +
                      "\n      "
                  ),
                ]),
                _vm._v(" "),
                _c("Button", {
                  attrs: { title: _vm.$t("modal.camInvite.button") },
                  nativeOn: {
                    click: function ($event) {
                      _vm.showModal = false
                      _vm.$router.push({ name: "tokens" })
                    },
                  },
                }),
              ],
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }