<template>
  <div class="modal__content">
    <div class="modal__content-text mb-s">
      {{ text }}
    </div>
    <div class="modal__content-text mb-m">
      {{ text_one }}
    </div>
    <div class="border__block mb-m">
      <div class="offer__text mb-s"  v-html=" $t('modal.offer', { count: count,price: price })">
      </div>
      <Button
        title="Purchase"
        @click.native="handleSubmit"
      />
    </div>
    <div class="line__text mb-s"><span class="line"></span><span class="text">or</span></div>
    <div class="gradient__wrap">
      <Button
        title="See more offers"
        :clickHandler="handleSubmit"
        :bgColor="button_color"
      />
    </div>
  </div>
</template>

<script>
import Button from "../Button/Button";

export default {
  name: "ModalOffer",
  components:{
    Button
  },
  data(){
    return{
      button_color:'#fff'
    }
  },
  props:{
    text: {
      type: String,
      default: ""
    },
    text_one: {
      type: String,
      default: ""
    },
    count: {
      type: Number,
      default: 10
    },
    price: {
      type: Number,
      default: 2
    },
  },
  methods:{
    handleSubmit() {
      this.$emit('submit')
    }
  }
}
</script>

<style lang="scss">
@import "./modaloffer";
</style>
