<template>
  <div
    class="message--foreign">
    <div class="message__contents">
      <img
        v-if="imageUrl"
        :src="imageUrl"
        alt=""
        style="width: 100%">
      <div v-html="contents" class="chat-message"></div>
      <div class="metadata" :class="compact ? 'before_footer': ''"><time class="time">{{ date }}</time></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MessageForeign',
  props: {
    author: {
      type: String,
      default: 'Person',
      required: false
    },
    date: {
      type: String,
      default: '16:30:00',
      required: false
    },
    imageUrl: {
      type: String,
      default: '',
      required: false
    },
    contents: {
      type: String,
      default: '',
      required: false
    }
  },
  data: () => ({
    compact: 0,
  }),
  mounted () {
    // // const textEl = document.getElementsByClassName("chat-message")[0]
    // // const ctx = this
    // const timeEl = document.querySelector(".metadata")
    // const textEl = timeEl.previousElementSibling
    // console.log('rect', timeEl, textEl)
    // var text_rect = textEl.getBoundingClientRect();
    // var tnode = textEl.firstChild;
    // // while (tnode && tnode.nodeType == tnode.ELEMENT_NODE) {
    // //   tnode = tnode.firstChild;
    // // }    
    // if (tnode) {    
    //   var r = document.createRange();
    //   r.setStart(tnode, 0);
    //   r.setEnd(tnode, 1);
    //   var char_rect = r.getBoundingClientRect();      
    //   var time_rect = timeEl.getBoundingClientRect();      
    //   console.log('rect time', char_rect.right, text_rect.right, Math.abs(char_rect.right - text_rect.right) > 3)
    //   if ( Math.abs(char_rect.right - text_rect.right) > 3 ) {
    //     var infoEl = document.querySelector(".chat-message");
    //     if (infoEl) {
    //       var shadowEl = document.createElement('span');
    //       shadowEl.style.display = 'inline-block';
    //       shadowEl.style.width = infoEl.offsetWidth + 'px';
    //       textEl.appendChild(shadowEl);
    //       this.compact = 1
    //     }
    //     console.log('compact', this.compact)
    //   }
    // }
  },
  methods: {
    onClick() {
      console.log('onClick')
      this.$emit('wa')
    },
    linkify(inputText) {
      var replacedText, replacePattern1, replacePattern2, replacePattern3;

      //URLs starting with http://, https://, or ftp://
      replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
      replacedText = inputText.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');

      //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
      replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
      replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank">$2</a>');

      //Change email addresses to mailto:: links.
      replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
      replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');

      return replacedText;
    }
  }
}
</script>

<style lang="scss">
.message__contents .foreign {
  float: right;
  font-size: 11px;
  position: relative;
  bottom: 15px;
  height: 3px;
}
</style>
