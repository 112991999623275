<template>
  <div class="wa">
    <img src="@/assets/images/whatsapp-logo-big.png" alt="123" class="icon" />
    <div class="close" @click="handleClose"></div>
    <WhatsAppNumber
      v-if="STEPS_ORDER[stepNumber] === STEP_NAME.PHONE_NUMBER"
      @step="handleStep"
      @enterNumber="checkNumber"
    />

    <WhatsAppCode
      v-if="STEPS_ORDER[stepNumber] === STEP_NAME.VERIFICATION_CODE"
      @step="handleStep"
    />

    <WhatsAppConfirm
      v-if="STEPS_ORDER[stepNumber] === STEP_NAME.CONFIRMATION"
      @close="closeComponent"
    />
    <!-- <div v-html="getHtml(pixel)" v-if="pixel" style="display: none"></div>        -->
  </div>
</template>

<script>
import {createNamespacedHelpers} from "vuex";
import {Namespaces} from "@/utils/constants/store.js";
import WhatsAppNumber from "@/components/WhatsAppNumber/WhatsAppNumber";
import WhatsAppCode from "@/components/WhatsAppCode/WhatsAppCode";
import WhatsAppConfirm from "@/components/WhatsAppConfirm/WhatsAppConfirm";

import {
  WHATSAPP_STEPS,
  WHATSAPP_STEPS_ORDER
} from "@/utils/constants/index.js";

const {
  mapActions: profileActions,
  mapGetters: profileGetters
} = createNamespacedHelpers(Namespaces.Profile);

export default {
  components: {
    WhatsAppNumber,
    WhatsAppCode,
    WhatsAppConfirm
  },
  data () {
    return {
      // pixel: null,
      stepNumber: 0
    }
  },
  created () {
    this.STEPS_ORDER = WHATSAPP_STEPS_ORDER
    this.STEP_NAME = WHATSAPP_STEPS
  },
  mounted () {
  },
  computed: {
    ...profileGetters(["profileData"])
  },
  methods: {
    ...profileActions(["setWhatsappNumber"]),
    ...profileActions(["setPhoneNumber"]),
    handleStep (step) {
      this.stepNumber = step
    },
    async closeComponent (value) {
      const cid = this.$storage.getCookie('_cid')
      const afid = this.$storage.getCookie('_afid')
      if (cid) {
        // this.pixel = `<img src="https://track.flirtypoint.com/conversion.gif?cid=${cid}&txid=${this.profileData.phone}&et=phonenumber" width="1" height="1"/>`
        await this.$api.post("v1/postback", { clickId: cid, affId: afid, txId: this.profileData.phone, event: "phonenumber" })
      }
      this.$emit("close", value)
    },
    checkNumber (number) {
      // this.setWhatsappNumber(number)
      this.setPhoneNumber(number)
    },
    handleClose () {
      this.$emit("close")
      // this.$emit("wnd_close")
    },
    getHtml(html) {
      return `${html}`
    },
    focusTel (ref) {
      console.log('focus')
      this.$refs[ref].focus()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./whatsAppFlow.scss";
.wa {
  // background-color: aquamarine;
  display: flexbox;
  justify-content: center;
  border-radius: 1.133rem 1.133rem 0 0;
  padding: 52px 16px 24px;
  font-family: "DMSans-Regular";
  background-image: url("@/assets/background/whatsapp.png");
  position: fixed;
  z-index: 2;
  left: 0;
  width: 100%;
  bottom: 0px;
  height: 250px;
  .content {
    display: flex;
    flex-direction: column;
    position: relative;

    .info {
      // margin-top: 2rem;
      margin-bottom: 2rem;
      font-size: 1rem;
    }
  }
    img {
      position: absolute;
      bottom:calc(100% - 35px);
      left: 50%;
      transform: translateX(-50%);
    }
  .close {
    display: block;
    // position: relative;
    top: 0.2rem;
    right: 0.2rem;
    position: absolute;
    // right: 32px;
    // top: 32px;
    width: 32px;
    height: 32px;
    opacity: 0.3;
  }
  .close:hover {
    opacity: 1;
  }
  .close:before, .close:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 33px;
    width: 2px;
    background-color: #333;
  }
  .close:before {
    transform: rotate(45deg);
  }
  .close:after {
    transform: rotate(-45deg);
  }
}
</style>
