var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "input__field" }, [
    _c("input", {
      attrs: {
        type: "text",
        name: "message",
        "aria-placeholder": _vm.$t("chat.input"),
        placeholder: _vm.$t("chat.input"),
        maxlength: "200",
        autofocus: "",
      },
      domProps: { value: _vm.value },
      on: {
        input: function ($event) {
          return _vm.$emit("input", $event.target.value)
        },
        keyup: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
          )
            return null
          $event.preventDefault()
          return _vm.send.apply(null, arguments)
        },
      },
    }),
    _c("br"),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }