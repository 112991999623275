<template>
  <div class="select-wrapper">
    <Multiselect
      ref="multiselect"
      :value="initialCity"
      :options="options"
      @search-change="search"
      @open="open"
      @input="handleSelect"
      :show-labels="false"
      placeholder=""
      deselect-label=""
      :showNoOptions="false"
      :showNoResults="false"
      :multiple="false"
    >
      <template slot="noResult">{{ $t('registration.cityPage.noresult') }}</template>
      <template slot="noOptions">{{ $t('registration.cityPage.listempty') }}</template>
    </Multiselect>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import {createNamespacedHelpers} from "vuex"
import {Namespaces} from "@/utils/constants/store.js"

const {
  mapGetters: configGetters
} = createNamespacedHelpers(Namespaces.Config)

export default {
  name: "SelectCity",
  components: {
    Multiselect
  },
  props: {
    options: {
      type: Array,
      default: () => []
    },
    borderColor: {
      type: String,
      default: "none"
    },
    initialCity: {
      type: String,
      default: "Berlin"
    }
  },
  computed: {
    ...configGetters(["geoDataCity"]),
  },
  methods: {
    handleSelect (city) {
      this.$emit("select", city)
    },
    open (value) {
      this.$nextTick(() => {
        this.$refs.multiselect.search = this.initialCity
      })
    },
    search (value) {
      this.$emit("searchInput", value)
    },
  }
}
</script>

<style lang="scss">
@import "./selectCity.scss";
</style>
