import types from "./types"
 
export default {
  [types.GET_MESSAGES_SUCCESS] (state, messages) {
    state.messages = messages.map(x => (x.from === "client" ? { ...x, sent: true } : x))
//.filter(msg => !(msg.text.indexOf('chat started') > 0 || msg.text.indexOf('Bye-bye') >= 0 ))
.sort((a, b) => a.created_at > b.created_at ? 1 : -1)
//console.log('MSGs', messages)
    state.loading = false
  },
  [types.GET_MESSAGES_FAIL] (state, error) {
    state.loading = false
    state.error = error
  },
  [types.GET_CHATS_SUCCESS] (state, chats) {
    state.chatsList = chats
    state.loading = false
  },
  [types.GET_CHATS_FAIL] (state, error) {
    state.loading = false
    state.error = error
  },
  [types.SET_CHAT_SUCCESS] (state) {
    state.loading = false
  },
  [types.SET_CHAT_FAIL] (state, error) {
    state.loading = false
    state.error = error
  },
  addMessage (state, msg) {
    state.messages.push( msg )
    const audio = new Audio()
    audio.preload = 'auto'
    audio.src = '/audio/send.mp3'
    audio.play().catch(console.log)    
  },
  removeMessage (state, msgId) {
    state.messages = state.messages.filter(msg => msg.msg_id !== msgId)
  },
  setSentMessage (state, msg) {
//console.log('1 MSGs', state.messages.filter(m => m.msg_id == msg.msg_id ))
    state.messages = state.messages.map(x => (x.msg_id === msg.msg_id ? { ...x, sent: true } : x))
// console.log('MSGs', state.messages)
  },
  clearMessages (state) {
    state.messages = []
  }
}
