var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "toggle-wrapper", on: { click: _vm.toggle } },
    [
      _c(
        "div",
        {
          staticClass: "g-button",
          class: { active: _vm.gender === _vm.GENDER.MALE },
        },
        [
          _c(
            "span",
            {
              staticClass: "button__text",
              style: { color: _vm.textColorMale },
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.lookfor === false
                      ? _vm.$t("registration.genderPage.male")
                      : _vm.$t("registration.genderPage.lookformale")
                  ) +
                  "\n    "
              ),
            ]
          ),
          _vm._v(" "),
          _c("Male", { attrs: { color: _vm.textColorMale, size: 22 } }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "g-button",
          class: { active: _vm.gender === _vm.GENDER.FEMALE },
        },
        [
          _c(
            "span",
            {
              staticClass: "button__text",
              style: { color: _vm.textColorFemale },
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.lookfor === false
                      ? _vm.$t("registration.genderPage.female")
                      : _vm.$t("registration.genderPage.lookforfemale")
                  ) +
                  "\n    "
              ),
            ]
          ),
          _vm._v(" "),
          _c("Female", { attrs: { color: _vm.textColorFemale } }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }