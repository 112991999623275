var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "modal__content", on: { click: _vm.handleClose } },
    [
      _c(
        "div",
        { staticClass: "modal__content-img mb-s" },
        [
          _c("HeartCircleOutline", {
            attrs: { size: 96, fillColor: "#5DD478" },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "modal__content-text mb-m" }, [
        _vm._v("\n    " + _vm._s(_vm.text) + "\n  "),
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "next-button",
          attrs: { type: "button" },
          on: { click: _vm.handleSend },
        },
        [
          _c("chat", { staticClass: "icon-button", attrs: { size: 24 } }),
          _vm._v(_vm._s(_vm.$t("like.openchat"))),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "next-button",
          attrs: { type: "button" },
          on: { click: _vm.handleClose },
        },
        [
          _c("cards", { staticClass: "icon-button", attrs: { size: 24 } }),
          _vm._v(_vm._s(_vm.$t("like.close"))),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }