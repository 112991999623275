export default httpClient => ({
  login(data) {
    const params = { ...data }
    return httpClient.post(`sanctum/profile`, params)
  },
  getUserData(userData) {
    return httpClient.get(`sanctum/profile`, userData)
  },
  updateUserData(userData) {
    return httpClient.post(`sanctum/profile/update`, userData)
  },
  addNumber(phone) {
    const params = { phone };
    return httpClient.post(`sanctum/add-phone`, { phone })
  },
  confirmCode(code) {
    return httpClient.post(`sanctum/send-code`, { code })
  },
  verifyPhone(phone) {
    return httpClient.post(`sanctum/phone-verify`, { phone })
  },
  confirmPhone(code) {
    return httpClient.post(`sanctum/phone-confirm`, { code })
  },
  verifyEmail(data, email) {
    return httpClient.post(`sanctum/email/verification`, { data, email })
  },  
  confirmEmail(hash) {
    return httpClient.post(`sanctum/email/confirm`, { hash })
  },
  decrementToken() {
    return httpClient.get('sanctum/decrement-token')
  },
  confirmPayment(payment_id) {
    return httpClient.get(`sanctum/confirmPayment/${payment_id}` )
  },
  getGeodata() {
    return httpClient.get('sanctum/geo-data')
  },
  getPhonePayPIN() {
    return httpClient.get('sanctum/phone-pay/pin')
  },
  emailVerify(email) {
    return httpClient.post(`sanctum/email-verify`, { email })
  },
  getLikes() {
    return httpClient.get('sanctum/reaction-list')
  },
  usernameVerify(name) {
    return httpClient.post(`sanctum/username-verify`, { name })
  }
})