var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "profile-form-wrapper" },
    [
      _c(
        "div",
        { class: { blurred: !_vm.profileData.email_verified_at && 0 } },
        [
          _vm.currentEditField === "name" && _vm.isModalOpen
            ? _c("ModalInput", {
                attrs: {
                  title: _vm.$t("profile.name"),
                  value: _vm.profileData.name,
                },
                on: {
                  changed: function ($event) {
                    return _vm.handleChange("name", $event)
                  },
                  cancel: function ($event) {
                    return _vm.handleCancel()
                  },
                },
              })
            : _c(
                "TheProfileFormField",
                {
                  attrs: { property: _vm.profileData.name, readonly: true },
                  on: { clicked: () => _vm.$router.push("/tokens") },
                },
                [_vm._v("\n      " + _vm._s(_vm.$t("profile.name")) + "\n    ")]
              ),
          _vm._v(" "),
          _vm.currentEditField === "phone_verified" && _vm.isModalOpen
            ? _c("ModalInput", {
                attrs: {
                  type: "tel",
                  title: _vm.$t("profile.phone"),
                  value: _vm.profileData.phone_verified,
                },
                on: {
                  changed: function ($event) {
                    return _vm.handleChange("phone_verified", $event)
                  },
                  cancel: function ($event) {
                    return _vm.handleCancel()
                  },
                },
              })
            : _c(
                "TheProfileFormField",
                {
                  attrs: { property: _vm.profileData.phone_verified || "" },
                  on: { clicked: () => _vm.openFieldEditor("phone_verified") },
                },
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.$t("profile.phone")) + "\n    "
                  ),
                ]
              ),
          _vm._v(" "),
          _vm.currentEditField === "birthday" && _vm.isModalOpen
            ? _c("ModalDatePicker", {
                attrs: { title: _vm.$t("profile.birth") },
                on: {
                  changed: function ($event) {
                    return _vm.handleChange("birthday", $event)
                  },
                  cancel: function ($event) {
                    return _vm.handleCancel()
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "TheProfileFormField",
            {
              attrs: { property: _vm.profileData.birthday },
              on: { clicked: () => _vm.openFieldEditor("birthday") },
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("profile.birth")) + "\n    ")]
          ),
          _vm._v(" "),
          _vm.currentEditField === "gender" && _vm.isModalOpen
            ? _c("ModalSelect", {
                attrs: {
                  title: _vm.$t("profile.gender"),
                  options: [
                    {
                      label: _vm.$t("registration.genderPage.male"),
                      value: "male",
                    },
                    {
                      label: _vm.$t("registration.genderPage.female"),
                      value: "female",
                    },
                  ],
                  "current-property": _vm.getGender(_vm.profileData.gender),
                },
                on: {
                  changed: function ($event) {
                    return _vm.handleChange("gender", $event)
                  },
                  cancel: function ($event) {
                    return _vm.handleCancel()
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "TheProfileFormField",
            {
              attrs: { property: _vm.getGender(_vm.profileData.gender) },
              on: { clicked: () => _vm.openFieldEditor("gender") },
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("profile.gender")) + "\n    ")]
          ),
          _vm._v(" "),
          _vm.currentEditField === "looking_gender" && _vm.isModalOpen
            ? _c("ModalSelect", {
                attrs: {
                  title: _vm.$t("profile.partner"),
                  options: [
                    {
                      label: _vm.$t("registration.genderPage.lookformale"),
                      value: "male",
                    },
                    {
                      label: _vm.$t("registration.genderPage.lookforfemale"),
                      value: "female",
                    },
                  ],
                  "current-property": _vm.getLookForGender(
                    _vm.profileData.looking_gender
                  ),
                },
                on: {
                  changed: function ($event) {
                    return _vm.handleChange("looking_gender", $event)
                  },
                  cancel: function ($event) {
                    return _vm.handleCancel()
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "TheProfileFormField",
            {
              attrs: {
                property: _vm.getLookForGender(_vm.profileData.looking_gender),
              },
              on: { clicked: () => _vm.openFieldEditor("looking_gender") },
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("profile.partner")) + "\n    ")]
          ),
          _vm._v(" "),
          _vm.currentEditField === "country" && _vm.isModalOpen
            ? _c("ModalSelect", {
                attrs: {
                  title: _vm.$t("profile.country"),
                  options: [
                    "Switzerland",
                    "Ukraine",
                    "Austria",
                    "Germany",
                    "United States",
                    "United Kingdom",
                  ],
                  "current-property": _vm.profileData.country,
                },
                on: {
                  changed: function ($event) {
                    return _vm.handleChange("country", $event)
                  },
                  cancel: function ($event) {
                    return _vm.handleCancel()
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "TheProfileFormField",
            {
              attrs: { "is-country": true, property: _vm.profileData.country },
              on: { clicked: () => _vm.openFieldEditor("country") },
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("profile.country")) + "\n    ")]
          ),
          _vm._v(" "),
          _vm.currentEditField === "city" && _vm.isModalOpen
            ? _c("ModalInput", {
                attrs: {
                  title: _vm.$t("profile.city"),
                  value: _vm.profileData.city,
                },
                on: {
                  changed: function ($event) {
                    return _vm.handleChange("city", $event)
                  },
                  cancel: function ($event) {
                    return _vm.handleCancel()
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "TheProfileFormField",
            {
              attrs: { property: _vm.profileData.city },
              on: { clicked: () => _vm.openFieldEditor("city") },
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("profile.city")) + "\n    ")]
          ),
          _vm._v(" "),
          _c(
            "TheProfileFormField",
            {
              attrs: {
                property: _vm.tokensLeft.toString(),
                "is-token-field": true,
              },
              on: { clicked: _vm.openTokensPage },
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("profile.tokens")) + "\n    ")]
          ),
          _vm._v(" "),
          _vm.profileData.paidPromo
            ? _c(
                "TheProfileFormField",
                {
                  attrs: { property: "Cancel" },
                  on: { clicked: _vm.handleMembership },
                },
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.$t("profile.membership")) + "\n    "
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      !_vm.profileData.email_verified_at && 0
        ? _c("div", { staticClass: "email-verification" }, [
            _c("p", [_vm._v(_vm._s(_vm.$t("profile.email")))]),
            _vm._v(" "),
            _c(
              "form",
              {
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.handleVerification.apply(null, arguments)
                  },
                },
              },
              [
                _c("input", {
                  attrs: { type: "email", readonly: "" },
                  domProps: { value: _vm.profileData.email },
                }),
                _vm._v(" "),
                _c("button", { attrs: { disabled: _vm.isEmailSent } }, [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("profile.verify")) + "\n      "
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _vm.isEmailSent
              ? _c("p", { staticClass: "sent-info" }, [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("profile.verification")) +
                      "\n    "
                  ),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticStyle: { padding: "20px" } }, [
        _c("span", [
          _vm._v(_vm._s(_vm.$t("contact.info")) + " "),
          _c("a", { attrs: { href: "mailto:support@mailercare.com" } }, [
            _vm._v("support@mailercare.com"),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { class: { blurred: !_vm.profileData.email_verified_at && 0 } },
        [
          _vm.currentEditField === "avatar"
            ? _c("ModalCrop", {
                attrs: { title: _vm.$t("profile.upload") },
                on: {
                  changed: function ($event) {
                    return _vm.handleChange("avatar", $event)
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("PhotoUpload", {
            attrs: { src: _vm.profileData.avatar },
            on: { clicked: () => _vm.openFieldEditor("avatar") },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.cancelMembership
        ? _c("ModalCancelMembership", {
            on: {
              "close-modal": function ($event) {
                _vm.cancelMembership = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }