<template>
  <div class="toast__wrap">
    <div class="toast__item">
      <div class="toast__img" v-if="img"><img :src="img" alt="photo" @click="handleClick"></div>
      <div class="toast__info" @click="handleClick">
        <span class="toast__info-name">{{name}}</span>
        <span class="toast__info-text">{{text}}</span>
      </div>
      <svg
          class="icon-close"
          viewBox="0 0 50 45"
          xmlns="http://www.w3.org/2000/svg"
      >
        <path
            d="M9.15625 6.3125L6.3125 9.15625L22.15625 25L6.21875 40.96875L9.03125 43.78125L25 27.84375L40.9375 43.78125L43.78125 40.9375L27.84375 25L43.6875 9.15625L40.84375 6.3125L25 22.15625Z"
            fill="#fff"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: "Toast",
  props:{
    img: {
      type: String,
      default: ""
    },
    name: {
      type: String,
      default: ""
    },
    text: {
      type: String,
      default: ""
    },
    handleClick: {
      type: Function,
      default: () => {}
    }
  }
}
</script>

<style  lang="scss" scoped>
@import "./toast";
.toast__wrap{
  flex-direction: column;
}
.icon-close {
  font-weight: bold;
  display: block;
  margin-left: auto;
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
  transition: 0.3s ease;
  align-items: center;
  color: #fff;
  opacity: 0.3;
  transition: visibility 0s, opacity 0.2s linear;
  &:hover,
  &:focus {
    opacity: 1;
  }
}
</style>
