var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "modal__content" },
    [
      _c("div", { staticClass: "modal__content-text mb-m" }, [
        _vm._v("\n    " + _vm._s(_vm.text) + "\n  "),
      ]),
      _vm._v(" "),
      _c("Button", {
        attrs: { title: "ok" },
        nativeOn: {
          click: function ($event) {
            return _vm.handleSubmit.apply(null, arguments)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }