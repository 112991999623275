var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "nuxt-link",
    { staticClass: "back-button", attrs: { to: "/" } },
    [
      _c("ArrowLeft", { attrs: { size: 16 } }),
      _vm._v(" "),
      _c("span", [_vm._v(_vm._s(_vm.$t("profile.back")))]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }