<template>
  <div class="wrapper">
    <div class="left-side">
      <nuxt-link to="/profile" class="left-side-name">
        <Account class="icon" fill-color="#A2B2D1" title="Profile" :size="32" />
        <span class="left-side-text">{{ profileData.email.substring(0, profileData.email.lastIndexOf("@")) || "" }}</span>
      </nuxt-link>
    </div>
    <!-- <nuxt-link to="/chats" class="icon__wrap center__mode" target="_blank">
        <Bell class="icon" fillColor="#A2B2D1" title="All Chats" :size="32"/>
        <span class="alert alert-msg" v-if="alert">{{ alert }}</span>
    </nuxt-link> -->
    <div class="right-side">
      <nuxt-link to="/chats" class="icon__wrap">
        <CommentMultiple class="icon" fill-color="#A2B2D1" title="All Chats" :size="32" />
        <span v-if="unread" class="alert alert-msg">{{ unread }}</span>
      </nuxt-link>
      <div class="right-side-tokens">
        <nuxt-link to="/tokens" class="icon__wrap">
          <Toll fill-color="#A2B2D1" title="Tokens" :size="32" />
          <!-- <span class="amount">{{ tokensLeft }}</span> -->
        </nuxt-link>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import Account from 'vue-material-design-icons/Menu.vue';
import CommentMultiple from 'vue-material-design-icons/EmailOutline.vue';
import Toll from 'vue-material-design-icons/CircleMultiple.vue';
import { Namespaces } from '@/utils/constants/store.js';

const {
  mapActions: profileActions,
  mapGetters: profileGetters
} = createNamespacedHelpers(Namespaces.Profile);

export default {
  components: {
    Account,
    CommentMultiple,
    Toll
  },
  props: {
    tokens: {
      type: [Number, String],
      default: 3
    },
    notification: {
      type: Number,
      default: 0
    }
    // message: {
    //   type: Number,
    //   default: 0
    // }
  },
  data () {
    return {
      tokenIcon: require('@/assets/Icons/Tokens/tokens.svg')
    };
  },
  computed: {
    ...profileGetters(['profileData', 'tokensLeft']),
    unread () {
      return this.$store.state.messages.unread.length;
    }
  },
  created () {
    // this.getUser()
  },
  methods: {
    ...profileActions(['getUser'])
  }
};
</script>

<style lang="scss" scoped>
@import "./header.scss";
</style>
