<template>
  <div class="slider-container">
    <div class="slider">
      <client-only>
        <swiper
          :options="swiperOptions"
          @slideChange="onSlideChange"
          @ready="onSwiperInit"
          @sliderMove="onSliderMove"
        >
          <swiper-slide
            v-for="model in modelsListFiltered"
            :key="model.id"
            :data-id="model.id"
          >
            <Slide
              :id="model.id"
              :key="model.id"
              :image="model.files.length > 0 ? model.files[0].path : ''"
              :name="model.name"
              :age="model.age"
              :distance="model.distance"
              :status="model.online"
              :description="model.description"
            />
          </swiper-slide>
        </swiper>
      </client-only>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import Slide from './Slide';
import 'swiper/css/swiper.min.css';
import { Namespaces } from '@/utils/constants/store.js';

const {
  mapActions: modelsActions,
  mapGetters: modelsGetters
} = createNamespacedHelpers(Namespaces.Models);
export default {
  name: 'GirlsSlider',
  components: {
    Slide,
    Swiper,
    SwiperSlide
  },
  data () {
    return {
      reachEnd: false,
      atEdge: false,
      swiperOptions: {
        slidesPerView: 'auto',
        initialSlide: this.$storage.getState('lastseenmodel') || 2,
        centeredSlides: true
      }
    };
  },
  computed: {
    ...modelsGetters(['modelsList']),
    modelsListFiltered () {
      // console.log('ml', this.modelsList, this.modelsList.filter(m => m.files.length > 0));
      return this.modelsList.filter(m => m.files.length > 0);
    }
    // getImageSlider () {
    // // TODO change slider image src
    // }
  },
  mounted () {
    // console.log('Component mounted')
    // if (this.$storage.getState('lastseenmodel') === undefined) {
    //   // console.log('lastmodel', this.$storage.getState('lastseenmodel'))
    //   this.swiperOptions.initialSlide = 13
    // }
  },
  methods: {
    ...modelsActions(['updateSwiperModelId']),
    ...modelsActions(['getModelsList']),
    onSlideChange () {
      const { slides, activeIndex } = this.swiper;
      const currentSlide = slides[activeIndex];
      if (!currentSlide) {
        return;
      }
      const modelId = currentSlide.dataset.id;
      this.updateSwiperModelId(modelId);
      this.$storage.setState('lastseenmodel', activeIndex);
      this.$emit('updateModel', modelId);
      // console.log('lastmodel', this.$storage.getState('lastseenmodel'))
    },
    slideNext () {
      this.swiper.slideNext();
    },
    onSwiperInit (swiper) {
      this.swiper = swiper;
      this.onSlideChange();
    },
    async onSliderMove (swiper) {
      // console.log('move', swiper, this.swiper.swipeDirection)
      if (!this.atEdge && this.swiper.isEnd && this.swiper.swipeDirection === 'next') {
        this.atEdge = true;
        const clist = JSON.parse(sessionStorage.getItem('clist'))?.filter(n => n);
        let cnext = +sessionStorage.getItem('cnext');
        // console.log('next to', cnext, clist, clist[cnext]);
        // alert('back to ' + cnext +' ' + clist[cnext]);
        if (clist && cnext !== undefined) {
          cnext = ++cnext % clist.length;
          // this.$storage.setLocalStorage('cnext', cnext);
          // console.log('back to', cnext, clist, clist[cnext]);
          sessionStorage.setItem('cnext', cnext);
        }
        if (clist && clist[cnext]) {
          // alert('getmodels ' + cnext +' ' + clist[cnext]);
          // this.$auth.$storage.setCookie('category', clist[cnext]);
          this.$storage.setState('lastseenmodel', 2);
          await this.getModelsList({ category: clist[cnext] });
        } else {
          this.$router.push({ name: 'tokens' });
        }
        this.swiper.slideTo(0, 100);
        // this.$router.push({ name: 'index' });
        this.atEdge = false;
      }
      if (!this.atEdge && this.swiper.isBeginning && this.swiper.swipeDirection === 'prev') {
        this.atEdge = true;
        const clist = JSON.parse(sessionStorage.getItem('clist'))?.filter(n => n);
        let cnext = +sessionStorage.getItem('cnext');
        // console.log('back to', cnext, clist, clist[cnext]);
        // alert('back to ' + cnext +' ' + clist[cnext]);
        if (clist && cnext !== undefined) {
          cnext--;
          if (cnext < 0) { cnext = clist.length - 1; }
          cnext = cnext % clist.length;
          // cnext = cnext === 1 ? 0 : cnext === 2 ? 1 : 2;
          // this.$storage.setLocalStorage('cnext', cnext);
          sessionStorage.setItem('cnext', cnext);
        }
        if (clist && clist[cnext]) {
          // console.log('prev to', cnext, clist, clist[cnext], clist.length);
          // alert('getmodels ' + cnext +' ' + clist[cnext]);
          // this.$auth.$storage.setCookie('category', clist[cnext]);
          this.$storage.setState('lastseenmodel', 2);
          await this.getModelsList({ category: clist[cnext] });
        } else {
          this.$router.push({ name: 'tokens' });
        }
        this.swiper.slideTo(0, 100);
        // this.$router.push({ name: 'index' });
        this.atEdge = false;
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "./slider.scss";
</style>
