<template>
  <GenderMale :fillColor="color" :size="size" />
</template>

<script>
import GenderMale from "vue-material-design-icons/GenderMale.vue";

export default {
  components: {
    GenderMale
  },
  props: {
    color: {
      type: String,
      default: "#565dff"
    },
    size: {
      type: Number,
      default: 24
    }
  }
};
</script>
