import types from './types';
import { SWIPER_SLIDE } from '@/utils/constants/index.js';

export default {
  [types.GET_MODELS_SUCCESS] (state, models) {
    // console.log('m', models.data.map((m) => { return { ...m, files: m.files.filter((f) => f.path.indexOf('.png')>-1) } }))
    state.models = models.data.map((m) => { return { ...m, files: m.files.filter(f => (f.path.includes('.png') || f.path.includes('.gif'))), distance: Math.floor(Math.random() * 26) + 4 }; }).sort(() => Math.random() - 0.5);
    state.swiperCurrentModelId = models.data[SWIPER_SLIDE.DEFAULT_ID].id; // TODO Индекс вынести в константу
    state.loading = false;
  },

  [types.GET_MODELS_FAIL] (state, error) {
    state.loading = false;
    state.error = error;
  },

  [types.REACT_ON_MODEL_SUCCESS] (state, data) {
    // console.log('on', data)
    if (data.is_like === 0) {
      state.models = state.models.filter(model => model.id !== data.id);
    }
    state.loading = false;
  },

  [types.REACT_ON_MODEL_FAIL] (state, error) {
    state.loading = false;
    state.error = error;
  },

  [types.SWIPER_UPDATE_MODELID_SUCCESS] (state, id) {
    state.swiperCurrentModelId = id;
    state.loading = false;
  },

  [types.SWIPER_UPDATE_MODELID_FAIL] (state, error) {
    state.loading = false;
    state.error = error;
  }
};
