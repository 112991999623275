var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      staticClass: "button-wrapper",
      style: { background: _vm.bgColor, color: _vm.textColor },
      attrs: { disabled: _vm.disabled, type: _vm.type },
    },
    [_vm._v("\n  " + _vm._s(_vm.title) + "\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }