<template>
  <Whatsapp :fillColor="color" :size="size" :title="title" />
</template>

<script>
import Whatsapp from "vue-material-design-icons/Whatsapp.vue";

export default {
  components: {
    Whatsapp
  },
  props: {
    title: {
      type: String,
      default: "Whatsapp"
    },
    color: {
      type: String,
      default: "#3CCF65"
    },
    size: {
      type: Number,
      default: 42
    }
  }
};
</script>
