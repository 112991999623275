<template>
  <div class="toggle-wrapper" @click="toggle">
    <div class="g-button" :class="{ active: gender === GENDER.MALE }">
      <span class="button__text" :style="{ color: textColorMale }">
        {{ (lookfor === false) ? $t("registration.genderPage.male") : $t("registration.genderPage.lookformale")}}
      </span>
      <Male :color="textColorMale" :size="22" />
    </div>

    <div class="g-button" :class="{ active: gender === GENDER.FEMALE }">
      <span class="button__text" :style="{ color: textColorFemale }">
        {{ (lookfor === false) ? $t("registration.genderPage.female") : $t("registration.genderPage.lookforfemale")}}
      </span>
      <Female :color="textColorFemale" />
    </div>
  </div>
</template>

<script>
import { Male, Female } from "@/assets/Icons/"
import { COLORS, GENDER } from "@/utils/constants/index.js"

export default {
  components: {
    Female,
    Male
  },
  props: {
    textColorMale: {
      type: String,
      default: COLORS.PURPLE
    },
    textColorFemale: {
      type: String,
      default: COLORS.PINK
    },
    defaultGender: {
      type: String,
      default: GENDER.MALE
    },
    lookfor: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      gender: this.defaultGender
    }
  },
  created () {
    this.GENDER = GENDER
  },
  methods: {
    toggle () {
      this.gender === GENDER.MALE
        ? (this.gender = GENDER.FEMALE)
        : (this.gender = GENDER.MALE)

      this.$emit("clicked", this.gender)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./genderToggle.scss";
</style>
