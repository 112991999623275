export default {
  error: state => state.error,
  loading: state => state.loading,
  profileData: state => state.profileData,
  whatsappVerification: state => state.profileData.whatsappVerified,
  phoneVerification: state => state.profileData.phoneVerified,
  tokensLeft: state => state.profileData.count_tokens,
  city: state => state.profileData.city,
  country: state => state.profileData.country,
  userId: state => state.profileData.id,
  phonePin: state => state.profileData.phone_pay_pin,
  likes: state => state.profileData.likes
};
