<template>
  <Modal :button-title="$t('modal.email.button')" @close="handleClose">
    <template v-if="!emailSent">
      <div class="info">
        <span>{{ $t('modal.email.confirmEmail', { modelName }) }}</span>
      </div>
      <input
        id="input_email"
        type="email"
        required="required"
        placeholder="E-mail"
        :value="userEmail"
        @input="userEmail = $event.target.value"
      >
      <button @click="handleEmail">
        <Loader class="icon-button" style="display: none;" />
        {{ $t('buttons.next') }}
      </button>
    </template>
    <template v-else>
      <div class="info">
        {{ $t('profile.verification') }}
      </div>
      <button @click="handleClick">
        Ok
      </button>
    </template>
    <div v-if="showError" class="err-message-wrapper js-err">
      <div class="err-message">
        <i class="close" @click="hideErr" />
        <span>{{ errMessage }}</span>
      </div>
    </div>
  </Modal>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import Modal from '@/components/Modal/Modal';
import { Namespaces } from '@/utils/constants/store.js';

const {
  mapActions: registrationActions
  // mapGetters: registrationGetters
} = createNamespacedHelpers(Namespaces.Registration);

export default {
  name: 'ModalInputEmailVerify',
  components: {
    Modal
  },
  props: {
    text: {
      type: String,
      default: 'modal.email.text'
    },
    btnTitle: {
      type: String,
      default: 'modal.email.button'
    },
    email: {
      type: String,
      default: ''
    },
    modelName: {
      type: String,
      default: 'Null'
    }
  },
  data () {
    return {
      emailSent: 0,
      userEmail: null,
      showError: false,
      errMessage: ''
    };
  },
  methods: {
    ...registrationActions(['checkEmail']),
    async handleVerification () {
      await this.$UserAPI.verifyEmail(this.$route.fullPath, this.userEmail)
        .then((response) => {
          this.emailSent = true;
          this.$store.commit('setEmailSent', true);
        })
        .catch(() => {
          this.showErr('Your Email address is not allowed. ');
        });
    },
    async verifyEmail (email) {
      // return true;
      const res = await this.checkEmail(email);
      return res === 'OK';
    },
    handleClose () {
      this.$emit('close-modal');
      // this.$router.push({ name: 'tokens' })
    },
    handleClick () {
      this.$emit('click-modal');
    },
    showErr (message) {
      this.errMessage = message;
      this.showError = true;
    },
    hideErr () {
      this.errMessage = '';
      this.showError = false;
    },
    async handleEmail (e) {
      e.target.disabled = true;
      document.querySelector('.icon-button').style = 'display: inline';
      const emailRegex = /^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i;
      if (
        (!this.userEmail || this.userEmail.trim() === '') || // required
          !emailRegex.test(this.userEmail) // email
      ) {
        this.showErr(this.$i18n.t('sf.error.email.requiredMessage'));
      } else if ((this.userEmail && this.userEmail.trim().length > 150)) { // maxLength
        this.showErr(this.$i18n.t('sf.error.email.maxlengthMessage'));
      } else if (await this.verifyEmail(this.userEmail)) { // checkEmail
        this.handleVerification();
      } else {
        this.showErr(this.$i18n.t('sf.error.email.emailaddressnotvalidMessage'));
      }
      document.querySelector('.icon-button').style = 'display: none';
      e.target.disabled = false;
    }
  }
};
</script>

<style scoped lang="scss">
input {
  background: #fff;
  color: #111;
  border: 1px solid #999;
  padding: 0 2%;
  float: none;
  font-size: 20px;
  width: 100%;
  height: 60px;
  line-height: 60px;
  margin-bottom: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px
}
.icon-button {
  position: absolute;
  width: 40px;
  height: 0px;
  bottom: 47px;
  right: 5px;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
button {
  margin: 10px 0 0;
  float: none;
  background: orange;
  border: none;
  color: #fff;
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  border-radius: 40px;
  display: block;
  text-transform: uppercase;
  font-size: 32px;
  font-weight: 700;
  width: 100%;
  height: 60px;
  line-height: 60px;
  float: none;
  margin: 20px 0 0;
  padding: 0;
  cursor: pointer;
  text-align: center;
  border: none;
  text-decoration: none;
  outline: 0;
  &:disabled {
    background: gray;
  }
}
.err-message-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.7);
  z-index: 999;
}
.err-message-wrapper .err-message {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1em;
  width: 20em;
  height: 10em;
  border: solid .05em #ff0000;
  background-color: #ffffff;
  color: #ff0000;
  padding: 1em;
  border-radius: .4em
}
.info {
  line-height: 130%;
  font-weight: 400;
  letter-spacing: 0.1rem;
  color: black;
}
</style>
