<template>
  <ArrowLeft :fillColor="color" :size="size" @click="clickHandler" />
</template>

<script>
import ArrowLeft from "vue-material-design-icons/ArrowLeft.vue";

export default {
  components: {
    ArrowLeft
  },
  props: {
    color: {
      type: String,
      default: "#00000"
    },

    size: {
      type: Number,
      default: 24
    },

    clickHandler: {
      type: Function,
      default: () => {}
    }
  }
};
</script>
