import types from "./types"

export default {
  [types.REGISTRATION_SUCCESS](state, data) {
    const stateData = { ...state.userData, ...data }
    state.userData = { ...stateData }
    state.loading = false
  },
  [types.REGISTRATION_FAIL](state, error) {
    state.loading = false
    state.error = error
  },
  [types.GET_CITIES_SUCCESS](state, cities) {
    state.cities = cities.data
    state.loading = false
  },
  [types.GET_CITIES_FAIL](state, error) {
    state.loading = false
    state.error = error
  },
  // [types.EMATL_VERIFY_SUCCESS](state, data) {
  //   state.emailChecked = data;
  //   state.loading = false;
  // },
  // [types.EMATL_VERIFY_FAIL](state, error) {
  //   state.loading = false;
  //   state.error = error;
  // },
}
