var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", {
    staticClass: "apple-pay-button-with-text apple-pay-button-black-with-text",
    on: { click: _vm.handleApplePay },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }