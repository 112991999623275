export default () => ({
  loading: false,
  error: false,
  configData: {
    image_1: "",
    image_2: "",
    image_3: "",
    position: "",
    free_tokens: null,
    time_message: null,
    time_profile_view: null,
    time_like: null
  },
  geoData: {
    location: {
      latitude: 0,
      longitude: 0,
    },
    city: {
      names: {
        en: null
      }
    },
    city_models: {
      city_name: null
    },
    country: {
      names: {
        en: null
      }
    },
    traits: {
      ip_address: null
    }
  }
});
