var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "slide", staticClass: "slide-wrap" },
    [
      _c(
        "nuxt-link",
        {
          staticClass: "slide-text-name",
          attrs: {
            to: { name: "girls-id", params: { id: _vm.id } },
            exact: "",
          },
        },
        [_c("img", { attrs: { src: _vm.image, alt: _vm.alt } })]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "slide-text" },
        [
          _c(
            "nuxt-link",
            {
              staticClass: "slide-text-name",
              attrs: {
                to: { name: "girls-id", params: { id: _vm.id } },
                exact: "",
              },
            },
            [
              _c("p", { staticClass: "name" }, [
                _vm._v("\n        " + _vm._s(_vm.name) + "\n      "),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "distributor" }, [
                _vm._v("\n        |\n      "),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "age" }, [
                _vm._v("\n        " + _vm._s(_vm.age) + "\n      "),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "slide-text-info" }, [
            _c(
              "p",
              { class: _vm.status ? "status" : "status status-offline" },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.status ? "online" : "offline") +
                    "\n      "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              { staticClass: "model-location" },
              [
                _c("MapMarkerOutline", { attrs: { size: 18 } }),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("profile.distance", {
                        distance: _vm.distance,
                        unit:
                          _vm.profileData.country.indexOf("United States") !==
                          -1
                            ? "ml"
                            : "km",
                      })
                    ) +
                    "\n      "
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "slide-text-info" }, [
            _c("p", { staticClass: "description" }, [
              _vm._v("\n        " + _vm._s(_vm.description) + "\n      "),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }