var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card-container" }, [
    _c("div", { staticClass: "jDamgM" }, [
      _c("section", { staticClass: "kgdJei" }, [
        _c("div", [
          _c("div", { staticClass: "kwZflE" }, [
            _vm._v("\n          Payment method\n        "),
          ]),
          _vm._v(" "),
          _c("ul", { staticClass: "lgKwEH" }, [
            _c("li", { staticClass: "hpBuqM", attrs: { open: "" } }, [
              _c("header", { staticClass: "dHFTMw" }, [
                _vm._m(0),
                _vm._v(" "),
                _c("div", { staticClass: "gwxuaO" }, [
                  _c(
                    "svg",
                    {
                      staticClass: "kFkHqy",
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        width: "22",
                        height: "16",
                        fill: "none",
                        viewBox: "0 0 22 16",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          fill: "#FF5F00",
                          d: "M13.12 3.668H8.623v8.08h4.497v-8.08Z",
                        },
                      }),
                      _vm._v(" "),
                      _c("path", {
                        attrs: {
                          fill: "#EB001B",
                          d: "M8.909 7.708a5.13 5.13 0 0 1 1.962-4.04 5.139 5.139 0 1 0 0 8.08 5.13 5.13 0 0 1-1.962-4.04Z",
                        },
                      }),
                      _vm._v(" "),
                      _c("path", {
                        attrs: {
                          fill: "#F79E1B",
                          d: "M19.187 7.708a5.139 5.139 0 0 1-8.315 4.04 5.14 5.14 0 0 0 0-8.08 5.139 5.139 0 0 1 8.315 4.04Zm-.492 3.185v-.166h.067v-.033h-.17v.033h.067v.166h.036Zm.33 0v-.2h-.052l-.06.138-.06-.137H18.8v.199h.037v-.15l.056.13h.038l.056-.13v.15h.037Z",
                        },
                      }),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "gwxuaO" }, [
                  _c(
                    "svg",
                    {
                      staticClass: "kFkHqy",
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        width: "35",
                        height: "22",
                        fill: "none",
                        viewBox: "0 0 35 22",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          fill: "#1434CB",
                          d: "M14.492 7.141 11.22 14.88H9.084L7.473 8.704c-.098-.38-.183-.52-.48-.68-.486-.26-1.288-.506-1.993-.658l.048-.225h3.437c.438 0 .832.29.932.79l.85 4.477L12.37 7.14h2.122Zm8.367 5.212c.009-2.043-2.85-2.155-2.83-3.067.006-.278.273-.573.857-.648.289-.038 1.087-.067 1.991.346l.355-1.641A5.472 5.472 0 0 0 21.342 7c-1.996 0-3.402 1.052-3.413 2.558-.013 1.114 1.003 1.735 1.768 2.105.787.38 1.052.623 1.049.962-.006.52-.628.748-1.21.757-1.016.016-1.605-.272-2.074-.488l-.366 1.695c.471.214 1.343.402 2.246.411 2.122 0 3.51-1.039 3.517-2.647Zm5.273 2.526H30l-1.63-7.738h-1.725a.92.92 0 0 0-.86.568l-3.031 7.17h2.12l.422-1.156h2.592l.244 1.156Zm-2.254-2.742 1.063-2.905.612 2.905h-1.675Zm-8.5-4.996-1.67 7.738h-2.02l1.671-7.738h2.02Z",
                        },
                      }),
                    ]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "ggbOVt" }, [
                _c(
                  "article",
                  { staticStyle: { opacity: "1", height: "auto" } },
                  [
                    _c("div", { staticClass: "foNVFO" }, [
                      _c("section", { staticClass: "PuvYm" }, [
                        _c(
                          "div",
                          {
                            staticClass: "euAZOt",
                            attrs: { "data-testid": "add-new-card-tab" },
                          },
                          [
                            _c("div", { staticClass: "EvIFC" }, [
                              _c("div", { staticClass: "jkUJqE" }, [
                                _c("label", {
                                  staticClass: "bNwNdk",
                                  attrs: { for: "card_holder" },
                                }),
                                _vm._v(" "),
                                _c("div", { staticClass: "cRUgqu" }, [
                                  _c("input", {
                                    ref: "card_holder",
                                    staticClass: "ixPvWM",
                                    staticStyle: {
                                      "text-transform": "uppercase",
                                    },
                                    attrs: {
                                      id: "card_holder",
                                      name: "card_holder",
                                      type: "text",
                                      placeholder: "Cardholder name",
                                      maxlength: "200",
                                    },
                                    domProps: { value: _vm.name },
                                    on: {
                                      change: _vm.onAcceptName,
                                      input: _vm.onAcceptName,
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "svg",
                                    {
                                      staticClass:
                                        "svg-inline--fa fa-user-large kkAHbc dEzdNj",
                                      attrs: {
                                        "aria-hidden": "true",
                                        focusable: "false",
                                        "data-prefix": "far",
                                        "data-icon": "user-large",
                                        role: "img",
                                        xmlns: "http://www.w3.org/2000/svg",
                                        viewBox: "0 0 512 512",
                                      },
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          fill: "currentColor",
                                          d: "M256 288c79.53 0 144-64.47 144-144s-64.47-144-144-144c-79.52 0-144 64.47-144 144S176.5 288 256 288zM256 48c52.94 0 96 43.06 96 96c0 52.93-43.06 96-96 96c-52.93 0-96-43.07-96-96C160 91.06 203.1 48 256 48zM351.1 320H160c-88.36 0-160 71.63-160 160c0 17.67 14.33 32 31.1 32H480c17.67 0 31.1-14.33 31.1-32C512 391.6 440.4 320 351.1 320zM49.14 464c7.787-54.21 54.54-96 110.9-96h191.1c56.33 0 103.1 41.79 110.9 96H49.14z",
                                        },
                                      }),
                                    ]
                                  ),
                                ]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "EvIFC" }, [
                              _c("div", { staticClass: "jkUJqE" }, [
                                _c("label", {
                                  staticClass: "bNwNdk",
                                  attrs: { for: "card_number" },
                                }),
                                _vm._v(" "),
                                _c("div", { staticClass: "cRUgqu" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "imask",
                                        rawName: "v-imask",
                                        value: _vm.cardMasks,
                                        expression: "cardMasks",
                                      },
                                    ],
                                    ref: "cardNumber",
                                    staticClass: "ixPvWM",
                                    attrs: {
                                      id: "card_number",
                                      name: "card_number",
                                      type: "text",
                                      placeholder: "XXXX-XXXX-XXXX-XXXX",
                                      maxlength: "20",
                                    },
                                    domProps: { value: _vm.cardNumber },
                                    on: { accept: _vm.onAcceptCard },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "svg",
                                    {
                                      staticClass:
                                        "svg-inline--fa fa-credit-card-front kkAHbc dEzdNj",
                                      attrs: {
                                        "aria-hidden": "true",
                                        focusable: "false",
                                        "data-prefix": "far",
                                        "data-icon": "credit-card-front",
                                        role: "img",
                                        xmlns: "http://www.w3.org/2000/svg",
                                        viewBox: "0 0 576 512",
                                      },
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          fill: "currentColor",
                                          d: "M96 360C96 346.7 106.7 336 120 336H168C181.3 336 192 346.7 192 360C192 373.3 181.3 384 168 384H120C106.7 384 96 373.3 96 360zM224 360C224 346.7 234.7 336 248 336H360C373.3 336 384 346.7 384 360C384 373.3 373.3 384 360 384H248C234.7 384 224 373.3 224 360zM96 280C96 266.7 106.7 256 120 256H456C469.3 256 480 266.7 480 280C480 293.3 469.3 304 456 304H120C106.7 304 96 293.3 96 280zM456 128C469.3 128 480 138.7 480 152V200C480 213.3 469.3 224 456 224H376C362.7 224 352 213.3 352 200V152C352 138.7 362.7 128 376 128H456zM0 96C0 60.65 28.65 32 64 32H512C547.3 32 576 60.65 576 96V416C576 451.3 547.3 480 512 480H64C28.65 480 0 451.3 0 416V96zM48 96V416C48 424.8 55.16 432 64 432H512C520.8 432 528 424.8 528 416V96C528 87.16 520.8 80 512 80H64C55.16 80 48 87.16 48 96z",
                                        },
                                      }),
                                    ]
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "gWxBKy" }, [
                                _c("div", { staticClass: "jkUJqE" }, [
                                  _c("label", {
                                    staticClass: "bNwNdk",
                                    attrs: { for: "expiration_date" },
                                  }),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "cRUgqu" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "imask",
                                          rawName: "v-imask",
                                          value: _vm.expirationMask(
                                            _vm.isTwoDigitsYear
                                          ),
                                          expression:
                                            "expirationMask(isTwoDigitsYear)",
                                        },
                                      ],
                                      ref: "expiration",
                                      staticClass: "ixPvWM",
                                      attrs: {
                                        id: "expiration_date",
                                        name: "expiration_date",
                                        type: "text",
                                        placeholder: "MM/YY",
                                        maxlength: "7",
                                      },
                                      domProps: { value: _vm.expiration },
                                      on: { accept: _vm.onAcceptExpiration },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "svg",
                                      {
                                        staticClass:
                                          "svg-inline--fa fa-credit-card-blank kkAHbc dEzdNj",
                                        attrs: {
                                          "aria-hidden": "true",
                                          focusable: "false",
                                          "data-prefix": "far",
                                          "data-icon": "credit-card-blank",
                                          role: "img",
                                          xmlns: "http://www.w3.org/2000/svg",
                                          viewBox: "0 0 576 512",
                                        },
                                      },
                                      [
                                        _c("path", {
                                          attrs: {
                                            fill: "currentColor",
                                            d: "M96 360C96 346.7 106.7 336 120 336H168C181.3 336 192 346.7 192 360C192 373.3 181.3 384 168 384H120C106.7 384 96 373.3 96 360zM224 360C224 346.7 234.7 336 248 336H360C373.3 336 384 346.7 384 360C384 373.3 373.3 384 360 384H248C234.7 384 224 373.3 224 360zM0 96C0 60.65 28.65 32 64 32H512C547.3 32 576 60.65 576 96V416C576 451.3 547.3 480 512 480H64C28.65 480 0 451.3 0 416V96zM48 96V416C48 424.8 55.16 432 64 432H512C520.8 432 528 424.8 528 416V96C528 87.16 520.8 80 512 80H64C55.16 80 48 87.16 48 96z",
                                          },
                                        }),
                                      ]
                                    ),
                                  ]),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "eNBMlC" }, [
                                _c("div", { staticClass: "jkUJqE" }, [
                                  _c("label", {
                                    staticClass: "bNwNdk",
                                    attrs: { for: "cvc" },
                                  }),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "cRUgqu" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "imask",
                                          rawName: "v-imask",
                                          value: _vm.securityMask,
                                          expression: "securityMask",
                                        },
                                      ],
                                      ref: "security",
                                      staticClass: "ixPvWM",
                                      attrs: {
                                        id: "cvc",
                                        name: "cvc",
                                        type: "text",
                                        placeholder: "CVC",
                                        maxlength: "4",
                                      },
                                      domProps: { value: _vm.cvv },
                                      on: { accept: _vm.onAcceptCvv },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "svg",
                                      {
                                        staticClass:
                                          "svg-inline--fa fa-credit-card kkAHbc dEzdNj",
                                        attrs: {
                                          "aria-hidden": "true",
                                          focusable: "false",
                                          "data-prefix": "far",
                                          "data-icon": "credit-card",
                                          role: "img",
                                          xmlns: "http://www.w3.org/2000/svg",
                                          viewBox: "0 0 576 512",
                                        },
                                      },
                                      [
                                        _c("path", {
                                          attrs: {
                                            fill: "currentColor",
                                            d: "M168 336C181.3 336 192 346.7 192 360C192 373.3 181.3 384 168 384H120C106.7 384 96 373.3 96 360C96 346.7 106.7 336 120 336H168zM360 336C373.3 336 384 346.7 384 360C384 373.3 373.3 384 360 384H248C234.7 384 224 373.3 224 360C224 346.7 234.7 336 248 336H360zM512 32C547.3 32 576 60.65 576 96V416C576 451.3 547.3 480 512 480H64C28.65 480 0 451.3 0 416V96C0 60.65 28.65 32 64 32H512zM512 80H64C55.16 80 48 87.16 48 96V128H528V96C528 87.16 520.8 80 512 80zM528 224H48V416C48 424.8 55.16 432 64 432H512C520.8 432 528 424.8 528 416V224z",
                                          },
                                        }),
                                      ]
                                    ),
                                  ]),
                                ]),
                              ]),
                            ]),
                          ]
                        ),
                      ]),
                    ]),
                  ]
                ),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _vm.needEmail
          ? _c("div", [
              _c("div", { staticClass: "kwZflE" }, [
                _vm._v("\n          Customer information\n        "),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "EvIFC" }, [
                _c("div", { staticClass: "jkUJqE" }, [
                  _c(
                    "label",
                    {
                      staticClass: "cRhlMD",
                      attrs: { for: "email", required: "" },
                    },
                    [_vm._v("Email")]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "cRUgqu" }, [
                    _c("input", {
                      ref: "userEmail",
                      staticClass: "boDkmi",
                      attrs: {
                        id: "email",
                        name: "email",
                        type: "email",
                        required: "",
                        placeholder: "e.g. john.smith@example.com",
                        maxlength: "200",
                        autocomplete: "on",
                      },
                      domProps: { value: _vm.email },
                      on: {
                        change: _vm.onAcceptEmail,
                        input: _vm.onAcceptEmail,
                      },
                    }),
                  ]),
                ]),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticStyle: { "margin-top": "15px" } }, [
          _c(
            "button",
            {
              staticClass: "cXoVPd",
              attrs: { type: "button", disabled: !_vm.isBtnEnabled },
              on: { click: _vm.submitCard },
            },
            [_vm._v("\n          Confirm & Pay\n        ")]
          ),
          _vm._v(" "),
          _c("div", { staticStyle: { "margin-top": "15px" } }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticStyle: {
                display: "none",
                "margin-top": "10px",
                "justify-content": "space-between",
                "flex-wrap": "wrap",
              },
            },
            [
              _c("div", { staticStyle: { "margin-top": "10px" } }, [
                _c(
                  "button",
                  {
                    staticClass: "fMrxFb",
                    staticStyle: {
                      height: "auto",
                      padding: "0px",
                      color: "rgba(0, 0, 0, 0.5)",
                    },
                    attrs: { type: "button" },
                    on: { click: _vm.handleClose },
                  },
                  [
                    _c(
                      "svg",
                      {
                        staticClass: "svg-inline--fa fa-arrow-left kQVxkf",
                        attrs: {
                          "aria-hidden": "true",
                          focusable: "false",
                          "data-prefix": "fas",
                          "data-icon": "arrow-left",
                          role: "img",
                          xmlns: "http://www.w3.org/2000/svg",
                          viewBox: "0 0 448 512",
                          position: "left",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            fill: "currentColor",
                            d: "M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z",
                          },
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _c("strong", { staticStyle: { "font-size": "14px" } }, [
                      _vm._v("Back"),
                    ]),
                  ]
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "confirm-and-pay-terms" }, [
            _vm._v(
              "\n          Upon clicking 'Confirm & Pay,' I acknowledge I have read and agreed to the\n          "
            ),
            _c(
              "a",
              {
                staticStyle: { cursor: "pointer" },
                attrs: {
                  href: "",
                  "data-popwin": "//flirtypoint.com/assets/legal/tc.html",
                },
                on: { click: _vm.handlePopup },
              },
              [_vm._v("Terms and Conditions")]
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "ckRXQM" }, [
      _c("input", {
        staticClass: "iyyCkU",
        attrs: {
          id: "credit_card",
          name: "transaction_type",
          type: "radio",
          readonly: "",
          value: "credit_card",
          checked: "",
        },
      }),
      _vm._v(" "),
      _c("span", { staticClass: "cDfmtz" }),
      _vm._v("\n                Card\n              "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }