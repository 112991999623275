var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "safe-top safe-left safe-right safe-bottom",
      on: { touchstart: _vm.onTouchStart },
    },
    [
      _c("TopBar", { attrs: { tokens: _vm.configData.free_tokens } }),
      _vm._v(" "),
      _c("Nuxt", { class: { blurred: _vm.showTokenWindow } }),
      _vm._v(" "),
      _vm.isChatOpened
        ? _c("ChatModalWindow", {
            attrs: {
              "new-username": _vm.chatName,
              "unique-name": _vm.uniqueName,
              "on-close": _vm.closeChat,
              "model-id": _vm.model_id,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showTokenWindow
        ? _c("TokensFlow", {
            attrs: { "has-token": _vm.tokensLeft },
            on: { close: _vm.closeTokenWindow },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }