var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "textfield-wrapper" },
    [
      _c("input", {
        staticClass: "text-field",
        class: {
          success:
            !_vm.isLoading && _vm.isValid && _vm.dirty && _vm.value.length,
          fail: !_vm.isLoading && !_vm.isValid && _vm.dirty,
        },
        style: { color: _vm.textColor },
        attrs: { type: _vm.type, placeholder: _vm.placeholder },
        domProps: { value: _vm.internalValue },
        on: {
          change: function ($event) {
            return _vm.$emit("change", $event.target.value)
          },
          input: _vm.updateInternalValue,
        },
      }),
      _vm._v(" "),
      !_vm.isLoading && !_vm.isValid && _vm.dirty
        ? _c("div", { staticClass: "error-msg" }, [
            _vm._v(_vm._s(_vm.errorMsg)),
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.isLoading && _vm.isValid && _vm.dirty && _vm.value.length
        ? _c("Check", { staticClass: "icon-success" })
        : _vm._e(),
      _vm._v(" "),
      !_vm.isLoading && !_vm.isValid && _vm.dirty
        ? _c("Alert", { staticClass: "icon-alert" })
        : _vm._e(),
      _vm._v(" "),
      _vm.isLoading ? _c("Loader", { staticClass: "icon-loading" }) : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }