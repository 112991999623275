export default httpClient => ({
  getModelsList({ category }) {
    const params = { category }
    return httpClient.get(`sanctum/model`, { params })
  },
  getModelInfo(id) {
    return httpClient.get(`sanctum/model/${id}`)
  },
  like({ id, is_like }) {
    const params = { is_like }
    return httpClient.get(`sanctum/reaction/${id}`, { params })
  },
  getModels({ category }) {
    const params = { category }
    return httpClient.get(`sanctum/getmodels`, { params })
  },
})