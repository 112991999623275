var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c("p", { staticClass: "info confirm" }, [
        _vm._v(_vm._s(_vm.$t("whatsapp.verified"))),
      ]),
      _vm._v(" "),
      _c("Button", {
        attrs: {
          title: _vm.$t("buttons.finish"),
          bgColor: "#428D83",
          textColor: "#fff",
        },
        nativeOn: {
          click: function ($event) {
            return _vm.closeWindow.apply(null, arguments)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }