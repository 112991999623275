var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "slider-wrapper" },
    [
      _c("div", { staticClass: "range" }, [_vm._v(_vm._s(_vm.range[0]))]),
      _vm._v(" "),
      _c("VueSlider", {
        staticClass: "slider",
        style: { width: "70%" },
        attrs: {
          tooltip: "none",
          "enable-cross": false,
          min: _vm.min,
          max: _vm.max,
          "process-style": { backgroundColor: _vm.bgColor },
        },
        on: { change: _vm.handleRange },
        model: {
          value: _vm.range,
          callback: function ($$v) {
            _vm.range = $$v
          },
          expression: "range",
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "range" }, [_vm._v(_vm._s(_vm.range[1]))]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }