<template>
  <div class="select-wrapper">
    <v-select
      class="style-chooser"
      :options="options"
      :clearable="false"
      :style="{ border: borderColor, 'border-radius': '10px' }"
      v-model="value"
      @input="handleSelect"
      :value="currentProperty"
    >
    </v-select>
  </div>
</template>

<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";


export default {
  components: {
    vSelect
  },

  props: {
    options: {
      type: Array,
      default: () => []
    },
    borderColor: {
      type: String,
      default: "none"
    },
    currentProperty:{
      type: String,
      default: ""
    }

  },

  data() {
    return {
      value: null
    };
  },
  created() {
    this.value =this.currentProperty
  },
  methods: {
    handleSelect() {
      this.$emit("select", this.value);
    }
  }
};
</script>

<style lang="scss">
@import "./select.scss";
</style>
