var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.STEPS_ORDER[_vm.stepNumber] === _vm.STEP_NAME.PHONE_NUMBER
        ? _c("PhoneVerifyNumber", {
            on: { step: _vm.handleStep, enterNumber: _vm.setNumber },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.STEPS_ORDER[_vm.stepNumber] === _vm.STEP_NAME.VERIFICATION_CODE
        ? _c("PhoneVerifyCode", { on: { step: _vm.handleStep } })
        : _vm._e(),
      _vm._v(" "),
      _vm.STEPS_ORDER[_vm.stepNumber] === _vm.STEP_NAME.CONFIRMATION
        ? _c("PhoneVerifyConfirm", { on: { close: _vm.closeComponent } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }