<template>
  <div class="slider-wrapper">
    <div class="range">{{ range[0] }}</div>

    <VueSlider
      v-model="range"
      :tooltip="'none'"
      :enable-cross="false"
      :min="min"
      :max="max"
      :style="{ width: '70%' }"
      :process-style="{ backgroundColor: bgColor }"
      class="slider"
      @change="handleRange"
    />

    <div class="range">{{ range[1] }}</div>
  </div>
</template>

<script>
import VueSlider from "vue-slider-component/dist-css/vue-slider-component.umd.min.js";
import "vue-slider-component/dist-css/vue-slider-component.css";
import "vue-slider-component/theme/default.css";
import { AGE_SLIDER_DEFAULTS, COLORS } from "@/utils/constants/index.js";


export default {
  components: {
    VueSlider
  },

  props: {
    min: {
      type: Number,
      default: AGE_SLIDER_DEFAULTS.MIN_AGE
    },
    max: {
      type: Number,
      default: AGE_SLIDER_DEFAULTS.MAX_AGE
    },
    selectedRange: {
      type: Array,
      default: () => AGE_SLIDER_DEFAULTS.RANGE
    },
    bgColor: {
      type: String,
      default: COLORS.ORANGE
    }
  },

  data() {
    return {
      range: this.selectedRange
    };
  },

  methods: {
    handleRange() {
      this.$emit("chosenRange", this.range);
    }
  }
};
</script>

<style lang="scss">
@import "./rangeSelector.scss";
</style>
