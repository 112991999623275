<template>
  <div class="modal__content">
    <div class="modal__content-text mb-m">
      {{ text }}
    </div>
    <TextField
      errorMsg="Incorrect Email"
      :isValid="$v.userInfo.email.$error"
      :dirty="$v.userInfo.email.$dirty"
      v-model.trim="$v.userInfo.email.$model"
      class="space mb-s"
      @input="handleEmailInput"
    />
    <Button title="Verify" @click.native="handleSubmit" />
    <Link />
  </div>
</template>

<script>
import Button from "../Button/Button";
import Link from "../Link/Link";
import TextField from "../TextField/TextField";
import { email, required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";

export default {
  mixins: [validationMixin],
  name: "ModalVerify",
  components: {
    Button,
    Link,
    TextField
  },
  data() {
    return {
      userInfo: {
        email: ""
      }
    };
  },
  validations: {
    userInfo: {
      email: { required, email }
    }
  },
  props: {
    text: {
      type: String,
      default: ""
    }
  },
  methods: {
    handleEmailInput(value) {
      this.userInfo.email = value;
    },
    handleSubmit() {
      this.$v.userInfo.$touch();
      this.errors = this.$v.userInfo.$anyError;

      if (this.errors) return;
    }
  }
};
</script>

<style scoped lang="scss">
@import "./modalverify";
</style>
