export default ({ app }, inject) => {
  // this is Nuxt stuff, in vanilla Vue use just your router intances 
  const { router } = app

  let programmatic = false
  ;(['push', 'replace', 'go', 'back', 'forward']).forEach(methodName => {
    const method = router[methodName]
    router[methodName] = (...args) => {
      programmatic = true
      method.apply(router, args)
    }
  })

  router.beforeEach((to, from, next) => {
    // name is null for initial load or page reload
    // console.log('route beforeEach', to, from, programmatic)    
  if (from.name === null || programmatic) {
      // triggered bu router.push/go/... call
      // route as usual
      next()
    } else {
      // triggered by user back/forward 
      // do not route
      if (from.name !== 'index' || (from.name === 'index' && from.query)) {
        next()
      }
      next(false)
      // if (from.name === 'index') { // && to.name !== 'index') {// && (to.name === 'index' && !from.query)) {
      //   next(confirm("You sure you want to leave?"))
      // } else next()
    }
    programmatic = false // clear flag
  })
}