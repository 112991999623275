<template>
  <div class="model-slider-container">
    <div v-if="!isChatOpened" class="back-button" @click="goBack">
      <span class="material-icons">arrow_back</span>
      <span>{{ $t("main.model.back") }}</span>
    </div>
    <swiper
      ref="mySwiper"
      :options="swiperOptions"
      :class="{ blurred: needConfirmation || showModal || showModalEmail }"
    >
      <swiper-slide v-for="(slide,index) in selectedModel.files" :key="slide.id" class="slide">
        <img :src="slide.path" :alt="slide.alt" :class="{ blurred: (slide.path.includes('.gif') || blurImage[selectedModel.id] === index) && tokensLeft <= 60 }">
        <!-- <img :src="slide.path" v-if="slide.path.indexOf('.png') > -1" :class="{ blurred: blurImage[selectedModel.id] === index && tokensLeft <= 10000 }">
        <video autoplay="true" loop="true" muted="true" playsinline="true" onloadstart="" v-else :class="{ blurred: blurImage[selectedModel.id] === index && tokensLeft <= 10000 }">
          <source :src="slide.path">
        </video> -->
        <nuxt-link v-if="(slide.path.includes('.gif') || blurImage[selectedModel.id] === index) && tokensLeft <= 60" to="/tokens" class="unlock">
          <Key :size="48" fill-color="orange" />
        </nuxt-link>
      </swiper-slide>
      <div slot="pagination" class="swiper-pagination swiper-pagination-black" />
      <div slot="button-prev" class="swiper-button-prev" />
      <div slot="button-next" class="swiper-button-next" />
    </swiper>
    <div v-if="!isChatOpened" class="model-social-buttons">
      <button v-if="isCountryAllowed" class="_social-button" @click="callPhone">
        <!-- <nuxt-link class="_social-button" to="/whatsapp"> -->
        <Phone :size="48" fill-color="#3CCF65" />
        <!-- </nuxt-link> -->
      </button>
      <button class="_social-button blueColor" @click="openChat">
        <!-- <ChatIcon :size="48" :title="$t('like.openchat')" :color="selectedModel.online? '#565DFF' : 'lightgrey'"/> -->
        <ChatIcon :size="48" fill-color="white" />
      </button>
      <button class="social-button2" @click="closePhoneVerify">
        <img src="@/assets/img/live_stream.png">
      <!-- <nuxt-link class="_social-button" to="/whatsapp"> -->
        <!-- <WhatsApp :size="48" :title="$t('buttons.whatsapp')"/> -->
      <!-- </nuxt-link> -->
      </button>
      <!-- <Button class="chat-button" @click.native="openChat" :disabled="!selectedModel.online" :title="$t('toast.btn')" /> -->
    </div>
    <WhatsAppFlow v-if="needConfirmation" @close="closePhoneVerify" @wnd_close="closePhoneVerify" />
    <!-- <ContentModal :text="$t('modal.phone.text')" :buttonTitle="$t('modal.phone.button')" v-if="showModal" @submit="gotoOffer" @close="closeModal"/> -->
    <ContentModalEmailVerify
      v-if="showModalEmail"
      text="modal.email.text_profile"
      btn-title="profile.verify"
      :email="profileData.email"
      @close-modal="handleCloseModalEmail"
      @click-modal="showModalEmail = false"
    />

    <ChatModalWindow
      v-if="isChatOpened"
      :on-close="closeChat"
      :new-username="selectedModel.name"
      :unique-name="uniqueName"
      :model-id="selectedModel.id"
    />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import Phone from 'vue-material-design-icons/Phone.vue';
import Key from 'vue-material-design-icons/Key.vue';
import ChatIcon from 'vue-material-design-icons/ChatProcessingOutline.vue';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
// import Message from '@/assets/Icons/Message/Message';
// import WhatsApp from '@/assets/Icons/WhatsApp/WhatsApp';
import image from '@/assets/images/model-image.png';
// import BackArrow from '@/assets/Icons/Back/back.svg';
import ChatModalWindow from '@/components/ChatModalWindow/ChatModalWindow';
import 'swiper/css/swiper.min.css';
import { Namespaces } from '@/utils/constants/store.js';
// import ToastMessage from '@/components/Toast/ToastMessage';
// import Button from '@/components/Button/Button.vue';

const {
  mapActions: SelectedModelActions,
  mapGetters: SelectedModelGetters
} = createNamespacedHelpers(Namespaces.SelectedModel);
const {
  mapActions: profileActions,
  mapGetters: profileGetters
} = createNamespacedHelpers(Namespaces.Profile);
const {
  mapActions: modelsActions
  // mapGetters: modelsGetters
} = createNamespacedHelpers(Namespaces.Models);
export default {
  name: 'ModelSlider',
  components: {
    // WhatsApp,
    // BackArrow,
    // Message,
    Swiper,
    SwiperSlide,
    ChatModalWindow,
    Phone,
    Key,
    // ToastMessage,
    ChatIcon
    // Button,
  },
  props: {
    modelId: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      isChatOpened: false,
      needConfirmation: false,
      uniqueName: '',
      isCountryAllowed: false,
      showModal: false,
      showModalEmail: false,
      showedModalEmail: false,
      msgInterval: 2,
      image,
      blurImage: { 72: 5, 70: 1, 69: 3, 67: 1, 66: 5, 64: 3, 63: 5, 61: 3, 60: 4, 59: 7, 58: 4, 53: 4, 52: 6, 50: 3, 49: 2, 48: 5, 47: 4 },
      swiperOptions: {
        pagination: {
          el: '.swiper-pagination'
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      }
      // selectedModelfiles: null,
    };
  },
  computed: {
    ...SelectedModelGetters(['selectedModel']),
    ...profileGetters(['userId', 'profileData', 'tokensLeft', 'phonePin', 'country'])
  },
  async created () {
    await this.getModelDetails(this.modelId);
    // this.selectedModelfiles = this.selectedModel.files.filter( f => f.path.indexOf('.png') > -1);
  },
  mounted () {
    // await this.getUser()
    // console.log('mounted', this.selectedModel)
    // if (this.$store.state.chattedWith.indexOf(this.selectedModel.id) === -1 && this.selectedModel.online === 1) {
    //   this.timer = setInterval(() => {
    //     this.timerCheck()
    //   }, 1000)
    // }
    this.isCountryAllowed = false;// ["germany", "austria"].indexOf(this.country.toLowerCase()) !== -1
    this.showedModalEmail = this.$storage.getState('showedModalEmail') || 0;
    // setTimeout(()=>{
    //   this.startToastMessage(this.$t('toast.text_message'))
    // }, 7*1000)
  },
  beforeDestroy () {
    // console.log('beforeDestroy')
  },
  methods: {
    ...profileActions(['getUser', 'getPIN']),
    ...SelectedModelActions(['getModelDetails']),
    ...modelsActions(['getModelsList']),
    openChat () {
      this.$router.push({ path: '/', query: { chatid: this.selectedModel.id } });
      // this.getUser()
      // this.uniqueName = `${ this.selectedModel.name }+${ this.userId }`
      // this.isChatOpened = true
    },
    closeChat () {
      this.isChatOpened = false;
    },
    closePhoneVerify () {
      // console.log('close')
      setTimeout(() => {
        this.needConfirmation = false;
        this.$router.push({ path: '/', query: { chatid: this.selectedModel.id, cam: 1 } });
        // this.$router.push({ path: '/tokens' });
      }, 100);
    },
    emailVerify () {
      if (!this.profileData.email_verified_at && this.$storage.getLocalStorage('_m1') === 'bbe14ec0-5260-42ea-b12e-7f7dc560d1f6' && !this.$store.state.emailSent) {
        if (!this.profileData.email.includes(this.$store.state.fakeDomain)) {
          this.showModalEmail = 1;
        } else {
          this.$router.push({ name: 'tokens' });
        }
      } else {
        this.$router.push({ name: 'tokens' });
      }
    },
    openPhoneVerify () {
      if (this.profileData.phoneVerified) {
        this.$router.push({ path: '/', query: { chatid: this.selectedModel.id } });
        return;
      }
      if (this.tokensLeft <= 100) {
        this.emailVerify();
        return;
      }
      this.$router.push({ name: 'tokens' });
      // console.log('open')
      this.needConfirmation = !this.needConfirmation;
    },
    async callPhone () {
      if (this.profileData.phoneVerified) {
        this.$router.push({ path: '/', query: { chatid: this.selectedModel.id } });
        return;
      }
      if (this.tokensLeft <= 100) {
        this.emailVerify();
        return;
      }
      await this.getPIN();
      const cid = this.$storage.getCookie('_cid');
      const afid = this.$storage.getCookie('_afid');
      if (cid) {
        await this.$api.post('sanctum/phone-click', { clickId: cid, modelId: this.selectedModel.id, affId: afid });
      }
      // console.log('makeCall pin: ', this.phonePin)
      setTimeout(() => {
        this.$router.push({ path: '/tokens' });
      }, 1500);
      window.location.href = `tel:+4932219214969${this.phonePin.toString()}`;
    },
    gotoOffer () {
      this.showModal = false;
      return this.emailVerify();
      // this.$router.push({ name: 'tokens' });
    },
    closeModal () {
      this.showModal = false;
    },
    handleCloseModalEmail () {
      this.showedModalEmail++;
      this.$storage.setState('showedModalEmail', this.showedModalEmail);
      this.showModalEmail = 0;
      if (this.showedModalEmail >= 3) {
        this.$router.push({ name: 'tokens' });
      }
    },
    goBack () {
      this.$router.push({ name: 'index' });
    }
  }
};
</script>

<style lang='scss'>
@import './modelSlider.scss';
.back-arrow {
  position: absolute;
  top: 0.6rem;
  left: 0.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  height: 2rem;
  width: 2rem;
  z-index: 2;
  background: rgba(241, 242, 243, 0.7);
  -webkit-filter: invert(100%);
  filter: invert(100%);
}
.green {
  background-color: rgba(0, 242, 0)!important;
 }
</style>
<style lang="scss" scoped>
.blurred {
  filter: blur(15px);
  pointer-events: none;
}
.unlock {
  outline: none;
  background-color: whitesmoke;
  box-shadow: 0px 3px 14px rgba(0, 0, 0, 0.09);
  width: 4.375rem;
  height: 4.375rem;
  display: flex;
  position: fixed;
  top: 50%;
  margin-top: calc(-1 * var(--swiper-navigation-size) / 2);
  margin-left: calc(50% - 2.15rem);
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 50%;
}
.chat-button {
  display: flex;
  width: 50%;
  // height: 2rem;
  // top: 1rem;
  position: relative;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.blueColor {
  background: #1D98F7!important;
}
.swiper-container {
  padding-bottom: 25px;
}
.swiper-container-horizontal > .swiper-pagination {
  position: absolute;
  bottom: 0;
}
.social-button2 {
  // border-radius: 50%;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  width: 6rem;
  height: 4rem;
  // border: none;
  // box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.16);
  background-color: transparent;
  // border: 1px solid lightgray;
  margin-right: 1px;
  margin-left: 1px;
}
</style>
