var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "wrapper" }, [
    _c(
      "div",
      { staticClass: "left-side" },
      [
        _c(
          "nuxt-link",
          { staticClass: "left-side-name", attrs: { to: "/profile" } },
          [
            _c("Account", {
              staticClass: "icon",
              attrs: { "fill-color": "#A2B2D1", title: "Profile", size: 32 },
            }),
            _vm._v(" "),
            _c("span", { staticClass: "left-side-text" }, [
              _vm._v(
                _vm._s(
                  _vm.profileData.email.substring(
                    0,
                    _vm.profileData.email.lastIndexOf("@")
                  ) || ""
                )
              ),
            ]),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "right-side" },
      [
        _c(
          "nuxt-link",
          { staticClass: "icon__wrap", attrs: { to: "/chats" } },
          [
            _c("CommentMultiple", {
              staticClass: "icon",
              attrs: { "fill-color": "#A2B2D1", title: "All Chats", size: 32 },
            }),
            _vm._v(" "),
            _vm.unread
              ? _c("span", { staticClass: "alert alert-msg" }, [
                  _vm._v(_vm._s(_vm.unread)),
                ])
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "right-side-tokens" },
          [
            _c(
              "nuxt-link",
              { staticClass: "icon__wrap", attrs: { to: "/tokens" } },
              [
                _c("Toll", {
                  attrs: { "fill-color": "#A2B2D1", title: "Tokens", size: 32 },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }