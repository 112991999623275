var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "basic-vue-chat", attrs: { id: "chatpage" } },
    [
      _c(
        "section",
        { staticClass: "window" },
        [
          _c("div", { staticClass: "back", on: { click: _vm.handleClose } }, [
            _c("span", { staticClass: "material-icons" }, [
              _vm._v("arrow_back_ios"),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "header",
            {
              staticClass: "window__header__container",
              class: {
                blurred:
                  _vm.needConfirmation |
                  _vm.noTokens |
                  _vm.showModal |
                  _vm.showInputEmail,
              },
            },
            [
              _c(
                "div",
                { staticClass: "icon-container" },
                [
                  _c("nuxt-link", { attrs: { to: `/girls/${_vm.modelId}` } }, [
                    _c("img", { attrs: { src: _vm.modelIcon, alt: "avatar" } }),
                    _vm._v(" "),
                    _vm.isOnline
                      ? _c("div", { staticClass: "status-circle" })
                      : _vm._e(),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _vm._t("header", function () {
                return [_vm._v("\n        " + _vm._s(_vm.title) + "\n      ")]
              }),
              _vm._v(" "),
              _c(
                "nuxt-link",
                { staticClass: "mail_icon", attrs: { to: "/chats" } },
                [
                  _c("CommentMultiple", {
                    staticClass: "icon",
                    attrs: {
                      "fill-color": "white",
                      title: "All Chats",
                      size: 40,
                    },
                  }),
                  _vm._v(" "),
                  _vm.unread
                    ? _c("span", { staticClass: "alert alert-msg" }, [
                        _vm._v(_vm._s(_vm.unread)),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "section",
            {
              staticClass: "window__messages__container",
              class: {
                blurred:
                  _vm.needConfirmation |
                  _vm.noTokens |
                  _vm.showModal |
                  _vm.showInputEmail,
              },
              attrs: { id: "window__messages__container" },
            },
            [
              _vm._t("default", function () {
                return [
                  _c("messages-list", {
                    staticClass: "messages-list",
                    attrs: { feed: _vm.msglist, "author-id": _vm.authorId },
                  }),
                ]
              }),
              _vm._v(" "),
              _vm.isTyping
                ? _c("div", { staticClass: "typing" }, [
                    _c("span"),
                    _vm._v(" "),
                    _c("span"),
                    _vm._v(" "),
                    _c("span"),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.isLoading
                ? _c("Loader", { staticClass: "icon-loading" })
                : _vm._e(),
            ],
            2
          ),
          _vm._v(" "),
          _vm.sayHi
            ? _c("WelcomeChat", {
                class: {
                  blurred:
                    _vm.needConfirmation |
                    _vm.noTokens |
                    _vm.showModal |
                    _vm.showInputEmail,
                },
                attrs: { "model-name": _vm.title },
                on: { sendHi: _vm.sendHi },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.needConfirmation
            ? _c("WhatsAppFlow", { on: { close: _vm.closePhoneVerify } })
            : _vm._e(),
          _vm._v(" "),
          _vm.noTokens
            ? _c("TokensTopUp", { on: { close: _vm.handleCloseTopUp } })
            : _vm._e(),
          _vm._v(" "),
          _vm.showModal
            ? _c("ContentModalEmailVerify", {
                attrs: { email: _vm.profileData.email },
                on: {
                  "close-modal": _vm.handleCloseModalEmail,
                  "click-modal": function ($event) {
                    _vm.showModalEmail = false
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showInputEmail
            ? _c("ModalInputEmailVerify", {
                attrs: { "model-name": _vm.title },
                on: {
                  "close-modal": _vm.handleCloseModalEmail,
                  "click-modal": _vm.handleEmailInput,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "window__input__container",
              class: {
                blurred:
                  _vm.needConfirmation |
                  _vm.noTokens |
                  _vm.showModal |
                  _vm.showInputEmail,
              },
            },
            [
              _vm._t("input-container", function () {
                return [
                  _c("input-container", {
                    ref: "inputText",
                    attrs: { title: _vm.$t("buttons.send") },
                    on: {
                      input: _vm.onInputMsg,
                      newOwnMessage: _vm.onNewOwnMessage,
                    },
                  }),
                ]
              }),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.btnClicked
        ? _c(
            "Modal",
            { staticClass: "camclick", on: { close: _vm.closeModal } },
            [
              [
                _c(
                  "div",
                  { staticClass: "modal-wrapper-img" },
                  [_c("Ok", { attrs: { size: 96, "fill-color": "#57CE70" } })],
                  1
                ),
                _vm._v(" "),
                _c("div", {
                  staticClass: "info",
                  domProps: { innerHTML: _vm._s(_vm.modalInfo) },
                }),
                _vm._v(" "),
                _c("Button", {
                  attrs: {
                    title:
                      _vm.btnClicked === 3
                        ? _vm.$t("modal.camInvite.button")
                        : _vm.$t("like.close"),
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.closeModal.apply(null, arguments)
                    },
                  },
                }),
              ],
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }