var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.fromModel ? "message--foreign" : "message--own" },
    [
      _c("div", { staticClass: "message__contents" }, [
        _vm.imageUrl
          ? _c("img", {
              staticStyle: { width: "100%" },
              attrs: { src: _vm.imageUrl, alt: "" },
            })
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "message_text js-message_text before_footer" },
          [
            _vm.fromModel
              ? _c("span", { domProps: { innerHTML: _vm._s(_vm.contents) } })
              : _c("span", [_vm._v(_vm._s(_vm.contents))]),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "message_footer compact js-message_footer" }, [
          _c("div", { staticClass: "message_info short js-message_info" }, [
            _c("span", { staticClass: "message_meta" }, [
              _c("time", { staticClass: "time", attrs: { datetime: "date" } }, [
                _vm._v(_vm._s(_vm.formatDate(_vm.date))),
              ]),
              _vm._v(" "),
              !_vm.fromModel
                ? _c("div", { staticClass: "message-status" }, [
                    _vm.sent
                      ? _c("i", { staticClass: "symbol-message-read" })
                      : _vm._e(),
                  ])
                : _vm._e(),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }