<template>
  <div :class="fromModel ? 'message--foreign' : 'message--own'">
    <div class="message__contents">
      <img
        v-if="imageUrl"
        :src="imageUrl"
        alt=""
        style="width: 100%">
      <div class="message_text js-message_text before_footer">
        <span v-html="contents" v-if="fromModel"></span><span v-else>{{ contents }}</span>
      </div>
      <div class="message_footer compact js-message_footer">   
        <div class="message_info short js-message_info">
          <span class="message_meta"><time class="time" datetime="date">{{ formatDate(date) }}</time>
            <div class="message-status" v-if="!fromModel">
              <i class="symbol-message-read" v-if="sent"></i>
            </div>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const moment = require('moment')

export default {
  name: 'Messages',
  components: {
  },
  props: {
    date: {
      type: String,
      default: '16:30:00',
      required: false
    },
    imageUrl: {
      type: String,
      default: '',
      required: false
    },
    contents: {
      type: String,
      default: '',
      required: false
    },
    fromModel: {
      type: Boolean,
      default: false,
      required: false 
    },
    sent: {
      type: Boolean,
      default: false,
      required: false 
    }
  },
  data: () => ({
    compact: 1,
  }),
  mounted () {
    // console.log('Component mounted')
    // const footerEl = this.ge1('.js-message_footer.compact', this.$el)//document.querySelector("time[datetime]")
    // this.adjustMeta(this.ge1('.js-message_footer.compact', this.$el))
    // console.log('rect', footerEl)
    this.getInfoWidth(this.$el.querySelector(".js-message_info"))
  },
  beforeDestroy () { 
    // console.log('Component beforeDestroy')
  },
  methods: {
    formatDate (date) {
      return moment(date).format('LT')
    },
    getInfoWidth (infoEl) {
      if (infoEl) {
        // console.log('rect', infoEl.offsetWidth)
        const shadowEl = document.createElement('span')
        shadowEl.style.display = 'inline-block'
        shadowEl.style.width = infoEl.offsetWidth + 'px'
        this.$el.querySelector(".js-message_text").appendChild(shadowEl)
      }
    },
    adjustMeta (footerEl) {
      // console.log('rect', messageEl)
        // console.log('rect', this.ge1(".js-message_footer.compact", messageEl))
      // return
      // const footerEl = this.ge1(".js-message_footer.compact", footerEl)
      // if (!footerEl) return
      const textEl = footerEl.previousElementSibling
      const text_rect = textEl.getBoundingClientRect();      
      if (textEl && !textEl.__inited && textEl.classList.contains("js-message_text")) {
        var tnode = textEl.firstChild;
        while (tnode && tnode.nodeType == tnode.ELEMENT_NODE) {
          tnode = tnode.firstChild;
        }
        if (tnode) {    
          var r = document.createRange();
          r.setStart(textEl, 0)
          r.setEnd(textEl, 1)
          // r.setStart(tnode, tnode.length-1)
          // r.setEnd(tnode, tnode.length)
          var char_rect = r.getBoundingClientRect();      
          // var time_rect = footerEl.firstChild.getBoundingClientRect();      
          textEl.__inited = true
          // console.log('rect time', r.toString(), char_rect.right, time_rect.left, time_rect.left - char_rect.right)
          if (Math.abs(char_rect.right - text_rect.right) > 3) {
          // if (Math.abs(time_rect.left - char_rect.right) > 50) {
          // if ( time_rect.left - char_rect.right > 50 ) {
            // var infoEl = footerEl.querySelector(".js-message_info")
            var infoEl = this.ge1(".js-message_info", footerEl);
            if (infoEl) {
              console.log('rect', infoEl.offsetWidth)
              var shadowEl = document.createElement('span');
              shadowEl.style.display = 'inline-block';
              shadowEl.style.width = infoEl.offsetWidth + 'px';
              textEl.appendChild(shadowEl);
              // console.log('compact', this.compact)
              // this.spanWidth = infoEl.offsetWidth
              this.compact = 1
              return true
            }
          }          
        }
        return false
      }
    },
    ge1(el, context) {
      if (typeof el === 'string') {
        // console.log('g1 1')
        return (this.ge1(context) || document).querySelector(el);
      } else if (el instanceof Node || el instanceof Window) {
        // console.log('g1 2')
        return el;
      } else if (el instanceof NodeList) {
        // console.log('g1 3')
        return el.item(0);
      } else if (el instanceof Array) {
        // console.log('g1 4')
        return el[0];
      } else if (el) {
        console.warn('unknown type of el', el);
        return el;
      }
      return null;
    },
  }
}
</script>

<style>
.message_footer {
  font-size: 14px;
  line-height: 19px;
  margin: 5px 0 0;
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.message_info {
  text-align: right;
}
.message_meta {
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
  margin-left: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 95%;
  max-width: calc(100% - 10px);
  display: inline-flex;
  justify-content: flex-end;
  height: 1.25rem;
  bottom: 0;
  right: 0;
}
.message_meta .time {
  font-size: 12px;
  font-weight: 500;
  margin-right:.1875rem;
}
.message_info.short {
  float: right;
}
.message_text.before_footer + .message_footer {
  margin-top: -14px;
}
.message_text.before_footer + .message_footer .message_info {
  position: relative;
}
.message-status {
  width: 1.1875rem;
  height: 1.1875rem;
  overflow: hidden;
  display: inline-block; 
  line-height: 1;
  font-size: 1.1875rem;
}
.message-status i {
  background:rgba(0,0,0,0);
}
@font-face {
  font-family: "icomoon";
  src: url(~@/assets/fonts/icomoon.woff2) format("woff2"), url(~@/assets/fonts/icomoon.woff) format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block
}
[class^=symbol-], [class*=" symbol-"] {
  font-family: "icomoon" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}
.symbol-message-succeeded:before {
  content:""
}
.symbol-message-read:before {
  content:""
}
.symbol-message-pending:before {
  content:""
}
.symbol-message-failed:before {
  content:""
}
</style>
