var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "modal__content" },
    [
      _c("div", { staticClass: "modal__content-text mb-m" }, [
        _vm._v("\n    " + _vm._s(_vm.text) + "\n  "),
      ]),
      _vm._v(" "),
      _c("TextField", {
        staticClass: "space mb-s",
        attrs: {
          errorMsg: "Incorrect Email",
          isValid: _vm.$v.userInfo.email.$error,
          dirty: _vm.$v.userInfo.email.$dirty,
        },
        on: { input: _vm.handleEmailInput },
        model: {
          value: _vm.$v.userInfo.email.$model,
          callback: function ($$v) {
            _vm.$set(
              _vm.$v.userInfo.email,
              "$model",
              typeof $$v === "string" ? $$v.trim() : $$v
            )
          },
          expression: "$v.userInfo.email.$model",
        },
      }),
      _vm._v(" "),
      _c("Button", {
        attrs: { title: "Verify" },
        nativeOn: {
          click: function ($event) {
            return _vm.handleSubmit.apply(null, arguments)
          },
        },
      }),
      _vm._v(" "),
      _c("Link"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }