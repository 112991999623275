var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "backdrop",
      on: {
        click: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.handleClose.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "modal-wrapper",
          on: {
            click: function ($event) {
              if ($event.target !== $event.currentTarget) return null
              return _vm.handleClose.apply(null, arguments)
            },
          },
        },
        [
          _c("div", { staticClass: "close", on: { click: _vm.handleClose } }),
          _vm._v(" "),
          _c("div", { staticClass: "content" }, [_vm._t("default")], 2),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }