<template>
  <Transition name="fade">
    <div>
      <div class="modal__overlay"></div>
      <div
        v-if="showing"
        class="modal__wrap"
      >
        <slot/>
      </div>
    </div>
  </Transition>
</template>

<script>
export default {
  name: "ModalWrap",
  props: {
    showing: {
      required: true,
      type: Boolean
    }
  },
  methods: {
    close() {
      this.$emit('close');
    }
  }
}
</script>

<style scoped lang="scss">
@import "./modalwrap";
</style>
