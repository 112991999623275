import { render, staticRenderFns } from "./PhoneVerifyFlow.vue?vue&type=template&id=781d6f20&scoped=true"
import script from "./PhoneVerifyFlow.vue?vue&type=script&lang=js"
export * from "./PhoneVerifyFlow.vue?vue&type=script&lang=js"
import style0 from "./PhoneVerifyFlow.vue?vue&type=style&index=0&id=781d6f20&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "781d6f20",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('781d6f20')) {
      api.createRecord('781d6f20', component.options)
    } else {
      api.reload('781d6f20', component.options)
    }
    module.hot.accept("./PhoneVerifyFlow.vue?vue&type=template&id=781d6f20&scoped=true", function () {
      api.rerender('781d6f20', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/PhoneVerifyFlow/PhoneVerifyFlow.vue"
export default component.exports