<template>
  <div class="slider-buttons">
    <!-- <button class="other-button parent" @click="messageHandler">
      <Message :size="64" color="#565DFF" />
    </button> -->

    <!-- <div class="alert alert-msg" v-if="notification">
      {{ notification }}
    </div> -->

    <div class="option-wrap">
      <button class="option-button blueColor" @click="messageHandler">
        <!-- <Close :size="48"/> -->
        <ChatIcon :size="48" fill-color="white" />
      </button>

      <button class="option-button2" :disabled="!isOnline" @click="handleClick">
        <img src="@/assets/img/live_stream.png" aria-valuetext="cam">
        <!-- <Message :size="48" :color="isOnline ? '#565DFF' : 'lightgrey'"/> -->
        <!-- <ChatIcon :size="48" :fillColor="isOnline ? 'white' : 'lightgrey'"/> -->
      </button>

      <button class="option-button" @click="handleClick">
         <Phone :size="48" fill-color="#3CCF65" />
      </button>
    </div>

    <!-- <button class="other-button" @click="whatsappHandler">
      <WhatsApp :size="64" />
    </button> -->
  </div>
</template>

<script>
import Phone from 'vue-material-design-icons/Phone.vue';
import ChatIcon from 'vue-material-design-icons/ChatProcessingOutline.vue';
import { Close, Heart, Message, WhatsApp } from '@/assets/Icons/';

export default {
  components: {
    Message,
    Close,
    Heart,
    WhatsApp,
    ChatIcon,
    Phone
  },
  props: {
    notification: {
      type: Number,
      default: 0
    },
    isOnline: {
      type: Boolean,
      default: true
    },
    modelId: {
      type: Number,
      default: 0
    }
  },

  methods: {
    likeHandler () {
      this.$emit('like');
    },
    skipHandler () {
      this.$emit('skip');
    },
    messageHandler () {
      this.$emit('message');
    },
    whatsappHandler () {
      this.$emit('whatsapp');
    },
    handleClick (e) {
      // console.log('e', e.target.ariaValueText, this.modelId);
      // this.$router.push({ name: 'tokens' });
      this.$emit('btnclick', { cam: e.target.ariaValueText === 'cam' ? 1 : 2 });
      // this.$router.push({ path: '/', query: { chatid: this.modelId, cam: e.target.ariaValueText === 'cam' ? 1 : 2 } });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "./footer";
.blueColor {
  background: #1D98F7!important;
}
.option-button2  {
  // border-radius: 50%;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  width: 6rem;
  height: 4rem;
  // border: none;
  // box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.16);
  background-color: transparent;
  // border: 1px solid lightgray;
  margin-right: 1px;
  margin-left: 1px;
}
</style>
