export const state = () => ({
  isChatOpened: false,
  ChatModel: false,
  justPaid: false,
  session_id: null,
  chattedWith: [],
  visited: [],
  emailSent: false,
  camInvite: { showed: false, modelName: '' },
  stopMsg: false,
  fakeDomain: 'fake.net'
});

export const mutations = {
  toggleChat (state, value) {
    // console.log('isChatOpened:', value)
    state.isChatOpened = value;
  },
  setChatModel (state, value) {
    state.ChatModel = value;
  },
  setPaid (state, value) {
    state.justPaid = value;
  },
  setSessionId (state, value) {
    state.session_id = value;
  },
  setEmailSent (state, value) {
    state.emailSent = value;
  },
  setStopMsg (state, value) {
    state.stopMsg = value;
  },
  setCamInvite (state, payload) {
    state.camInvite = { ...payload };
    // state.camInvite.modelName = payload.modelName;
    // console.log('setCamInvite:', payload, state.camInvite);
  },
  setChattedWith (state, value) {
    if (!state.chattedWith.includes(value)) {
      state.chattedWith.push(value);
      if (typeof window !== 'undefined') {
        localStorage.setItem('chatted', JSON.stringify(state.chattedWith));
      }
    }
  },
  initChatted (state, payload) {
    state.chattedWith = payload;
  },
  initVisited (state, payload) {
    state.visited = payload;
  },
  setVisited (state, value) {
    if (!state.visited.includes(value)) {
      state.visited.push(value);
      if (typeof window !== 'undefined') {
        localStorage.setItem('visited', JSON.stringify(state.visited));
      }
    }
  }
};

export const getters = {
  getChattedWith: state => state.chattedWith
};

export const actions = {
  initVisited ({ commit, state }) {
    if (localStorage.getItem('visited')) {
      commit('initVisited', JSON.parse(localStorage.getItem('visited')));
    }
    if (localStorage.getItem('chatted')) {
      commit('initChatted', JSON.parse(localStorage.getItem('chatted')));
    }
  }
};
