<template>
  <div class="modal__content" @click="handleClose">
    <div class="modal__content-img mb-s"><Ok :size=96 fillColor="#57CE70"/></div>
    <div class="modal__content-text mb-m" @click.stop>
      {{ text }}
    </div>
    <button class="next-button" type="button" @click="handleClose">{{ $t('like.close') }}</button>
  </div>
</template>

<script>
import Button from "@/components/Button/Button"
import Ok from "vue-material-design-icons/CheckCircleOutline.vue"

export default {
  components: {
    Button,
    Ok
  },
  props:{
    text: {
      type: String,
      default: 'Payment successful!'
    }
  },
  data () {
    return {
    }
  },
  methods: {
    handleClose(info) {
      this.$emit("close")
    }
  }
};
</script>

<style lang="scss" scoped>
@import "./PurchaseOk";
</style>
