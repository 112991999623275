<template>
  <div class="offer-wrapper">
    <div class="offer-wrapper-top">
      <Icon :color="item.color" :iconType="item.iconType" />

      <p>
        {{ $t("tokens.buy") }}
        <span class="highlight">
          {{ item.count }} {{ $t("tokens.tokens") }}
        </span>
        {{ $t("tokens.only") }}
        {{ $t("tokens.for") }}
        <span class="highlight">${{ item.price }}</span>
      </p>
    </div>
    <Button :title="$t('tokens.btn')" @click.native="handleClick"/>
  </div>
  
</template>

<script>
import Icon from "@/components/TokenOfferIcon/TokenOfferIcon"
import Button from "@/components/Button/Button"

export default {
  components: {
    Icon,
    Button
  },
  props: {
    item: {
      type: Object
    },
    icon: {
      type: String,
      default: ""
    },
  },
  data: () => ({
  }),
  methods: {
    async handleClick () {
      this.$emit('handleClick', this.item.id)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./tokenOffer.scss";
</style>
