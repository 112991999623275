var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Modal",
    {
      attrs: { "button-title": _vm.$t("modal.email.button") },
      on: { close: _vm.handleClose },
    },
    [
      !_vm.emailSent
        ? [
            _c("div", { staticClass: "info" }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.$t("modal.email.confirmEmail", {
                      modelName: _vm.modelName,
                    })
                  )
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("input", {
              attrs: {
                id: "input_email",
                type: "email",
                required: "required",
                placeholder: "E-mail",
              },
              domProps: { value: _vm.userEmail },
              on: {
                input: function ($event) {
                  _vm.userEmail = $event.target.value
                },
              },
            }),
            _vm._v(" "),
            _c(
              "button",
              { on: { click: _vm.handleEmail } },
              [
                _c("Loader", {
                  staticClass: "icon-button",
                  staticStyle: { display: "none" },
                }),
                _vm._v("\n      " + _vm._s(_vm.$t("buttons.next")) + "\n    "),
              ],
              1
            ),
          ]
        : [
            _c("div", { staticClass: "info" }, [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("profile.verification")) + "\n    "
              ),
            ]),
            _vm._v(" "),
            _c("button", { on: { click: _vm.handleClick } }, [
              _vm._v("\n      Ok\n    "),
            ]),
          ],
      _vm._v(" "),
      _vm.showError
        ? _c("div", { staticClass: "err-message-wrapper js-err" }, [
            _c("div", { staticClass: "err-message" }, [
              _c("i", { staticClass: "close", on: { click: _vm.hideErr } }),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm.errMessage))]),
            ]),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }