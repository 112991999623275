<template lang="">
  <div class="form-welcome-page">
    <h1 class="title space">{{ $t(welcomeTitle) }}</h1>

    <div class="about-me">
      <span>{{ $t("registration.genderPage.me") }}</span>
      <GenderToggle
        class="space"
        :defaultGender="userInfo.gender"
        @clicked="handleUserToggle"
      />
    </div>

    <div class="looking-for">
      <span>{{ $t("registration.genderPage.partner") }}</span>
      <GenderToggle
        class="space"
        :defaultGender="userInfo.looking_gender"
        :lookfor="true"
        @clicked="handlePartnerToggle"
      />
    </div>
  </div>
</template>

<script>
import GenderToggle from "../GenderToggle/GenderToggle";

export default {
  components: {
    GenderToggle
  },
  props: {
    welcomeTitle: {
      type: String,
      default: "registration.genderPage.title"
    }
  },
  data () {
    return {
      userInfo: {
        gender: "male",
        looking_gender: "female"
      }
    };
  },
  watch: {
    userInfo: {
      handler () {
        this.$emit("formValue", this.userInfo)
      },
      deep: true
    }
  },
  methods: {
    handleUserToggle (value) {
      this.userInfo.gender = value
    },
    handlePartnerToggle (value) {
      this.userInfo.looking_gender = value
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./theFormGender.scss";
</style>
