var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "upload-wrapper" }, [
    !_vm.src.length
      ? _c("img", { attrs: { src: _vm.uploadIcon, alt: "" } })
      : _vm._e(),
    _vm._v(" "),
    _c("img", { attrs: { src: _vm.src, alt: "" } }),
    _vm._v(" "),
    _c("div", { staticClass: "info" }, [
      _c("a", { attrs: { href: "#" }, on: { click: _vm.clickHandler } }, [
        _vm._v(_vm._s(_vm.$t("profile.upload"))),
      ]),
      _vm._v(" "),
      _c("span", [_vm._v(_vm._s(_vm.$t("profile.image")))]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }