var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal" }, [
    _c("div", { staticClass: "back", on: { click: _vm.handleClose } }, [
      _c("span", { staticClass: "material-icons" }, [_vm._v("arrow_back_ios")]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "modal-content" },
      [
        _c("h2", [_vm._v(_vm._s(_vm.title))]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "select" },
          [
            _c("Select", {
              staticClass: "profile_select",
              attrs: {
                options: _vm.options,
                borderColor: "1px solid grey",
                currentProperty: _vm.currentProperty,
              },
              on: { select: _vm.getValue },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("Button", {
          attrs: { title: _vm.$t("profile.btn") },
          nativeOn: {
            click: function ($event) {
              return _vm.handleChange.apply(null, arguments)
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }