const moment = require('moment')

export const state = () => ({
  list: [],
  unread: [],
})

export const mutations = {
  add (state, msg) {
    state.list.push( msg )
    //if (typeof window !== "undefined") localStorage.setItem("messages", btoa(unescape(encodeURIComponent(JSON.stringify(state)))))
    if (typeof window !== "undefined") localStorage.setItem("messages", JSON.stringify(state))
    const audio = new Audio()
    audio.preload = 'auto'
    audio.src = '/audio/ding.mp3'
    audio.play().catch(console.log)    
  },
  addUnread (state, msg) {
    state.unread.push( msg )
    //if (typeof window !== "undefined") localStorage.setItem("messages", btoa(unescape(encodeURIComponent(JSON.stringify(state)))))
    if (typeof window !== "undefined") localStorage.setItem("messages", JSON.stringify(state))
  },
  delUnread (state, list) {    
    state.unread.pop( list )
    //if (typeof window !== "undefined") localStorage.setItem("messages", btoa(unescape(encodeURIComponent(JSON.stringify(state)))))
    if (typeof window !== "undefined") localStorage.setItem("messages", JSON.stringify(state))
  },
  updateUnread (state, msgs) {
    state.unread = msgs
    if (typeof window !== "undefined") localStorage.setItem("messages", JSON.stringify(state))
  },
  deleteItem (id) {
    const index = state.list.findIndex(c => c.id == id)
    state.list.splice(index, 1);
  },
  init (state, msgs) {
    state.list = msgs
    //console.log('list', state.list)
    //if (typeof window !== "undefined") localStorage.setItem("messages_list", JSON.stringify(state.list))
  },
  initializeStore (state, payload) {
    //console.log('initializeStore', payload.list.filter(m => moment(m.created_at).format('DATE') === moment().format('DATE')))
    Object.assign(state, payload)
    state.list = payload.list.filter(m => m.bot === 1)
    //state.list = payload.list.filter(m => moment(m.created_at).format('DATE') === moment().format('DATE'))
    //console.log('list', state.list)
    //if (typeof window !== "undefined") localStorage.setItem("messages", JSON.stringify(state))
    //state = Object.assign(state, payload)
  },
}

export const actions = {
  initializeStore({ commit, state }) {
    if (localStorage.getItem('messages')) {
      commit('initializeStore', JSON.parse(localStorage.getItem('messages')))
//      commit('initializeStore', JSON.parse(decodeURIComponent(escape(window.atob(localStorage.getItem('messages'))))))
      /*this.replaceState(
        Object.assign(state, JSON.parse(localStorage.getItem('messages')))
      ) */
    }
    /*if (localStorage.getItem('messages.list')) {
      commit('init', JSON.parse(localStorage.getItem('messages.list')))
    }
    if (localStorage.getItem('messages.unread')) {
      commit('init', JSON.parse(localStorage.getItem('messages.unread')))
    }*/
  },
}