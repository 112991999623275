import Echo from 'laravel-echo'

window.Pusher = require('pusher-js')

function getHeaders ({ app }) {
  const headers = {}

  return headers
}

export default (ctx, inject) => {
  const options = {"broadcaster":"pusher","authModule":false,"connectOnLogin":false,"disconnectOnLogout":false,"wsHost":"admin.flirtypoint.com","wssPort":6001,"wsPort":6001,"key":"123","forceTLS":true,"encrypted":true,"disableStats":true,"authEndpoint":"https:\u002F\u002Fadmin.flirtypoint.com\u002Fbroadcasting\u002Fauth"}
  options.auth = options.auth || {}
  options.auth.headers = {
    ...options.auth.headers,
    ...getHeaders(ctx)
  }

  const echo = new Echo(options)

  ctx.$echo = echo
  inject('echo', echo)
}
