<template>
  <div class="toast__wrap white__mode">
                <div class="header">
                    <svg
                        class="icon-close"
                        viewBox="0 0 50 45"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M9.15625 6.3125L6.3125 9.15625L22.15625 25L6.21875 40.96875L9.03125 43.78125L25 27.84375L40.9375 43.78125L43.78125 40.9375L27.84375 25L43.6875 9.15625L40.84375 6.3125L25 22.15625Z"
                            fill="#ee0000"
                        />
                    </svg>
                </div>
    <div class="toast__item">
      <div class="toast__img">
        <img :src="img" alt="photo" />
      </div>
      <div class="toast__info">
        <span class="toast__info-name">{{ name }}</span>
        <span class="toast__info-text">{{ text }}</span>
      </div>
    </div>
    <Button :title="buttonTitle" @click.native="handleOpen"/>
  </div>
</template>

<script>
import Button from "../Button/Button";
import {createNamespacedHelpers} from "vuex";
import {Namespaces} from "@/utils/constants/store.js";

const {
  mapActions: profileActions,
  mapGetters: profileGetters
} = createNamespacedHelpers(Namespaces.Profile);


export default {
  name: "ToastMessage",
  components: {
    Button
  },
  props: {
    img: {
      type: String,
      default: ""
    },
    name: {
      type: String,
      default: ""
    },
    text: {
      type: String,
      default: ""
    },
    buttonTitle: {
      type: String,
      default: ""
    },
    handleOpen: {
      type: Function,
      default: () => {}
    }
  },
};
</script>

<style lang="scss">
@import "./toast";
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
 .title {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
 .icon-close {
    display: block;
    margin-left: auto;
    width: 1.25rem;
    height: 1.25rem;
    cursor: pointer;
 }
}
</style>
