<template>
  <div class="textfield-wrapper">
    <input
      :type="type"
      :style="{color:textColor}"
      class="text-field"
      :value="internalValue"
      :placeholder="placeholder"
      @change="$emit('change', $event.target.value)"
      @input="updateInternalValue"
      :class="{
        success: !isLoading && isValid && dirty && value.length,
        fail: !isLoading && !isValid && dirty
      }"
    />
    <div class="error-msg" v-if="!isLoading && !isValid && dirty">{{ errorMsg }}</div>
    <Check class="icon-success" v-if="!isLoading && isValid && dirty && value.length" />
    <Alert class="icon-alert" v-if="!isLoading && !isValid && dirty" />
    <Loader class="icon-loading" v-if="isLoading"/>
  </div>
</template>

<script>
import { Alert, Check } from "@/assets/Icons/"
import Loader from "../Loader/Loader"
import _debounce from 'lodash/debounce'

export default {
  components: {
    Alert,
    Check,
    Loader
  },
  props: {
    errorMsg: {
      type: String,
      default: "Incorrect details"
    },
    value: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: "text"
    },
    isValid: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    dirty: {
      type: Boolean,
      default: false
    },
    textColor: {
      type: String,
      default: "white"
    },
    placeholder: {
      type: String,
      default: ""
    }
  },
  data () {
    return {
      internalValue: this.value,
      touched: false,
    }
  },
  watch: {
    value (value) {
      if (!this.touched) this.internalValue = value
    },
  },
  methods: {
    updateInternalValue (event) {
      // console.log("email", event, event.target.matches(':-webkit-autofill'))
      this.touched = event.target.matches(':-webkit-autofill')
      this.updateValue(event.target.value)
    },
    updateValue: _debounce(function (value) {
      this.touched = false
      this.$emit('input', value)
      this.$emit('update:value', value)
    }, 600),
  },
}
</script>

<style lang="scss" scoped>
@import "./textField.scss";
</style>
