var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "select-wrapper" },
    [
      _c("v-select", {
        staticClass: "style-chooser",
        style: { border: _vm.borderColor, "border-radius": "10px" },
        attrs: {
          options: _vm.options,
          clearable: false,
          value: _vm.currentProperty,
        },
        on: { input: _vm.handleSelect },
        model: {
          value: _vm.value,
          callback: function ($$v) {
            _vm.value = $$v
          },
          expression: "value",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }