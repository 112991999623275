import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _83dfe29a = () => interopDefault(import('../pages/account.vue' /* webpackChunkName: "pages/account" */))
const _5fcc1a74 = () => interopDefault(import('../pages/am4d.vue' /* webpackChunkName: "pages/am4d" */))
const _6063ee0c = () => interopDefault(import('../pages/campaign.vue' /* webpackChunkName: "pages/campaign" */))
const _0e383016 = () => interopDefault(import('../pages/chat-app.vue' /* webpackChunkName: "pages/chat-app" */))
const _808e0dbe = () => interopDefault(import('../pages/chats.vue' /* webpackChunkName: "pages/chats" */))
const _2619ac6f = () => interopDefault(import('../pages/darkpage.vue' /* webpackChunkName: "pages/darkpage" */))
const _241ab7e1 = () => interopDefault(import('../pages/darkpagesimple.vue' /* webpackChunkName: "pages/darkpagesimple" */))
const _f62820aa = () => interopDefault(import('../pages/expired.vue' /* webpackChunkName: "pages/expired" */))
const _6b8c03f6 = () => interopDefault(import('../pages/gettokens.vue' /* webpackChunkName: "pages/gettokens" */))
const _5172b96e = () => interopDefault(import('../pages/notifications.vue' /* webpackChunkName: "pages/notifications" */))
const _5bf79a3c = () => interopDefault(import('../pages/offer.vue' /* webpackChunkName: "pages/offer" */))
const _d5f21fa2 = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _65c70c38 = () => interopDefault(import('../pages/regb.vue' /* webpackChunkName: "pages/regb" */))
const _1bb7fb23 = () => interopDefault(import('../pages/registration.vue' /* webpackChunkName: "pages/registration" */))
const _83bf8200 = () => interopDefault(import('../pages/registration_email.vue' /* webpackChunkName: "pages/registration_email" */))
const _26fa7562 = () => interopDefault(import('../pages/rm7.vue' /* webpackChunkName: "pages/rm7" */))
const _4ce3a23d = () => interopDefault(import('../pages/sf.vue' /* webpackChunkName: "pages/sf" */))
const _4d2a17c2 = () => interopDefault(import('../pages/sk.vue' /* webpackChunkName: "pages/sk" */))
const _4dd331ce = () => interopDefault(import('../pages/sw.vue' /* webpackChunkName: "pages/sw" */))
const _6e976504 = () => interopDefault(import('../pages/tokens.vue' /* webpackChunkName: "pages/tokens" */))
const _21301606 = () => interopDefault(import('../pages/top-up.vue' /* webpackChunkName: "pages/top-up" */))
const _eb16c594 = () => interopDefault(import('../pages/topup.vue' /* webpackChunkName: "pages/topup" */))
const _2d733a9c = () => interopDefault(import('../pages/trial.vue' /* webpackChunkName: "pages/trial" */))
const _0fab607c = () => interopDefault(import('../pages/whatsapp.vue' /* webpackChunkName: "pages/whatsapp" */))
const _4671df00 = () => interopDefault(import('../pages/lp/a.vue' /* webpackChunkName: "pages/lp/a" */))
const _8251dddc = () => interopDefault(import('../pages/lp/ab.vue' /* webpackChunkName: "pages/lp/ab" */))
const _8235aeda = () => interopDefault(import('../pages/lp/ac.vue' /* webpackChunkName: "pages/lp/ac" */))
const _82197fd8 = () => interopDefault(import('../pages/lp/ad.vue' /* webpackChunkName: "pages/lp/ad" */))
const _463980fc = () => interopDefault(import('../pages/lp/c.vue' /* webpackChunkName: "pages/lp/c" */))
const _461d51fa = () => interopDefault(import('../pages/lp/d.vue' /* webpackChunkName: "pages/lp/d" */))
const _459066f0 = () => interopDefault(import('../pages/lp/i.vue' /* webpackChunkName: "pages/lp/i" */))
const _447690dc = () => interopDefault(import('../pages/lp/s.vue' /* webpackChunkName: "pages/lp/s" */))
const _44ff9682 = () => interopDefault(import('../pages/lp/sa.vue' /* webpackChunkName: "pages/lp/sa" */))
const _557b169a = () => interopDefault(import('../pages/lp/sab.vue' /* webpackChunkName: "pages/lp/sab" */))
const _44c7387e = () => interopDefault(import('../pages/lp/sc.vue' /* webpackChunkName: "pages/lp/sc" */))
const _95ef1f70 = () => interopDefault(import('../pages/lp/uk-test.vue' /* webpackChunkName: "pages/lp/uk-test" */))
const _27c57bcc = () => interopDefault(import('../pages/lp/uk-test2.vue' /* webpackChunkName: "pages/lp/uk-test2" */))
const _27a94cca = () => interopDefault(import('../pages/lp/uk-test3.vue' /* webpackChunkName: "pages/lp/uk-test3" */))
const _43e9a5d2 = () => interopDefault(import('../pages/lp/x.vue' /* webpackChunkName: "pages/lp/x" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _381006e5 = () => interopDefault(import('../pages/payment/back/_id.vue' /* webpackChunkName: "pages/payment/back/_id" */))
const _638cf1ac = () => interopDefault(import('../pages/payment/declined/_id.vue' /* webpackChunkName: "pages/payment/declined/_id" */))
const _67930ced = () => interopDefault(import('../pages/chat/_id.vue' /* webpackChunkName: "pages/chat/_id" */))
const _6fc7e388 = () => interopDefault(import('../pages/girls/_id.vue' /* webpackChunkName: "pages/girls/_id" */))
const _54a1035a = () => interopDefault(import('../pages/model/_id.vue' /* webpackChunkName: "pages/model/_id" */))
const _39a789b7 = () => interopDefault(import('../pages/payment/_id.vue' /* webpackChunkName: "pages/payment/_id" */))
const _03078b62 = () => interopDefault(import('../pages/unsubscribe/_token.vue' /* webpackChunkName: "pages/unsubscribe/_token" */))
const _0d6d43db = () => interopDefault(import('../pages/_id/_name.vue' /* webpackChunkName: "pages/_id/_name" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _83dfe29a,
    name: "account"
  }, {
    path: "/am4d",
    component: _5fcc1a74,
    name: "am4d"
  }, {
    path: "/campaign",
    component: _6063ee0c,
    name: "campaign"
  }, {
    path: "/chat-app",
    component: _0e383016,
    name: "chat-app"
  }, {
    path: "/chats",
    component: _808e0dbe,
    name: "chats"
  }, {
    path: "/darkpage",
    component: _2619ac6f,
    name: "darkpage"
  }, {
    path: "/darkpagesimple",
    component: _241ab7e1,
    name: "darkpagesimple"
  }, {
    path: "/expired",
    component: _f62820aa,
    name: "expired"
  }, {
    path: "/gettokens",
    component: _6b8c03f6,
    name: "gettokens"
  }, {
    path: "/notifications",
    component: _5172b96e,
    name: "notifications"
  }, {
    path: "/offer",
    component: _5bf79a3c,
    name: "offer"
  }, {
    path: "/profile",
    component: _d5f21fa2,
    name: "profile"
  }, {
    path: "/regb",
    component: _65c70c38,
    name: "regb"
  }, {
    path: "/registration",
    component: _1bb7fb23,
    name: "registration"
  }, {
    path: "/registration_email",
    component: _83bf8200,
    name: "registration_email"
  }, {
    path: "/rm7",
    component: _26fa7562,
    name: "rm7"
  }, {
    path: "/sf",
    component: _4ce3a23d,
    name: "sf"
  }, {
    path: "/sk",
    component: _4d2a17c2,
    name: "sk"
  }, {
    path: "/sw",
    component: _4dd331ce,
    name: "sw"
  }, {
    path: "/tokens",
    component: _6e976504,
    name: "tokens"
  }, {
    path: "/top-up",
    component: _21301606,
    name: "top-up"
  }, {
    path: "/topup",
    component: _eb16c594,
    name: "topup"
  }, {
    path: "/trial",
    component: _2d733a9c,
    name: "trial"
  }, {
    path: "/whatsapp",
    component: _0fab607c,
    name: "whatsapp"
  }, {
    path: "/lp/a",
    component: _4671df00,
    name: "lp-a"
  }, {
    path: "/lp/ab",
    component: _8251dddc,
    name: "lp-ab"
  }, {
    path: "/lp/ac",
    component: _8235aeda,
    name: "lp-ac"
  }, {
    path: "/lp/ad",
    component: _82197fd8,
    name: "lp-ad"
  }, {
    path: "/lp/c",
    component: _463980fc,
    name: "lp-c"
  }, {
    path: "/lp/d",
    component: _461d51fa,
    name: "lp-d"
  }, {
    path: "/lp/i",
    component: _459066f0,
    name: "lp-i"
  }, {
    path: "/lp/s",
    component: _447690dc,
    name: "lp-s"
  }, {
    path: "/lp/sa",
    component: _44ff9682,
    name: "lp-sa"
  }, {
    path: "/lp/sab",
    component: _557b169a,
    name: "lp-sab"
  }, {
    path: "/lp/sc",
    component: _44c7387e,
    name: "lp-sc"
  }, {
    path: "/lp/uk-test",
    component: _95ef1f70,
    name: "lp-uk-test"
  }, {
    path: "/lp/uk-test2",
    component: _27c57bcc,
    name: "lp-uk-test2"
  }, {
    path: "/lp/uk-test3",
    component: _27a94cca,
    name: "lp-uk-test3"
  }, {
    path: "/lp/x",
    component: _43e9a5d2,
    name: "lp-x"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }, {
    path: "/payment/back/:id?",
    component: _381006e5,
    name: "payment-back-id"
  }, {
    path: "/payment/declined/:id?",
    component: _638cf1ac,
    name: "payment-declined-id"
  }, {
    path: "/chat/:id?",
    component: _67930ced,
    name: "chat-id"
  }, {
    path: "/girls/:id?",
    component: _6fc7e388,
    name: "girls-id"
  }, {
    path: "/model/:id?",
    component: _54a1035a,
    name: "model-id"
  }, {
    path: "/payment/:id?",
    component: _39a789b7,
    name: "payment-id"
  }, {
    path: "/unsubscribe/:token?",
    component: _03078b62,
    name: "unsubscribe-token"
  }, {
    path: "/:id/:name?",
    component: _0d6d43db,
    name: "id-name"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
