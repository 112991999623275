<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="backdrop" @click.self="handleClose">
    <Modal @close="handleClose">
      <template v-if="!confirmed">
        <div class="">
          <Alert :size="96" fill-color="#5DD478" />
        </div>
        <div class="info" v-html="$t('modal.cancelMembership.text')" />
        <div class="button-group">
          <Btn
            :title="$t('buttons.cancel')"
            @click.native="handleClose"
          />
          <Btn
            class="btnCancel"
            :title="$t('buttons.confirm')"
            @click.native="handleConfirm"
          />
        </div>
      </template>
      <template v-else>
        <div class="info">
          {{ $t('modal.cancelMembership.canceled') }}
        </div>
        <Btn
          title="Ok"
          @click.native="handleClose"
        />
      </template>
    </Modal>
  </div>
</template>

<script>
import Btn from '@/components/Button/Button';
import Modal from '@/components/Modal/Modal';
import Alert from '@/assets/Icons/Alert/Alert';

export default {
  name: 'ModalCancelMembership',
  components: {
    Modal,
    Btn,
    Alert
  },
  props: {
    text: {
      type: String,
      default: ''
    },
    btnTitle: {
      type: String,
      default: ''
    },
    email: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      confirmed: false
    };
  },
  methods: {
    async handleConfirm () {
      await this.$api.post('/sanctum/cancelMembership')
        .then((r) => {
          this.confirmed = true;
          // console.log('confirm', r);
        })
        .catch((e) => {
          // console.log('confirm error', e);
          this.handleClose();
        });
      // await this.$UserAPI.verifyEmail(this.$route.fullPath)
      // this.emailSent = true
      // this.$store.commit('setEmailSent', true)
    },
    handleClose () {
      this.$emit('close-modal');
    },
    handleClick () {
      this.$emit('click-modal');
    }
  }
};
</script>

<style lang="scss" scoped>
.backdrop {
  top: 0;
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
}
.button-group {
  width: 100%;
  margin: 10px auto;
  text-align: center;
  font-size: 17px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  align-self: start;
  padding: 0.25em 0.75em;
  gap: 0.2rem;
  // min-width: 10ch;
  min-height: 44px;
  .btnCancel {
    background: red !important;
  }
}
</style>
