<template>
  <Heart :fillColor="color" :size="size" :title="title"/>
</template>

<script>
import Heart from "vue-material-design-icons/Heart.vue";

export default {
  components: {
    Heart
  },
  props: {
    color: {
      type: String,
      default: "#FF8D28"
    },
    title: {
      type: String,
      default: "Like"
    },
    size: {
      type: Number,
      default: 32
    }
  }
};
</script>
