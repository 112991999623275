<template>
  <div class="input-wrapper">
    <input v-model="input" :type="type" />
    <span @click="handleSave">{{ $t('profile.btn') }}</span>
  </div>
</template>

<script>
export default {
  props: {
    type:{
      type:String,
      default:"text"
    },
    value:{
      type:String,
      default:""
    }
  },
  data() {
    return {
      input: ""
    };
  },
  created() {
    this.input = this.value
  },
  methods: {
    handleSave() {
      this.$emit("changed", this.input);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "./input.scss";
</style>
