/* eslint-disable */
if (typeof window !== 'undefined') {
    (function (h, o, t, j, a, r) {
        const id = window.location.hostname.indexOf('flirtypoint.com') > -1 ? '3189784' : window.location.hostname === 'directsex.co' ? '3748132' : '';
        if (!id) return;
        const sv = 6;
        h.hj = h.hj || function () {
            (h.hj.q = h.hj.q || []).push(arguments);
        };
        h._hjSettings = {
            hjid: id,
            hjsv: sv
        };
        a = o.getElementsByTagName('head')[0];
        r = o.createElement('script');
        r.async = 1;
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        a.appendChild(r);
    })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
}