<template>
  <ChatUI
    :initial-feed="feed"
    :new-message="newMessage"
    :is-loading="isLoading"
    :title="newUsername"
    :model-icon="img"
    :model-id="modelId"
    :is-online="selectedModel.online"
    :wa="showWa"
    @newOwnMessage="newOwnMessage"
    @closed="handleClose"
  />
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import ChatUI from '@/components/ChatUI/ChatUI';
import { Namespaces } from '@/utils/constants/store.js';

const moment = require('moment');

const {
  mapActions: chatAction,
  mapGetters: chatGetter
} = createNamespacedHelpers(Namespaces.Chat);

const {
  mapGetters: SelectedModelGetters,
  mapActions: SelectedModelActions
} = createNamespacedHelpers(Namespaces.SelectedModel);

const {
  mapActions: profileActions,
  mapGetters: profileGetters
} = createNamespacedHelpers(Namespaces.Profile);

export default {
  name: 'TelenesChat',
  components: {
    ChatUI
  },
  props: {
    newUsername: {
      type: String,
      default: 'Melo'
    },
    uniqueChatName: {
      type: String,
      default: ''
    },
    modelId: {
      type: Number,
      default: null
    }
  },
  data: () => ({
    username: '',
    selected: false,
    showMessages: false,
    message: null,
    notification: false,
    notificationMsg: '',
    feed: [],
    newMessage: {},
    isLoading: false,
    polling: null,
    showWa: false,
    // justPaid: false,
    // windowHeight: window.innerHeight,
    img: ''
  }),
  computed: {
    ...SelectedModelGetters(['selectedModel']),
    ...profileGetters(['userId', 'tokensLeft', 'profileData']),
    ...chatGetter(['messages'])
  },
  watch: {
    $route (to, from) {
      // console.log('route change to', to)
      // console.log('route change from', from)
      if (from.query.chatid && !to.query.chatid) {
        this.handleClose();
      }
    }
  },
  async created () {
    // console.log('Component created')
    // await this.getModelDetails(this.modelId)
    this.img = this.selectedModel.chat_icon;
    await this.getChatModelMessages(this.modelId);
    this.feed = this.$store.state.chat.messages;
    // console.log('feed: ', this.$store.state.messages.list)
    // if (this.feed.length > 0)
    //   this.$store.commit('setSessionId', this.feed[this.feed.length -1 ]?.session_id)
    // console.log('session_id', this.$store.state.session_id)
    this.$store.commit('setChatModel', this.modelId);
    // this.justPaid = this.$store.state.justPaid && !this.profileData.phone_verified_at
    // console.log('justPaid: ', this.justPaid)
    this.$auth.$storage.setCookie('lastChatModelId', this.modelId);
    // console.log('model: ', this.selectedModel)
    // console.log('modelId: ', this.$store.state.ChatModel)
    // console.log('filter list: ', this.$store.state.messages.list.filter(msg => msg.chat.model_id != this.modelId))
    // Remove from notification messages list all messages for current model
    this.$router.push({
      // path: '/',
      query: {
        chatid: this.modelId
      }
    });
  },
  mounted () {
    // console.log('Component mounted')
    // let element = document.getElementById('chatpage')
    // expect(wrapper.findComponent(ChildComponent).isVisible()).toBe(true)
    // console.log('1 channel opened:', element)
    this.$store.commit('toggleChat', true);
    // element = document.getElementById('chatpage')
    // console.log('2 channel opened:', element)
    // console.log('2 channel opened:', isVisible('basic-vue-chat'))
    // this.$nextTick(() => {
    //   window.addEventListener('resize', this.onResize)
    // })
    // await this.getChatMessages()
    // this.feed = this.messages
    // console.log(this.feed)
    if (this.$route.query.wa) {
      this.showWa = true;
    }
    this.$store.commit('messages/updateUnread', this.$store.state.messages.unread.filter(msg => msg.model_id !== this.modelId));
    // setTimeout(() => {
    //   this.$store.commit('messages/init', this.$store.state.messages.list.filter(msg => msg.model_id != this.modelId))
    // }, 5000)
  },
  beforeDestroy () {
    // window.removeEventListener('resize', this.onResize)
    // console.log('Component beforeDestroy')
    this.$store.commit('chat/clearMessages');
    this.$store.commit('setChatModel', false);
    this.$store.commit('toggleChat', false);
    this.getUser();
  },
  methods: {
    ...chatAction(['getChatModelMessages', 'getMessages', 'setChatModel']),
    ...SelectedModelActions(['getModelDetails']),
    ...profileActions(['getUser']),
    onResize () {
      // console.log('resize ', this.windowHeight, window.innerHeight)
      // this.windowHeight = window.innerHeight
    },
    handleClose () {
      // console.log('Component closed')
      // clearInterval(this.polling)
      // this.$store.commit('chat/clearMessages')
      // this.$store.commit('setChatModel', false)
      // this.$store.commit('toggleChat', false)
      // if (this.$store.state.chat.messages.length > 2) {
      //   await this.newOwnMessage(`${this.profileData.name} left chat`, true)
      // }
      this.$emit('closed');
      if (this.$route.query.chatid) {
        this.$router.replace({ query: {} });
      }
    },
    async newOwnMessage (text, systemMsg = false) {
      // console.log('TEXT', text)
      // if (this.profileData.count_tokens===0) {
      //   this.$router.push('/tokens')
      //   // this.handleClose()
      //   return
      // }
      // console.log('chattedWith', this.$store.state.chattedWith)
      if (this.$store.state.chattedWith.length >= 1 && !this.$store.state.chattedWith.includes(this.modelId) && this.tokensLeft <= 0) {
        this.$router.push({ name: 'tokens' });
      }
      // id: 0,
      const obj = {
        text: `${text}`,
        msg_id: (Math.random().toString(16) + '000000000').substr(2, 8),
        sent: false,
        created_at: moment().utc().format(),
        updated_at: moment().utc().format()
      };
      this.$store.commit('chat/addMessage', obj);
      this.$store.commit('setChattedWith', this.modelId);
      // setTimeout(() => {
      //   this.$store.commit('chat/setSentMessage', obj)
      //   if (this.$store.state.chat.messages.length == 1) {
      //     setTimeout(() => {
      //       obj = {
      //         id: 0,
      //         text: this.$i18n.t('chat.reply', {link: ( this.tokensLeft <= 60 ) ? "/tokens?msg=1" : this.$route.fullPath}),
      //         msg_id: (Math.random().toString(16)+"000000000").substr(2,8),
      //         sent: true,
      //         from: 'model',
      //         created_at: moment().utc().format(),
      //         updated_at: moment().utc().format()
      //       }
      //       this.$store.commit('chat/addMessage', obj)
      //     }, 1500)
      //   }
      // }, 1000)
      // return
      // this.isLoading = true
      // clearInterval(this.polling)
      await this.$api.post('sanctum/telenes/send-message', { message: text, model_id: this.modelId, isSystem: systemMsg })
        .then(() => {
        // console.log(response)
          this.$store.commit('chat/setSentMessage', obj);
          sessionStorage.removeItem('clist');
          sessionStorage.removeItem('cnext');
          if (this.$store.state.chat.messages.length === 1) {
            const cid = this.$storage.getCookie('_cid');
            const afid = this.$storage.getCookie('_afid');
            if (cid) {
              this.$api.post('v1/postback', { clickId: cid, affId: afid, event: 'chat' });
            }
          }
        // if (this.$store.state.chat.messages.length == 3) {
        //   // const cid = this.$storage.getCookie('_cid')
        //   // const afid = this.$storage.getCookie('_afid')
        //   // if (cid) {
        //   //   // this.pixel = `<img src="https://track.flirtypoint.com/conversion.gif?cid=${cid}&et=chat" width="1" height="1"/>`
        //   //   await this.$api.post("v1/postback", { clickId: cid, affId: afid, event: "chat" })
        //   // }
        //   setTimeout(() => {
        //     obj = {
        //       id: 0,
        //       text: this.$i18n.t('chat.reply', { link: "/tokens?msg=1" }),
        //       msg_id: (Math.random().toString(16)+"000000000").substr(2,8),
        //       // sent: true,
        //       from: 'model',
        //       created_at: moment().utc().format(),
        //       updated_at: moment().utc().format()
        //     }
        //     this.$store.commit('chat/addMessage', obj)
        //   }, 1500)
        // }
        // this.getChatModelMessages(this.modelId)
        // this.getChatMessages(response.sessionId)
        // this.polling = setInterval( () => { this.getChatMessages(response.sessionId) }, 10000)
        })
        .catch(() => {
          this.$store.commit('chat/removeMessage', obj.msg_id);
          // console.log(error);
        });
      this.getUser();
      // this.isLoading = false
    },
    async getChatMessages (sessionid = null) {
      // this.feed = []
      this.isLoading = true;
      await this.getMessages();
      for (const msg of this.messages) {
        // Filter messages by selected model
        if (msg.model_id !== this.modelId) { continue; }
        if (msg.text.indexOf('chat started') > 0 || msg.text.includes('Bye-bye')) { continue; }
        const obj = {
          id: msg.from === 'model' ? 1 : 0,
          msg_id: msg.id,
          contents: msg.text,
          date: moment(msg.created_at).fromNow()
        };
        // console.log('getchatmessages ', msg.id, msg.session_id, sessionid)
        if (this.feed.find((obj) => { return obj.msg_id === msg.id; }) === undefined) {
          if (sessionid && msg.session_id === sessionid) {
            // console.log('getchatmessages undefined', msg.id)
            this.newMessage = obj;
          } else {
            // console.log('getchatmessages', msg.id)
            this.feed.push(obj);
          }
        }
      }
      this.isLoading = false;
      this.showMessages = true;
    }
  }
};
</script>

<style scoped>
html,
body {
  background-color: #fff;
  color: #636b6f;
  font-family: 'Nunito', sans-serif;
  font-weight: 200;
  height: 100vh;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  margin: 0;
}
.full-height {
  height: 100vh;
  min-height: -webkit-fill-available;
}
.flex-center {
  align-items: center;
  display: flex;
  justify-content: center;
}
.position-ref {
  position: relative;
}
.top-right {
  position: absolute;
  right: 10px;
  top: 18px;
}
.content {
  text-align: center;
}
.title {
  font-size: 84px;
}
.links > a {
  color: #636b6f;
  padding: 0 25px;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.1rem;
  text-decoration: none;
  text-transform: uppercase;
}
.m-b-md {
  margin-bottom: 30px;
}
</style>
