var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal" }, [
    _c(
      "div",
      { staticClass: "modal-content" },
      [
        _c("h2", [_vm._v(_vm._s(_vm.title))]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "cropper__holder" },
          [
            _c("cropper", {
              ref: "cropper",
              staticClass: "custom-restrictions-cropper",
              attrs: {
                "check-orientation": "",
                src: _vm.image,
                "stencil-component": _vm.$options.components.CircleStencil,
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "button",
            on: {
              click: function ($event) {
                return _vm.$refs.file.click()
              },
            },
          },
          [
            _c("input", {
              ref: "file",
              attrs: { type: "file", accept: "image/*" },
              on: {
                change: function ($event) {
                  return _vm.uploadImage($event)
                },
              },
            }),
            _vm._v("\n      Upload image\n    "),
          ]
        ),
        _vm._v(" "),
        _c("Button", {
          attrs: { title: _vm.$t("profile.btn") },
          nativeOn: {
            click: function ($event) {
              return _vm.handleChange.apply(null, arguments)
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }