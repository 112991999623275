var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "notokens-wrapper" }, [
    _c("div", { staticClass: "close", on: { click: _vm.handleClose } }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "content" },
      [
        _c("div", {
          staticClass: "info",
          domProps: {
            innerHTML: _vm._s(_vm.$t("notifications.noTokens.title")),
          },
        }),
        _vm._v(" "),
        _c("Button", {
          attrs: { title: _vm.$t("notifications.noTokens.next") },
          nativeOn: {
            click: function ($event) {
              return _vm.handleSend.apply(null, arguments)
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }