<template>
  <button
    class="button-wrapper"
    :style="{ background: bgColor, color: textColor }"
    :disabled="disabled"
    :type="type"
  >
    {{ title }}
  </button>
</template>

<script>
import { COLORS } from "@/utils/constants/index.js";

export default {
  props: {
    title: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: "button"
    },
    bgColor: {
      type: String,
      default: COLORS.ORANGE_GRADIENT
    },
    textColor: {
      type: String,
      default: "#000000"
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
@import "./button.scss";
</style>
