<template>
  <div class="checkbox-wrapper">
    <label class="container">
      <input type="checkbox" v-model="checked" @change="handleCheck" />
      <span class="checkmark"></span>

      <span :style="{ color: color }">{{ label }}</span>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ""
    },
    color: {
      type: String,
      default: "#ffff"
    }
  },
  data() {
    return {
      checked: false
    };
  },
  methods: {
    handleCheck() {
      this.$emit("checked", this.checked);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "./checkbox.scss";
</style>
