<template>
  <div class="form-email-page">
    <h1 class="title space">Email</h1>

    <div class="email-field">
      <TextField
        errorMsg="Incorrect Email"
        type="email"
        class="space"
        :value="userInfo.email"
        @change="handleEmailInputChange"
        @input="handleEmailInputChange"
        :isLoading="isLoading"
        :isValid="$v.userInfo.email.isValid"
        :dirty="$v.userInfo.email.$dirty"
        :placeholder="$t('registration.emailPage.emailtext')"
      />
    </div>

    <!-- <Checkbox
      :label="$t('registration.emailPage.checkbox')"
      @checked="handleAccept"
      class="highlight"
    /> -->
    <div class="highlight">
      <label class="orange">
        <input type="checkbox" @change="handleAccept" checked/>
        <span class="checkmark"></span>
        <span class="checklabel">{{ $t('registration.emailPage.checkbox') }}</span>
      </label>
    </div>
    <Button
      :title="$t('buttons.profiles')"
      @click.native="handleSubmit"
      :disabled="!userInfo.termsAccepted || !this.$v.userInfo.email.isValid"
    />

    <span class="conditions">
      <i18n path="terms.term" tag="label">
        <button class="tos" @click="IsShowTerms=true">{{ $t('terms.tos') }}</button>
      </i18n>
    </span>
    <ContentModalTerms @submit="IsShowTerms=false" v-if="IsShowTerms" :text="$t('terms.htmlText')"/>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { validationMixin } from 'vuelidate';
import { email, required } from 'vuelidate/lib/validators';
import TextField from '../TextField/TextField';
import Checkbox from '../Checkbox/Checkbox';
import ContentModalTerms from '../ContentModalTerms/ContentModalTerms';
import verifyEmail from '~/utils/AbstractApiEmailVerificator';
import { Namespaces } from '@/utils/constants/store.js';

const { 
  mapActions: registrationActions
} = createNamespacedHelpers( Namespaces.Registration );

export default {
  components: {
    TextField,
    Checkbox,
    ContentModalTerms
  },
  mixins: [validationMixin],
  data () {
    return {
      isLoading: false,
      IsShowTerms: false,
      userInfo: {
        email: '',
        termsAccepted: true
      }
    };
  },
  validations: {
    userInfo: {
      email: {
        async isValid (value) {
          const isRequiredValid = required(value);
          const isEmailValid = email(value);

          if (!isRequiredValid || !isEmailValid) {
            return false;
          }

          // return true
          this.isLoading = true;
          const emailIsVerified = await this.verifyEmail(this.userInfo.email);
          this.isLoading = false;

          return emailIsVerified;
        }
      }
    }
  },
  methods: {
    ...registrationActions(['checkEmail']),
    handleEmailInputChange (value) {
      const validateEmail = (email) => {
        return email.match(
          /^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i
        );
      };
      this.userInfo.email = (value || '').trim();
      if (validateEmail(this.userInfo.email)) {
        this.$v.userInfo.email.$touch();
      }
      if (!this.userInfo.email) {
        this.$nextTick(() => this.$v.$reset());
      }
    },
    handleAccept (value) {
      this.userInfo.termsAccepted = value;
      this.$emit('termsAccepted', this.userInfo.termsAccepted);
    },
    handleSubmit () {
      this.$v.userInfo.$touch();
      this.errors = this.$v.userInfo.$anyError;
      this.isValid = this.$v.userInfo.email.isValid;
      if (this.errors && this.isValid) { return; }
      this.$emit('formValue', this.userInfo);
      this.$emit('login');
    },
    async verifyEmail (email) {
      const res = await this.checkEmail(email);
      return res === 'OK';
    },
    showTerms () {
      this.IsShowTerms = true;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "./theFormEmail.scss";
.highlight {
  margin: 20px 0;
}
.orange {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  // background-color: rgba($color: orange, $alpha: 0.1);
}

/* Hide the browser's default checkbox */
.orange input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  border-radius: 5px;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background: transparent;
  border: 1px solid orange;
}
/* When the checkbox is checked, add a blue background */
.orange input:checked ~ .checkmark {
  background: linear-gradient(90deg, #ff6565 -2.93%, #ffdc23 105.33%);
}
/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
/* Show the checkmark when checked */
.orange input:checked ~ .checkmark:after {
  display: block;
}
/* Style the checkmark/indicator */
.orange .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid black;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.orange .checklabel {
  font-size: 18px;
  // margin-top: 20px;
  // padding: 20px 2px 20px 2px;
  color: white;
}
.tos {
  color: #FF8C00;
  background: transparent;
}
</style>
