import types from './types';

export default {
  [types.GET_MODEL_DETAILS_SUCCESS] (state, model) {
    state.selectedModel = { ...model, files: model.files.filter(f => (f.path.includes('.png') || f.path.includes('.gif'))), distance: Math.floor(Math.random() * 25) + 1 };
    state.loading = false;
  },

  [types.GET_MODEL_DETAILS_FAIL] (state, error) {
    state.loading = false;
    state.error = error;
  }
};
