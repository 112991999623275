<template>
  <div class="toast__wrap white__mode">
    <div class="toast__item">
      <div class="toast__img">
        <img :src="img" alt="photo" />
      </div>
      <div class="toast__info">
        <span class="toast__info-name">{{ name }}</span>
        <span class="toast__info-text">{{ text }}</span>
      </div>
    </div>
    <Button :title="buttonTitle" @click.native="handleOpen"/>
  </div>
</template>

<script>
import Button from "../Button/Button";
import {createNamespacedHelpers} from "vuex";
import {Namespaces} from "@/utils/constants/store.js";

const {
  mapActions: profileActions,
  mapGetters: profileGetters
} = createNamespacedHelpers(Namespaces.Profile);


export default {
  name: "ToastButton",
  components: {
    Button
  },
  props: {
    img: {
      type: String,
      default: ""
    },
    name: {
      type: String,
      default: ""
    },
    text: {
      type: String,
      default: ""
    },
    buttonTitle: {
      type: String,
      default: ""
    },
    handleOpen: {
      type: Function,
      default: () => {}
    }
  },
};
</script>

<style scoped lang="scss">
@import "./toast";
</style>
