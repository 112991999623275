var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "select-wrapper" },
    [
      _c(
        "Multiselect",
        {
          ref: "multiselect",
          attrs: {
            value: _vm.initialCity,
            options: _vm.options,
            "show-labels": false,
            placeholder: "",
            "deselect-label": "",
            showNoOptions: false,
            showNoResults: false,
            multiple: false,
          },
          on: {
            "search-change": _vm.search,
            open: _vm.open,
            input: _vm.handleSelect,
          },
        },
        [
          _c("template", { slot: "noResult" }, [
            _vm._v(_vm._s(_vm.$t("registration.cityPage.noresult"))),
          ]),
          _vm._v(" "),
          _c("template", { slot: "noOptions" }, [
            _vm._v(_vm._s(_vm.$t("registration.cityPage.listempty"))),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }