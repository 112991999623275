<template>
  <div>
    <Spinner size="medium" line-fg-color="#FF4500"/>
  </div>
</template>

<script>
import Spinner from 'vue-simple-spinner/src/components/Spinner.vue'

export default {
  components: {
    Spinner
  }
}
</script>
