export default () => ({
  loading: true,
  error: false,
  profileData: {
    id: null,
    email: "",
    phone: "",
    name: "",
    birthday: null,
    whatsappNumber: "",
    whatsappVerified: null,
    phoneNumber: "",
    phoneVerified: null,
    gender: "",
    partnerGender: "",
    country: "",
    city: "",
    count_tokens: 0,
    looking_gender: "",
    token: null,
    is_blocked: false,
    email_verified_at: true,
    phone_verified_at: false,
    created_at: "",
    updated_at: "",
    avatar:"",
    phone_pay_pin: null,
    call: false,
    call_session_id: null,
    likes: [],
    paidPromo: false,
  }
});
