var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "model-slider-container" },
    [
      !_vm.isChatOpened
        ? _c("div", { staticClass: "back-button", on: { click: _vm.goBack } }, [
            _c("span", { staticClass: "material-icons" }, [
              _vm._v("arrow_back"),
            ]),
            _vm._v(" "),
            _c("span", [_vm._v(_vm._s(_vm.$t("main.model.back")))]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "swiper",
        {
          ref: "mySwiper",
          class: {
            blurred:
              _vm.needConfirmation || _vm.showModal || _vm.showModalEmail,
          },
          attrs: { options: _vm.swiperOptions },
        },
        [
          _vm._l(_vm.selectedModel.files, function (slide, index) {
            return _c(
              "swiper-slide",
              { key: slide.id, staticClass: "slide" },
              [
                _c("img", {
                  class: {
                    blurred:
                      (slide.path.includes(".gif") ||
                        _vm.blurImage[_vm.selectedModel.id] === index) &&
                      _vm.tokensLeft <= 60,
                  },
                  attrs: { src: slide.path, alt: slide.alt },
                }),
                _vm._v(" "),
                (slide.path.includes(".gif") ||
                  _vm.blurImage[_vm.selectedModel.id] === index) &&
                _vm.tokensLeft <= 60
                  ? _c(
                      "nuxt-link",
                      { staticClass: "unlock", attrs: { to: "/tokens" } },
                      [
                        _c("Key", {
                          attrs: { size: 48, "fill-color": "orange" },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            )
          }),
          _vm._v(" "),
          _c("div", {
            staticClass: "swiper-pagination swiper-pagination-black",
            attrs: { slot: "pagination" },
            slot: "pagination",
          }),
          _vm._v(" "),
          _c("div", {
            staticClass: "swiper-button-prev",
            attrs: { slot: "button-prev" },
            slot: "button-prev",
          }),
          _vm._v(" "),
          _c("div", {
            staticClass: "swiper-button-next",
            attrs: { slot: "button-next" },
            slot: "button-next",
          }),
        ],
        2
      ),
      _vm._v(" "),
      !_vm.isChatOpened
        ? _c("div", { staticClass: "model-social-buttons" }, [
            _vm.isCountryAllowed
              ? _c(
                  "button",
                  {
                    staticClass: "_social-button",
                    on: { click: _vm.callPhone },
                  },
                  [
                    _c("Phone", {
                      attrs: { size: 48, "fill-color": "#3CCF65" },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "_social-button blueColor",
                on: { click: _vm.openChat },
              },
              [_c("ChatIcon", { attrs: { size: 48, "fill-color": "white" } })],
              1
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "social-button2",
                on: { click: _vm.closePhoneVerify },
              },
              [
                _c("img", {
                  attrs: { src: require("@/assets/img/live_stream.png") },
                }),
              ]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.needConfirmation
        ? _c("WhatsAppFlow", {
            on: {
              close: _vm.closePhoneVerify,
              wnd_close: _vm.closePhoneVerify,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showModalEmail
        ? _c("ContentModalEmailVerify", {
            attrs: {
              text: "modal.email.text_profile",
              "btn-title": "profile.verify",
              email: _vm.profileData.email,
            },
            on: {
              "close-modal": _vm.handleCloseModalEmail,
              "click-modal": function ($event) {
                _vm.showModalEmail = false
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isChatOpened
        ? _c("ChatModalWindow", {
            attrs: {
              "on-close": _vm.closeChat,
              "new-username": _vm.selectedModel.name,
              "unique-name": _vm.uniqueName,
              "model-id": _vm.selectedModel.id,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }