var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [_c("Spinner", { attrs: { size: "medium", "line-fg-color": "#FF4500" } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }