import types from "./types"

export default {
  async getUser ({ commit }) {
    try {
      const data = await this.$UserAPI.getUserData()
      if (!data.email_verified_at)
        data.email_verified_at = data.email.indexOf("@fake.net") !== -1
      data.count_tokens = data.count_tokens * 20
      commit(types.EDIT_PROFILE_SUCCESS, data)
    } catch (error) {
      commit(types.EDIT_PROFILE_FAIL, error)
    }
  },
  async editUser ({ commit }, profileData) {
    try {
      const data = await this.$UserAPI.updateUserData(profileData)
      data.email_verified_at = true
      commit(types.EDIT_PROFILE_SUCCESS, data)
    } catch (error) {
      commit(types.EDIT_PROFILE_FAIL, error)
    }
  },
  async setWhatsappNumber ({ commit }, number) {
    try {
      await this.$UserAPI.addNumber(number)
      commit(types.SET_WHATSAPP_NUMBER_SUCCESS, number)
    } catch (error) {
      commit(types.SET_WHATSAPP_NUMBER_FAIL, error)
    }
  },
  async checkCode ({ commit }, code) {
    try {
      await this.$UserAPI.confirmCode(code)
      commit(types.CHECK_WHATSAPP_CODE_SUCCESS)
    } catch (error) {
      commit(types.CHECK_WHATSAPP_CODE_FAIL, error)
    }
  },
  async checkEmailToken ({ commit }, token) {
    try {
      const data = await this.$UserAPI.confirmEmail(token)
      data.count_tokens = data.count_tokens * 20
      commit(types.CHECK_EMAIL_TOKEN_SUCCESS, data)
      // return data
    } catch (error) {
      commit(types.CHECK_EMAIL_TOKEN_FAIL, error)
      throw error
    }
  },
  async decrementToken ({commit}) {
    try {
      await this.$UserAPI.decrementToken()
      commit(types.DECREMENT_SUCCESS)
    } catch (error) {
      commit(types.DECREMENT_FAIL, error)
    }
  },
  async checkPayment ({ commit }, id) {
    try {
      const data = await this.$UserAPI.confirmPayment(id).then(res => res.data)
      commit(types.CHECK_PAYMENT_SUCCESS, data)
    } catch (error) {
      commit(types.CHECK_PAYMENT_FAIL, error)
    }
  },
  async getPIN ({commit}) {
    try {
      const req = await this.$UserAPI.getPhonePayPIN()
      commit(types.GETPIN_SUCCESS, req.data.phone_pay_pin)
    } catch (error) {
      commit(types.GETPIN_FAIL, error)
    }
  },
  async setPhoneNumber ({ commit }, phone) {
    try {
      await this.$UserAPI.verifyPhone(phone)
      commit(types.SET_PHONE_NUMBER_SUCCESS, phone)
    } catch (error) {
      commit(types.SET_PHONE_NUMBER_FAIL, error)
    }
  },
  async checkPhoneCode ({ commit }, code) {
    try {
      await this.$UserAPI.confirmPhone(code)
      commit(types.CHECK_PHONE_CODE_SUCCESS)
    } catch (error) {
      commit(types.CHECK_PHONE_CODE_FAIL, error)
    }
  },
  async getLikes ({ commit }) {
    try {
      const data = await this.$UserAPI.getLikes().then(res => res.data)
      // console.log('likes', data)
      commit(types.GET_LIKES_SUCCESS, data)
    } catch (error) {
      commit(types.GET_LIKES_FAIL, error)
    }
  }
}
