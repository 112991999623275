var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "modal__content" },
    [
      _c("div", { staticClass: "modal__content-img mb-s" }, [
        _c("img", { attrs: { src: _vm.img, alt: "photo" } }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "modal__content-text mb-m" }, [
        _vm._v("\n    " + _vm._s(_vm.text) + "\n  "),
      ]),
      _vm._v(" "),
      _c("Button", {
        attrs: { title: "See offers" },
        nativeOn: {
          click: function ($event) {
            return _vm.handleSubmit.apply(null, arguments)
          },
        },
      }),
      _vm._v(" "),
      _c("Link"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }