var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("Message", {
    attrs: { fillColor: _vm.color, size: _vm.size, title: _vm.title },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }