import {parse as parseCookie, serialize as serializeCookie} from "cookie";
import ConfigAPI from "~/API/config";
import UserAPI from "~/API/user";
import ModelsAPI from "~/API/models";
import ChatAPI from "~/API/chat";

import { USER_TOKEN_KEY } from '@/utils/constants/base'

export default function ({ $axios, $config: {apiHost}, req }, inject) {
  $axios.setBaseURL(`${apiHost}/api`);

  const getCookie = function (_key, req) {
    // if server
    if (req) {
      if (req.headers.cookie) {
        const cookies = parseCookie(req.headers.cookie || "") || {};
        return cookies['auth.'+_key] ? decodeURIComponent(cookies['auth.'+_key]) : void 0;
      }
    }
    // if client
    if (process.client) {
      return localStorage.getItem(_key);
    }
  }

  $axios.onRequest((config) => {
    const token = getCookie(USER_TOKEN_KEY, req);
    // console.log('t', token);
    if (token) {
      $axios.setToken(token, 'Bearer');
    }
    return config;
  })
  $axios.onResponse(response => response.data);
  
  inject('api', $axios);
  inject('ConfigAPI', ConfigAPI($axios));
  inject('UserAPI', UserAPI($axios));
  inject('ModelsAPI', ModelsAPI($axios));
  inject('ChatAPI', ChatAPI($axios));
}
