<template>
  <transition name="modal-fade">
    <div class="modal-overlay" @click="handleSubmit" @click.right.stop.prevent="">
      <div class="modal" @click.stop>
                <div class="header">
                    <h2 class="title">{{ title }}</h2>
                    <svg
                        class="icon-close"
                        viewBox="0 0 50 45"
                        xmlns="http://www.w3.org/2000/svg"
                        @click="handleSubmit"
                    >
                        <path
                            d="M9.15625 6.3125L6.3125 9.15625L22.15625 25L6.21875 40.96875L9.03125 43.78125L25 27.84375L40.9375 43.78125L43.78125 40.9375L27.84375 25L43.6875 9.15625L40.84375 6.3125L25 22.15625Z"
                            fill="#ee0000"
                        />
                    </svg>
                </div>
        <div class="mb-m" v-html="text"></div>
        <Button
          title="ok"
          @click.native="handleSubmit"
        />
      </div>
    </div>
  </transition>
</template>

<script>
import Button from "../Button/Button";
export default {
  name: "ModalTerms",
  components:{
    Button
  },
  props:{
    title: {
      type: String,
      default: ""
    },
    text: {
      type: String,
      default: ""
    }
  },
  methods:{
    handleSubmit() {
     this.$emit('submit')
    }
  }
}
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  background-color: #000000da;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.modal {
  background-color: white;
  height: 100vh;
  width: 100%;
  padding: 5%;
  margin-bottom: 24px;
}
.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}
.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}
.mb-m {
  margin-bottom: 24px;
}
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.header .title {
    font-size: 1.25rem;
    line-height: 1.75rem;
}
.icon-close {
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
}
</style>
