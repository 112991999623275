<template>
  <AlertOutline :fillColor="color" :size="size" />
</template>

<script>
import AlertOutline from "vue-material-design-icons/AlertOutline.vue";

export default {
  components: {
    AlertOutline
  },
  props: {
    color: {
      type: String,
      default: "#dc4747"
    },
    size: {
      type: Number,
      default: 24
    }
  }
};
</script>
