<template>
  <div class="backdrop" @click.self="handleClose">
    <div class="modal-wrapper" @click.self="handleClose">
      <div class="close" @click="handleClose" />
      <div class="content">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalWindow',
  components: {
  },
  props: {
  },
  data () {
    return {
    };
  },
  methods: {
    handleSend () {
      this.$emit('submit');
    },
    handleClose () {
      this.$emit('close');
    }
  }
};
</script>

<style lang="scss" scoped>
.modal-wrapper {
  z-index: 2;
  display: flex;
  justify-content: center;
  border-radius: 5px;
  padding: 52px 16px 24px;
  font-family: "DMSans-Regular";
  position: fixed;
  background-color: white;
  left: 0;
  right: 0;
  margin: auto;
  max-width: 500px;
  bottom: 40%;
  border: 1px solid orange;
  -webkit-transition: opacity 300ms ease 200ms, -webkit-transform 300ms ease 200ms, -webkit-filter 300ms ease 200ms;
  transition: opacity 300ms ease 200ms, -webkit-transform 300ms ease 200ms, -webkit-filter 300ms ease 200ms;
  transition: transform 300ms ease 200ms, filter 300ms ease 200ms, opacity 300ms ease 200ms;
  transition: transform 300ms ease 200ms, filter 300ms ease 200ms, opacity 300ms ease 200ms, -webkit-transform 300ms ease 200ms, -webkit-filter 300ms ease 200ms;
  .content {
    display: flex;
    flex-direction: column;
    position: relative;
    img {
      position: absolute;
      bottom:calc(100% + 19px);
      left: 50%;
      transform: translateX(-50%);
    }
    .info {
      text-align: center;
      margin-top: 0;
      font-size: 1.125rem;
      padding-bottom: 5%;
    }
  }
  .close {
    display: block;
    // position: relative;
    top: 0.2rem;
    right: 0.2rem;
    position: absolute;
    // right: 32px;
    // top: 32px;
    width: 32px;
    height: 32px;
    opacity: 0.3;
  }
  .close:hover {
    opacity: 1;
  }
  .close:before, .close:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 33px;
    width: 2px;
    background-color: #333;
  }
  .close:before {
    transform: rotate(45deg);
  }
  .close:after {
    transform: rotate(-45deg);
  }
  &-img{
    width: 96px;
    height: 96px;
    border-radius: 50%;
    padding: 0;
    margin-right: auto;
    margin-left: auto;
    margin-top: -80px;
    background: white;
    // background: linear-gradient(90deg, #ff6565 -2.93%, #ffdc23 105.33%);
    img{
      border-radius: 50%;
      object-fit: cover;
      width: 100%;
    }
  }
}
.modal-wrapper.modal--active {
  bottom: 40%;
  -webkit-filter: blur(0);
          filter: blur(0);
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
}
.backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
}
</style>
