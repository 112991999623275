var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "block-wrapper", class: { highlight: _vm.unread } },
    [
      _c(
        "nuxt-link",
        { staticClass: "block-image", attrs: { to: "/girls/1" } },
        [
          _c("img", {
            attrs: { src: _vm.getAvatar(_vm.photo), alt: "avatar" },
          }),
          _vm._v(" "),
          _vm.status ? _c("div", { staticClass: "status" }) : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "block-info" }, [
        _c("div", { staticClass: "block-info-left" }, [
          _c("div", [
            _c("span", [_vm._v(_vm._s(_vm.name))]),
            _vm._v(" "),
            _c("span", { staticClass: "time" }, [
              _vm._v(
                _vm._s(_vm.$t("notifications.at")) + " " + _vm._s(_vm.timestamp)
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "message" }, [_vm._v(_vm._s(_vm.action))]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "block-info-right" }, [
          _vm.unread
            ? _c(
                "div",
                { staticClass: "unread" },
                [
                  _c("span", [_vm._v(_vm._s(_vm.$t("notifications.open")))]),
                  _vm._v(" "),
                  _c("ChevronRight", {
                    staticClass: "icon",
                    attrs: { size: 16, color: "#FF2525" },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }