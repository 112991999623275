var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "message--own" }, [
    _c("div", { staticClass: "message__contents" }, [
      _vm.imageUrl
        ? _c("img", {
            staticStyle: { width: "100%" },
            attrs: { src: _vm.imageUrl, alt: "" },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "chat-message" }, [
        _vm._v(_vm._s(_vm.contents)),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "metadata" },
        [
          _c("time", { staticClass: "time" }, [_vm._v(_vm._s(_vm.date))]),
          _vm.sent
            ? _c("Tick", { staticClass: "tick", attrs: { size: 16 } })
            : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }