<template>
  <div class="modal">
    <div class="back" @click="handleClose"><span class="material-icons">arrow_back_ios</span></div>
    <div class="modal-content">
      <h2>{{ title }}</h2>

      <DatePicker
        v-model="date"
        valueType="format"
        :placeholder="this.$i18n.t('profile.datepicker')"
        class="datepicker"
      />
      <Button :title="$t('profile.btn')" @click.native="handleDate" />
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import Button from "../Button/Button";
export default {
  components: {
    DatePicker,Button
  },
  props: {
    closeHandler: {
      type: Function,
      default: () => {}
    },
    title: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      date: ""
    };
  },
  mounted () {
    // console.log('Component mounted')
    this.$router.push({
      query : {
        edit : true
      }
    })    
  },
  beforeDestroy () {
    // console.log('Component beforeDestroy')
    this.$router.push({
      query : {}
    })    
  },
  methods: {
    handleDate () {
      this.$emit("changed", this.date);
    },
    handleClose () {
      this.$emit("cancel");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "./modalDatePicker.scss";
.back {
  position: absolute;
  z-index: 2;
  top: 1.5rem;
  left: 10px;
  color: black;
}
</style>
