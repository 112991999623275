var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "form-email-page" },
    [
      _c("h1", { staticClass: "title space" }, [_vm._v("Email")]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "email-field" },
        [
          _c("TextField", {
            staticClass: "space",
            attrs: {
              errorMsg: "Incorrect Email",
              type: "email",
              value: _vm.userInfo.email,
              isLoading: _vm.isLoading,
              isValid: _vm.$v.userInfo.email.isValid,
              dirty: _vm.$v.userInfo.email.$dirty,
              placeholder: _vm.$t("registration.emailPage.emailtext"),
            },
            on: {
              change: _vm.handleEmailInputChange,
              input: _vm.handleEmailInputChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "highlight" }, [
        _c("label", { staticClass: "orange" }, [
          _c("input", {
            attrs: { type: "checkbox", checked: "" },
            on: { change: _vm.handleAccept },
          }),
          _vm._v(" "),
          _c("span", { staticClass: "checkmark" }),
          _vm._v(" "),
          _c("span", { staticClass: "checklabel" }, [
            _vm._v(_vm._s(_vm.$t("registration.emailPage.checkbox"))),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("Button", {
        attrs: {
          title: _vm.$t("buttons.profiles"),
          disabled:
            !_vm.userInfo.termsAccepted || !this.$v.userInfo.email.isValid,
        },
        nativeOn: {
          click: function ($event) {
            return _vm.handleSubmit.apply(null, arguments)
          },
        },
      }),
      _vm._v(" "),
      _c(
        "span",
        { staticClass: "conditions" },
        [
          _c("i18n", { attrs: { path: "terms.term", tag: "label" } }, [
            _c(
              "button",
              {
                staticClass: "tos",
                on: {
                  click: function ($event) {
                    _vm.IsShowTerms = true
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("terms.tos")))]
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _vm.IsShowTerms
        ? _c("ContentModalTerms", {
            attrs: { text: _vm.$t("terms.htmlText") },
            on: {
              submit: function ($event) {
                _vm.IsShowTerms = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }