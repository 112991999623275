var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "input__container" },
    [
      _c("img", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.file,
            expression: "file",
          },
        ],
        staticClass: "image--uploaded",
        attrs: { src: _vm.imagePreview },
      }),
      _vm._v(" "),
      _vm._t("input-field", function () {
        return [
          _c("input-field", {
            ref: "msgInput",
            on: { input: _vm.onInput, newOwnMessage: _vm.onNewOwnMessage },
            model: {
              value: _vm.message,
              callback: function ($$v) {
                _vm.message = $$v
              },
              expression: "message",
            },
          }),
        ]
      }),
      _vm._v(" "),
      _vm._t("input-button", function () {
        return [
          _c("input-button", {
            attrs: { title: _vm.title },
            on: { newOwnMessage: _vm.onNewOwnMessage },
          }),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }