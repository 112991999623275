<template>
  <div
    class="input__button"
    @click="send">
    {{ title }}
  </div>
</template>

<script>

export default {
  name: 'InputButton',
  props: {
    title: {
      type: String,
      default: 'Send',
      required: false
    }
  },
  methods: {
    send (event) {
      this.$emit('newOwnMessage')
    }
  }
}
</script>

<style>

</style>
