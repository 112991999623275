var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal" }, [
    _c(
      "div",
      { staticClass: "modal-content-block" },
      [
        _c("ChatTelenesLogic", {
          staticClass: "custom-window",
          attrs: {
            newUsername: _vm.newUsername,
            uniqueChatName: _vm.uniqueName,
            modelId: _vm.modelId,
          },
          on: { closed: _vm.onClose },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }