<template>
  <div ref="slide" class="slide-wrap">
    <nuxt-link
      :to="{ name: 'girls-id', params: { id } }"
      exact
      class="slide-text-name"
    >
      <img :src="image" :alt="alt">
    </nuxt-link>
    <div class="slide-text">
      <nuxt-link
        :to="{ name: 'girls-id', params: { id } }"
        exact
        class="slide-text-name"
      >
        <p class="name">
          {{ name }}
        </p>
        <p class="distributor">
          |
        </p>
        <p class="age">
          {{ age }}
        </p>
      </nuxt-link>
      <div class="slide-text-info">
        <p :class="status? 'status' : 'status status-offline'">
          {{ status? 'online': 'offline' }}
        </p>
        <!-- <p class="location">{{ location }}</p> -->
        <p class="model-location">
          <MapMarkerOutline :size="18" /> {{ $t("profile.distance", {distance: distance, unit: profileData.country.indexOf('United States') !== -1 ? 'ml' : 'km'}) }}
        </p>
      </div>
      <div class="slide-text-info">
        <p class="description">
          {{ description }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import MapMarkerOutline from 'vue-material-design-icons/MapMarkerOutline.vue';
import { Namespaces } from '@/utils/constants/store.js';

const {
  // mapActions: profileActions,
  mapGetters: profileGetters
} = createNamespacedHelpers(Namespaces.Profile);
export default {
  name: 'GirlSlide',
  components: {
    MapMarkerOutline
  },
  props: {
    image: {
      type: String,
      default: ''
    },
    alt: {
      type: String,
      default: 'photo'
    },
    id: {
      type: Number,
      default: null
    },
    name: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    age: {
      type: Number,
      default: 0
    },
    status: {
      type: Number,
      default: 0
    },
    distance: {
      type: Number,
      default: 0
    },
    location: {
      type: String,
      default: ''
    }
  },
  data: () => ({
  }),
  computed: {
    ...profileGetters(['profileData'])
    // distance: () => {
    //   // const len = Math.floor(Math.random() * 25) + 1;
    //   return props.distance + '' + ();
    // },
    // desc () {
    //   return this.description ? this.description.substring(0, 35)+'...' : ''
    // }
  },
  mounted () {
    this.setHeightSlide();
  },
  methods: {
    setHeightSlide () {
      this.$refs.slide.style.setProperty('--vh', window.innerHeight / 100 + 'px');
    }
  }
};
</script>

<style lang="scss" scoped>
@import "./slider.scss";
.model-location {
  position: absolute;
  font-size: 0.75rem;
  right: 38px;
}
</style>
