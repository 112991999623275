<template>
  <div
    class="menu-item"
    @click="clickHandler"
    :style="{ background: backgroundColor }"
  >
    <div class="menu-item-left">
      <div class="field">
        <slot />
      </div>

      <div class="icon-block">
        <Diamond
          v-if="isTokenField"
          color="#FF2525"
          :size="20"
          class="diamond"
        />

        <span class="add value" :class="{ property: property }">
           {{ property ||  $t("profile.add") }}
        </span>
      </div>
    </div>

    <div class="menu-item-icon" v-if="!readonly">
      <ChevronRight />
    </div>
  </div>
</template>

<script>
import ChevronRight from "@/assets/Icons/ChevronRight/ChevronRight";
import Diamond from "@/assets/Icons/Diamond/Diamond";



export default {
  components: {
    ChevronRight,
    Diamond
  },
  props: {
    property: {
      type: String,
      default: ""
    },
    backgroundColor: {
      type: String,
      default: ""
    },
    isTokenField: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    isCountry: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      tokenIcon: require("@/assets/Icons/Tokens/tokens.svg")
    };
  },
  methods: {
    clickHandler() {
      this.$emit("clicked");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "./theProfileFormField.scss";
</style>
